<script setup>
// --------------------------------- Imports -------------------------------- //
import { find } from 'lodash-es';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const { $t, route, router } = useCommonImports();

const view_items = computed(() => [
  {
    uid: 'list',
    route_name: 'forms-overview-list-view',
    leftSlot: IconHawkTableTwo,
    tooltip_text: $t('List view'),
  },
  {
    uid: 'calendar',
    route_name: 'forms-overview-calendar-view',
    leftSlot: IconHawkCalendar,
    tooltip_text: $t('Calendar view'),
  },
]);

// -------------------------------- Functions ------------------------------- //
function navigateToFormsInstancesListPage({ template }) {
  router.push({ name: 'forms-template-forms-list', params: { template_uid: template, asset_id: route.params.asset_id } });
}

function changeLayout(event) {
  switch (event.uid) {
    case 'list':
      router.push({ name: 'forms-overview-list-view', params: { ...route.params } });
      break;
    case 'calendar':
      router.push({ name: 'forms-overview-calendar-view', params: { ...route.params } });
      break;
  }
}

// -------------------------------- Watchers -------------------------------- //

// ----------------------------- Lifecycle Hooks ---------------------------- //
</script>

<template>
  <div class="flex min-h-full flex-col justify-center">
    <HawkPageHeader :title="$t('Forms')" class="-mb-4 -mx-4">
      <template #left>
        <HawkButtonGroup
          :items="view_items"
          icon
          size="md"
          :active_item="find(view_items, function(item) { return item.route_name === route.name; })?.uid"
          @select="changeLayout($event)"
        />
      </template>
      <template #right>
        <!-- Create new Forms CTA -->
        <new-form-button @form-created="navigateToFormsInstancesListPage" />
      </template>
    </HawkPageHeader>
    <div class="mt-4">
      <!-- Forms Router view for calendar and list view -->
      <router-view />
    </div>
  </div>
</template>
