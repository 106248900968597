<!-- eslint-disable vue/prop-name-casing -->
<script setup>
// USAGE:
// <HawkAutocompleteElement
//   :autocomplete_options="[{ name: '123' }, { name: '234' }, { name: '345' }, { name: '456' }, { name: '567' }]"
//   :options="{
//     label: 'name',
//     value: form$?.data?.value,
//   }"
//   @change="form$?.update({ ...form$.data, value: $event })"
//   @on-click="form$?.update({ ...form$.data, value: $event })"
//   @on-enter="form$?.update({ ...form$.data, value: $event })"
// />
import { searchData } from '~/common/utils/common.utils.js';

const props = defineProps({
  autocomplete_options: { type: Array, default: () => {} },
  input_placeholder: { type: String, default: 'Enter value' },
  options: { type: Object },
});
const emit = defineEmits(['change', 'onEnter', 'onClick']);

const state = reactive({
  selected_auto_option: {},
  key_pressed: -1,
});

const filtered_autocomplete_options = computed(() => searchData(props.autocomplete_options, props.options?.value, ['name']) || []);

function onKeyStroke(e) {
  if (e.key === 'Space' || e.key === 'Enter') {
    e.preventDefault();
  }
  if (e.key === 'ArrowDown') {
    state.key_pressed = state.key_pressed === filtered_autocomplete_options.value.length - 1 ? 0 : state.key_pressed + 1;
    state.selected_auto_option = state.key_pressed !== filtered_autocomplete_options.value.length ? filtered_autocomplete_options.value?.[state.key_pressed] : {};
    document?.getElementById(state.selected_auto_option?.name)?.scrollIntoView({ block: 'end', behavior: 'smooth' });
  }
  else if (e.key === 'ArrowUp') {
    state.key_pressed = state.key_pressed === -1 || state.key_pressed === 0 ? filtered_autocomplete_options.value.length - 1 : state.key_pressed - 1;
    state.selected_auto_option = state.key_pressed !== -1 ? filtered_autocomplete_options.value?.[state.key_pressed] : {};
    document?.getElementById(state.selected_auto_option?.name)?.scrollIntoView({ block: 'end', behavior: 'smooth' });
  }
  else if (e.key === 'Enter') {
    emit('onEnter', state.selected_auto_option?.name);
  }
}
</script>

<template>
  <div class="col-span-12">
    <TextElement
      v-bind="{
        name: 'value',
        ...options,
      }"
      :placeholder="input_placeholder"
      :default="options?.default || null"
      autocomplete="off"
      class="pb-2"
      @change="value => emit('change', value)"
      @keydown="e => onKeyStroke(e)"
    />
    <div v-if="options?.value?.length >= 1 && filtered_autocomplete_options?.length" class="col-span-12">
      <div class="text-xs text-gray-500 my-1 font-medium">
        {{ $t('Suggested values') }}
      </div>
      <div class="max-h-[200px] scrollbar">
        <div
          v-for="option in filtered_autocomplete_options" :id="option.name"
          :key="option.name"
          class="w-full py-2.5 px-2 flex items-center hover:bg-gray-50 rounded-lg gap-2 cursor-pointer"
          :class="{ 'bg-gray-50': state.selected_auto_option?.name === option?.name }"
          @click="emit('onClick', option.name);"
        >
          <HawkText :content="option.name" :length="40" class="text-sm text-gray-700" />
        </div>
      </div>
    </div>
  </div>
</template>
