<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { cloneDeep } from 'lodash-es';
import { useThermStore } from '../store/therm.store';

const props = defineProps({
  side: {
    type: String,
    default: 'left',
  },
  therm_store_name: {
    type: String,
    default: 'therm',
  },
});
const therm_store = useThermStore(props.therm_store_name);
const ftg = computed(() => therm_store.ftg);
const inactive_feature_types = computed(() => therm_store.inactive_feature_types);
const features_count = computed(() => therm_store.features_count(true));
const feature_types = computed(() => therm_store.feature_types);

function setInactiveFeatures(payload) {
  return therm_store.set_inactive_features(payload);
}

function toggleDefectTypes(feature) {
  const inactiveFeatures = cloneDeep(inactive_feature_types.value);
  const index = inactiveFeatures.indexOf(feature.id);
  if (index === -1)
    inactiveFeatures.push(feature.id);
  else inactiveFeatures.splice(index, 1);
  setInactiveFeatures(inactiveFeatures);
  therm_store.selected_features = [];
}

function toggleAllButOneType(feature) {
  const all_features_types = Object.keys(feature_types.value).filter(key => features_count.value[key]).map(item => Number(item));
  if (!inactive_feature_types.value.includes(feature.id)
    && inactive_feature_types.value.length === (all_features_types.length - 1)) {
    setInactiveFeatures([]);
  }
  else {
    setInactiveFeatures(all_features_types.filter(key => feature.id !== key));
  }
  therm_store.selected_features = [];
}
</script>

<template>
  <div class="therm-features h-[calc(100vh-250px)] overflow-auto mt-4 scrollbar">
    <div v-for="item in ftg" :key="item">
      <div
        v-for="type in item.featureTypes"
        :key="type.id"
        @click="toggleDefectTypes(type)"
      >
        <div
          v-if="(features_count[type.id] || inactive_feature_types.includes(type.id)) && type.id !== 100"
          class="p-2.5 pl-4 flex items-center justify-between hover:bg-blue-500 hover:bg-opacity-10 text-sm"
        >
          <div class="flex justify-between items-center w-full cursor-pointer">
            <div class="flex items-center cursor-pointer">
              <div
                :style="
                  `border:1px solid ${
                    type.properties ? type.properties.color : 'red'
                  }; background: ${
                    type.properties ? type.properties.color : 'red'
                  }`
                "
                class="shrink-0 h-4 w-4 rounded-full border border-red-500 grid place-items-center"
              >
                <div
                  :style="
                    `background:${
                      inactive_feature_types.includes(type.id)
                        ? 'none'
                        : type.properties
                          ? type.properties.color
                          : ''
                    }`
                  "
                  class="h-2 w-2 bg-red"
                />
              </div>
              <div class="ml-2">
                <HawkText class="font-medium text-sm text-gray-700" :content="type.name" :length="20" :class="{ 'line-through text-gray-300': inactive_feature_types.includes(type.id) }" />
              </div>
            </div>
            <div class="features_count bg-gray-100 p-1" @click.stop="toggleAllButOneType(type)">
              <span class="font-medium text-xs">
                {{ features_count[type.id] || 0 }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.features_count {
  display: inline-block;
  border-radius: 50%;
  min-width: 20px;
  min-height: 20px;
  text-align: center;
  line-height: 1;
  box-sizing: content-box;
  white-space: nowrap;
}
.features_count:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  padding-top: 100%;
  height: 0;
}
.features_count span {
  display: inline-block;
  vertical-align: middle;
}
</style>
