<script setup>
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { useFormTemplateDetailStore } from '../store/form-template-detail.store';

// Components
import FormTemplateDetailHeader from '~/forms/components/form-template-detail/form-template-detail-header.vue';

const { $t, auth_store, route, router } = useCommonImports();

const form_template_detail_store = useFormTemplateDetailStore();

provide('form_template_detail_store', form_template_detail_store);

const is_template_loading = ref(true);

const form_template_detail_tabs = computed(() => [
  { uid: '1', label: $t('Forms'), to: { name: 'forms-overview' } },
  { uid: '2', label: $t('Templates'), to: { name: 'forms-templates' } },
  {
    uid: '3',
    label: form_template_detail_store.form_template_detail.name,
    to: {
      name: auth_store.check_split('forms_v2') ? 'fam' : 'form-template-detail',
      params: { template_uid: route.params.template_uid, asset_id: route.params.asset_id },
    },
  },
  { uid: '4', label: $t('Settings'), to: { name: 'forms-template-settings' } },
]);

const tabs = computed(() => [{
  uid: '1',
  label: $t('General'),
  to: { name: 'forms-template-settings-general', params: { asset_id: route.params.asset_id } },
  disabled: false,
}, {
  uid: '2',
  label: $t('Email & Notification'),
  to: { name: 'forms-template-settings-mail', params: { asset_id: route.params.asset_id } },
  disabled: false,
}, {
  uid: '3',
  label: $t('Sharing & Security'),
  to: { name: 'forms-template-settings-sharing', params: { asset_id: route.params.asset_id } },
  disabled: false,
}, {
  uid: '4',
  label: $t('Quick Access'),
  to: { name: 'forms-template-settings-quick-access', params: { asset_id: route.params.asset_id } },
  disabled: false,
}]);

const active_tab = computed(() => {
  return tabs.value.find(item => item.to.name === route.name)?.uid;
});

async function getData() {
  if (route.params.template_uid) {
    is_template_loading.value = true;
    await form_template_detail_store.set_form_template({ id: route.params.template_uid });
    is_template_loading.value = false;
  }
}

onMounted (async () => {
  await getData();
});

watch(() => route.params.asset_id, (val, old_val) => {
  if (val && old_val && val !== old_val)
    router.push({ name: 'forms-templates', params: { asset_id: route.params.asset_id }, query: { active: 'published' } });
});

watch(() => route.params.template_uid, () => {
  getData();
});
</script>

<template>
  <div class="justify-center py-4 sm:px-6 lg:px-4">
    <hawk-loader v-if="is_template_loading" />
    <div v-else class="flex min-h-full flex-col">
      <div class="flex justify-between items-start">
        <FormTemplateDetailHeader>
          <div>
            <hawk-breadcrumbs :items="form_template_detail_tabs" active_item="4" />
          </div>
        </FormTemplateDetailHeader>
      </div>
      <div class="flex min-h-full flex-col justify-center mt-4">
        <div class="flex py-5 border-b border-t">
          <div class="mr-4 text-lg font-semibold flex items-center">
            <span class="text-gray-600 rounded-md  cursor-pointer flex items-center p-2 hover:bg-gray-50 relative top-0.5" @click="router.push({ name: auth_store.check_split('forms_v2') ? 'fam' : 'form-template-detail', params: { template_uid: route.params.template_uid } })">
              <icon-hawk-chevron-left class="h-5 w-5 -mt-1" />
            </span>
            <p>{{ $t("Settings") }} </p>
          </div>
          <hawk-page-header-tabs :tabs="tabs" :active_item="active_tab" />
        </div>
        <router-view />
      </div>
    </div>
  </div>
</template>
