<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import syncMaps from '@mapbox/mapbox-gl-sync-move';
import IconWarn from '~icons/hawk/warn-icon';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import minMax from 'dayjs/plugin/minMax';
import timezone from 'dayjs/plugin/timezone';
import dayjsBusinessDays from 'dayjs-business-days2';
import { groupBy, isNil, mean, min, round, sortBy, sum, uniq, uniqBy } from 'lodash-es';
import { computed, nextTick, onUnmounted } from 'vue';
import { useModal } from 'vue-final-modal';
import { useAuthStore } from '~/auth/stores/auth.store';
import HawkColumnsSelector from '~/common/components/organisms/hawk-columns-selector.vue';
import { useMap } from '~/common/composables/mapbox/maps';
import TerraChartsHeatMap from '~/terra/components/charts/terra-charts-heatmap.vue';
import RangeDataTable from '~/terra/components/charts/terra-charts-range-table.vue';
import ScheduleTracker from '~/terra/components/charts/terra-schedule-tracker.vue';
import { useTerraStore } from '~/terra/store/terra.store.js';
import { useChartsConstants } from '~/terra/utils/charts-constants.js';
import { bDuration, getPriorDay, safeDivision, safeMultiplication, safeSubtraction } from '~/terra/utils/terra.utils.js';
import { useExportTerraReport } from '~/terra/utils/terra-report-export.composable.js';

const props = defineProps({
  add_classes: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: 'terra-charts',
  },
  terra_store_name: {
    type: String,
    default: 'terra',
  },
});
dayjs.extend(minMax);
dayjs.extend(timezone);
dayjs.extend(dayjsBusinessDays);
dayjs.extend(isSameOrAfter);
dayjs.setWorkingWeekdays([1, 2, 3, 4, 5, 6]);
const $t = inject('$t');
const $services = inject('$services');
const $toast = inject('$toast');
const terra_store = useTerraStore(props.terra_store_name);
const auth_store = useAuthStore();
const { color_by_menu, color_by_percentage_menu, color_by_status_menu, color_by_work_rate_menu, range_dates, status_color_map } = useChartsConstants();

let turf;
// Temporary until we come up with a better solution than $date
if (auth_store?.logged_in_user_details?.timezone)
  dayjs.tz.setDefault(auth_store?.logged_in_user_details?.timezone);
const map_instance_1 = ref(null);
const map_instance_compare_1 = ref(null);
const map_instance_compare_2 = ref(null);
const file_input_ref = ref(null);

const filter_selector_modal = useModal({
  component: HawkColumnsSelector,
  attrs: {
    onClose() {
      filter_selector_modal.close();
    },
    is_nested: false,
    immediate: false,
    label_field_name: 'name',
  },
});
const state = reactive({
  progress_data: [],
  field_project_metrics: {},
  filtered_projects: {},
  fc_instance: null,
  is_container_patch_loading: false,
  re_render_heatmap: 0,
  range_project_field_hash_metrics: {},
  row_indicators_for_range_table: {},
  prev_field_project_metrics: {},
  compare_maps: false,
  terra_response_metrics: null,
  range_analytics_response_current: null,
  range_analytics_response_previous: null,
  pr_range_data_loading: false,
  is_schedule_tracker_open: false,
  map_color_by: 'Status',

  form: {
    range_date: 90,
    date: dayjs().format('YYYY-MM-DD'),

  },
  hovered_block: null,
  selected_block: null,
  selected_activities_workflow: '',
  selected_range_metrics_field: '',
  selected_activity_workflow_field: '',
  selected_project_for_range_metrics: '',

});
const { is_report_exporting, setExporting, cancelExportingTerraReport, exportTerraReports } = useExportTerraReport();
const feature_layer = {
  id: 'label_layer',
  type: 'symbol',

  source: 'all_features_source',
  paint: {
    'text-color': '#202',
  },
  layout: {
    'icon-size': 1.2,
    // "text-allow-overlap": true,
    // "icon-allow-overlap": true,
    'icon-image': 'mapbox-text-bg',
    'icon-text-fit': 'both',
    'text-justify': 'center',
    'text-anchor': 'center',
    'text-field': ['get', 'name'],
    'text-font': ['literal', ['Arial Unicode MS Regular']],
    'text-size': 10,
  },
};
const layers_map = {
  color_by_percentage: [{
    id: 'fill_layer',
    type: 'fill',
    source: 'all_features_source',
    paint: {
      'fill-color': [
        'interpolate',
        ['linear'],
        ['get', 'completion_percentage'],
        0,
        '#F04438',
        20,
        '#F79009',
        40,
        '#FAC515',
        60,
        '#85E13A',
        80,
        '#12B76A',
        100,
        '#12B76A',

      ],
    },

  }, {
    id: 'line_layer',
    type: 'line',
    source: 'all_features_source',
    paint: {
      'line-width': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        2,
        1,
      ],
      'line-color': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        'red',
        'black',
      ],
    },
  }, feature_layer],
  color_by_status: [
    {
      id: 'fill_layer',
      type: 'fill',
      source: 'all_features_source',
      paint: {
        'fill-color': [
          'match',
          ['get', 'status'],
          'Not started',
          '#98A2B3',
          'Behind',
          '#F04438',
          'On Track',
          '#FAC515',
          'Ahead',
          '#53B1FD',
          'Completed',
          '#12B76A',
          '#98A2B3',
        ],

      },
    },
    {
      id: 'line_layer',
      type: 'line',
      source: 'all_features_source',
      paint: {
        'line-width': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          2,
          1,
        ],
        'line-color': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          'red',
          'black',
        ],
      },
    },
    feature_layer,
  ],
};
const { initMapbox, loadMapBoxPackage, loadImages, addMapboxToken, setSources, setLayers, removeMapboxInstance } = useMap({}, async (event_data, event_name, map_id) => {
  if (event_name === 'loaded') {
    const map_instances = {
      'map-viewer-1': map_instance_1.value,
      'map-viewer-compare-1': map_instance_compare_1.value,
      'map-viewer-compare-2': map_instance_compare_2.value,
    };
    const features = (terra_store.container?.block_boundary?.features) || [];
    if (features.length) {
      setSources(['all_features_source'], map_instances[map_id], true);
      loadImages(map_instances[map_id], '/img/icons/mapbox-text-bg.png', 'mapbox-text-bg');
      setLayers(layers_map.color_by_status, map_instances[map_id]);
      await loadBoundaryColorsForMap(map_id, true);
      await map_instances[map_id].once('idle');
    }
  }
});
const workflow_data_hash = computed(() => {
  return groupBy(state.progress_data, 'workflow');
});
const settings_menu = computed(() => [
  ...(terra_store.check_permission('modify_schedule')
    ? [{
        label: $t('Schedule tracker'),
        value: 'schedule_tracker',
      }]
    : []),
  ...((auth_store.is_internal_user && terra_store.container?.modify_charts)
    ? [
        {
          label: terra_store.container.properties?.is_charts_published ? $t('Unpublish') : $t('Publish'),
          value: 'publish_unpublish',
        },
        {
          label: $t('Import boundaries'),
          value: 'import_boundaries',
        },

      ]
    : []),
  {
    label: $t('Export'),
    value: 'export',
    on_click: () => {
      setExporting(true);
    },
  },

  ...(auth_store.check_split('grs_export_summary')
    ? [
        {
          label: $t('Export Summary'),
          value: 'export_summary',
          on_click: () => {
            setExporting(true, true);
          },
        },
      ]
    : []),
]);
const selected_workflow_data = computed(() => {
  logger.log('Selected workflow data', workflow_data_hash.value[state.selected_activities_workflow]);
  return workflow_data_hash.value[state.selected_activities_workflow];
});
const range_field_hash_metrics = computed(() => {
  return Object.values(state.range_project_field_hash_metrics);
});
const range_data_hash_by_field = computed(() => {
  return groupBy(range_field_hash_metrics.value, 'field');
});
const map_color_by_menu = computed(() => {
  if (state.map_color_by === 'Percentage')
    return color_by_percentage_menu.value;
  else if (state.map_color_by === 'Status')
    return color_by_status_menu.value;
  else if (state.map_color_by === 'Work rate')
    return color_by_work_rate_menu.value;
  return [];
});

const unique_projects = computed(() => {
  const field_hash = groupBy(selected_workflow_data.value, 'field');
  let sorted_uniq_projects;
  const filtered_projects = Object.keys(state.filtered_projects);
  if (filtered_projects.length)
    sorted_uniq_projects = sortBy(filtered_projects, item => item);
  else
    sorted_uniq_projects = sortBy(uniq(field_hash[state.selected_range_metrics_field]?.map(item => item.project)) || [], item => item);

  if (auth_store.check_split('range_overall_progress'))
    sorted_uniq_projects.unshift('Overall progress');
  return sorted_uniq_projects;
});

const filtered_projects = computed(() => {
  return Object.keys(state.filtered_projects);
});
const workflow_metrics = computed(() => {
  const data = {};
  const metric_skeleton = {
    projects_count: null,
    avg_percent_complete: null,
    avg_scheduled_percent_complete: null,
    avg_diff: null,
    name: null,
    percent_complete: null,
  };

  Object.keys(workflow_data_hash.value).forEach((workflow) => {
    const payload = { ...metric_skeleton };
    const meta = {
      percent_complete_values: [],
      scheduled_percent_complete_values: [],
      weightage_values: [],
      multiply_percent_and_weightage_values: [],
      multiply_scheduled_percent_and_weightage_values: [],
    };
    const workflow_data = workflow_data_hash.value[workflow];
    workflow_data.forEach((item) => {
      if (filtered_projects.value.length === 0 || state.filtered_projects[item.project]) {
        meta.percent_complete_values.push(item.percent_complete);
        meta.scheduled_percent_complete_values.push(item.scheduled_percent_complete);
        meta.weightage_values.push(item.weightage);
        meta.multiply_percent_and_weightage_values.push(safeMultiplication(item.percent_complete, item.weightage));
        meta.multiply_scheduled_percent_and_weightage_values.push(safeMultiplication(item.scheduled_percent_complete, item.weightage));
      }
    });
    payload.name = workflow;
    payload.projects_count = uniqBy(workflow_data, 'project').length;
    payload.avg_percent_complete = safeDivision(sum(meta.multiply_percent_and_weightage_values), payload.projects_count);
    payload.avg_scheduled_percent_complete = safeDivision(sum(meta.multiply_scheduled_percent_and_weightage_values), payload.projects_count);
    payload.avg_diff = round(safeSubtraction(payload.avg_percent_complete * 100, payload.avg_scheduled_percent_complete * 100));

    data[workflow] = payload;
  });
  logger.log('Workflow slider metrics', data);
  return data;
});
const unique_fields = computed(() => {
  let fields_data = [];
  if (selected_workflow_data.value) {
    const field_hash = groupBy(selected_workflow_data.value, 'field');
    logger.log('Selected field in workflow for left side metrics beside map', field_hash);
    const unique_fields = uniq(selected_workflow_data.value.map(item => item.field));
    const skeleton = {
      percent_complete: null,
      range_metric: null,
      range_badge_value: null,
      range_badge_color: null,
      name: null,
      field_index: null,
    };
    unique_fields.forEach((item) => {
      const data = { ...skeleton };
      const range_data = {
        metric_values: [],
        pr_work: [],
      };
      const progress_data = {
        percent_complete: [],
      };
      if (range_data_hash_by_field.value[item]) {
        range_data_hash_by_field.value[item].forEach((item) => {
          if (filtered_projects.value.length === 0 || state.filtered_projects[item.project]) {
            range_data.metric_values.push(item.cr_work ?? 0);
            range_data.pr_work.push(item.pr_work ?? 0);
          }
        });
      }
      else {
        range_data.metric_values = [0];
      }
      if (field_hash[item]) {
        field_hash[item].forEach((item) => {
          if (filtered_projects.value.length === 0 || state.filtered_projects[item.project])
            progress_data.percent_complete.push(item.percent_complete);
        });
      }
      const sum_of_cr_work = sum(range_data.metric_values);
      const sum_of_pr_work = sum(range_data.pr_work);
      data.name = item;
      data.percent_complete = mean(progress_data.percent_complete);
      data.range_metric = sum_of_cr_work;
      data.range_badge_value = round(safeDivision(safeSubtraction(sum_of_cr_work, sum_of_pr_work), sum_of_pr_work) * 100);
      data.range_badge_tooltip = `vs last ${state.form.range_date} days i.e ${sum(range_data.pr_work)}`;
      data.range_badge_color = data.range_badge_value > 0 ? 'green' : 'red';
      data.field_index = min(field_hash[item].map(item => item.field_index));
      fields_data.push(data);
    });
  }
  logger.log('Unique fields with percent complete and range metrics', fields_data);
  fields_data = sortBy(fields_data || [], ['field_index']);
  fields_data.unshift({
    name: 'Overall progress',
    percent_complete: workflow_metrics.value[state.selected_activities_workflow]?.avg_percent_complete,
  });
  return fields_data;
});
const hovered_block_metrics = computed(() => {
  const hovered_or_clicked_block = state.selected_block?.properties?.name || state.hovered_block?.properties?.name;
  const block_data = state.field_project_metrics[`${state.selected_activities_workflow}:${hovered_or_clicked_block}:${state.selected_activity_workflow_field}`];
  logger.log('Hovered block metrics - Project and field', block_data?.percent_complete);
  return {
    block_name: hovered_or_clicked_block,
    block_completion_percentage: !isNil(block_data?.percent_complete) ? round(block_data.percent_complete * 100) : 'NA',
    status: block_data?.status ?? '-',
    status_value: block_data?.difference_percent_complete,
    current_work_rate: !isNil(block_data?.actual_work_rate) ? round(block_data?.actual_work_rate) : 'NA',
    current_work_rate_indicator: block_data?.difference_work_rate ?? '-',
    est_finish_date: block_data?.est_finish ? block_data.est_finish : 'NA',
    est_finish_indicator: block_data?.difference_finish ?? '-',
    planned_finish_date: block_data?.planned_finish ? dayjs(block_data.planned_finish).format('DD-MM-YYYY') : 'NA',

    units: block_data?.units,
    required_work_rate: !isNil(block_data?.required_work_rate) ? round(block_data?.required_work_rate) : null,
  };
});
onMounted(async () => {
  try {
    turf = await import('@turf/turf');
    state.is_loading = true;
    await loadMapBoxPackage();
    await addMapboxToken();

    await getData();

    map_instance_1.value = await initMapbox({
      container_id: 'map-viewer-1',
    });
    map_instance_1.value.on('load', () => {
      map_instance_1.value.resize();
      loadBoundaryColorsForMap('map-viewer-1');
    });
    loadMapEvents();

    state.is_loading = false;
  }
  catch (err) {
    logger.error(err);
    state.is_loading = false;
  }
});
onUnmounted(() => {
  removeMapboxInstance(map_instance_1.value);
  removeMapboxInstance(map_instance_compare_1.value);
  removeMapboxInstance(map_instance_compare_2.value);
});

function getCompletionPercentageAndStatus(project_level_data) {
  const meta = {
    percent_complete: [],
    scheduled_percent_complete: [],
    weightage: [],
    multiply_percent_and_weightage_values: [],
    multiply_schedule_percent_and_weightage_values: [],
  };
  project_level_data.forEach((item) => {
    if (item.scheduled_percent_complete)
      meta.scheduled_percent_complete.push(item.scheduled_percent_complete);
    if (item.percent_complete)
      meta.percent_complete.push(item.percent_complete);
    if (item.weightage)
      meta.weightage.push(item.weightage);
    meta.multiply_percent_and_weightage_values.push(safeMultiplication(item.percent_complete, item.weightage));
    meta.multiply_schedule_percent_and_weightage_values.push(safeMultiplication(item.scheduled_percent_complete, item.weightage));
  });

  const percentage_complete = sum(meta.multiply_percent_and_weightage_values);
  const avg_scheduled_percent_complete = sum(meta.multiply_schedule_percent_and_weightage_values);
  const avg_diff = safeSubtraction(percentage_complete, avg_scheduled_percent_complete);

  const completion_percentage = round(percentage_complete * 100) || 0;
  const status = avg_diff > 0 ? 'Ahead' : 'Behind';

  return { completion_percentage, status };
}
async function fitBounds(map_id) {
  const map_hash = {
    'map-viewer-1': map_instance_1.value,
    'map-viewer-compare-1': map_instance_compare_1.value,
    'map-viewer-compare-2': map_instance_compare_2.value,
  };
  const bounds = turf.bbox({
    type: 'FeatureCollection',
    features: [

      ...((terra_store.container?.block_boundary?.features) || []),
    ],
  });

  await map_hash[map_id].fitBounds(bounds);
}
async function loadBoundaryColorsForMap(map_id, fit_bounds = false) {
  let temp_features = (terra_store.container?.block_boundary?.features) || [];
  let selected_workflow_project_hash = groupBy(selected_workflow_data.value, 'project');
  let field_project_metrics = state.field_project_metrics;
  if (map_id === 'map-viewer-compare-1') {
    selected_workflow_project_hash = groupBy(Object.values(state.prev_field_project_metrics), 'project');
    field_project_metrics = state.prev_field_project_metrics;
  }

  temp_features = temp_features.reduce((acc, feature) => {
    const project_level_data = selected_workflow_project_hash[feature.properties.name];
    if (project_level_data && (Object.keys(state.filtered_projects).length === 0 || state.filtered_projects[feature.properties.name])) {
      if (state.selected_activity_workflow_field === 'Overall progress') {
        const { completion_percentage, status } = getCompletionPercentageAndStatus(project_level_data);
        feature.properties.completion_percentage = completion_percentage;
        feature.properties.status = status;
      }
      else {
        const percent_complete = field_project_metrics[`${state.selected_activities_workflow}:${feature.properties.name}:${state.selected_activity_workflow_field}`]?.percent_complete;
        feature.properties.completion_percentage = round(percent_complete * 100) || 0;
        feature.properties.status = state.map_color_by === 'Work rate' ? field_project_metrics[`${state.selected_activities_workflow}:${feature.properties.name}:${state.selected_activity_workflow_field}`]?.work_rate_status : field_project_metrics[`${state.selected_activities_workflow}:${feature.properties.name}:${state.selected_activity_workflow_field}`]?.status;
      }

      acc.push(feature);
    }
    return acc;
  }, []);
  const map_hash = {
    'map-viewer-1': map_instance_1.value,
    'map-viewer-compare-1': map_instance_compare_1.value,
    'map-viewer-compare-2': map_instance_compare_2.value,
  };
  await map_hash[map_id].getSource('all_features_source')?.setData({
    type: 'FeatureCollection',
    features: temp_features,
  });
  if (fit_bounds)
    await fitBounds(map_id);
}

async function getRangeAnalytics(use_local_data = false) {
  try {
    state.pr_range_data_loading = true;
    // Get the current date
    const currentDate = dayjs();
    const prior_days = getPriorDay(currentDate, state.form.range_date);
    const current_previous = getPriorDay(prior_days, state.form.range_date);
    const current_start_date = prior_days.format('YYYY-MM-DD');
    let current_end_date = currentDate.format('YYYY-MM-DD');
    const previous_start_date = current_previous.format('YYYY-MM-DD');
    const previous_end_date = current_start_date;
    if (!use_local_data) {
      const range_analytics_response_current = await $services.terra.get_range_analytics_progress_history_metrics({
        container_id: terra_store.container?.uid,
        body: {
          start_date: current_start_date,
          end_date: current_end_date,
        },
      });
      state.range_analytics_response_current = range_analytics_response_current;
      logger.log('Range analytics response current', range_analytics_response_current.data);
      const range_analytics_response_previous = await $services.terra.get_range_analytics_progress_history_metrics({
        container_id: terra_store.container?.uid,
        body: {
          start_date: previous_start_date,
          end_date: previous_end_date,
        },
      });
      state.range_analytics_response_previous = range_analytics_response_previous;
      logger.log('Range analytics response previous', range_analytics_response_previous.data);
    }
    const range_data_skeleton = {
      workflow: null,
      field: null,
      project: null,
      cr_duration: null,
      pr_duration: bDuration(previous_start_date, previous_end_date),
      cr_work: null,
      pr_work: null,
      cr_scheduled_work: null,
      pr_scheduled_work: null,
      cr_difference_work: null,
      pr_difference_work: null,
      cr_work_rate: null,
      pr_work_rate: null,
      cr_difference_work_rate: null,
      pr_difference_work_rate: null,
      cr_percent_complete: null,
      pr_percent_complete: null,
      cr_start_scheduled_percent_complete: null,
      cr_finish_scheduled_percent_complete: null,
      pr_start_scheduled_percent_complete: null,
      pr_finish_scheduled_percent_complete: null,
      cr_scheduled_percent_complete: null,
      pr_scheduled_percent_complete: null,
      cr_difference_percent_complete: null,
      pr_difference_percent_complete: null,
      range_difference_percent: null,
      cr_est_finish: null,
      pr_est_finish: null,
      cr_difference_finish: null,
      cr_status: null,
      cr_efficiency: null,
      pr_efficiency: null,
    };
    // Adding +1 after API call for bDuration correct calculations as end date is not considered in bDuration
    current_end_date = dayjs(currentDate).add(1, 'days').format('YYYY-MM-DD');
    const field_hash_metrics = {};
    state.range_analytics_response_current.data.forEach((item) => {
      const workflow_data = terra_store.workflow_fields_hash({ workflow: item.workflow });
      const data = { ...range_data_skeleton };
      if (workflow_data) {
        const project_name = terra_store.active_projects_data_map({ all_projects: true })[item.project].name;
        const workflow_name = workflow_data?.name;
        const field_name = workflow_data?.fields[item.field]?.name;
        const field_project_data = state.field_project_metrics[`${workflow_name}:${project_name}:${field_name}`] || {};

        data.workflow = workflow_name;
        data.field = field_name;
        data.project = project_name;
        data.cr_work = item.total_value;
        let cr_duration_from;
        let cr_duration_to;
        if (dayjs(field_project_data.actual_start ?? undefined).isAfter(current_start_date))
          cr_duration_from = field_project_data.actual_start;
        else if (dayjs(field_project_data.planned_start ?? undefined).isAfter(current_start_date))
          cr_duration_from = field_project_data.planned_start;
        else cr_duration_from = current_start_date;
        if (field_project_data.actual_finish && dayjs(field_project_data.actual_finish).isBefore(current_end_date))
          cr_duration_to = field_project_data.actual_finish;
        else if (field_project_data.latest_date && dayjs(field_project_data.latest_date).isBefore(current_end_date))
          cr_duration_to = field_project_data.latest_date;
        else if (field_project_data.planned_finish && dayjs(field_project_data.planned_finish).isBefore(current_end_date))
          cr_duration_to = field_project_data.planned_finish;
        else cr_duration_to = current_end_date;
        const cr_duration_val = bDuration(cr_duration_from, cr_duration_to);
        data.cr_duration = cr_duration_val === 0 ? 1 : cr_duration_val;
        if (field_project_data.planned_start) {
          const cr_start_scheduled_percent_complete = min([1, safeDivision(bDuration(field_project_data.planned_start, current_start_date), field_project_data.planned_duration)]);
          const cr_finish_scheduled_percent_complete = min([1, safeDivision(bDuration(field_project_data.planned_start, current_end_date), field_project_data.planned_duration)]);
          if (cr_start_scheduled_percent_complete < 0)
            data.cr_start_scheduled_percent_complete = 0;
          else data.cr_start_scheduled_percent_complete = cr_start_scheduled_percent_complete;
          if (cr_finish_scheduled_percent_complete < 0)
            data.cr_finish_scheduled_percent_complete = 0;
          else data.cr_finish_scheduled_percent_complete = cr_finish_scheduled_percent_complete;
        }

        data.cr_work_rate = safeDivision(data.cr_work, data.cr_duration);
        data.cr_difference_work_rate = safeSubtraction(data.cr_work_rate, field_project_data.planned_work_rate);
        data.cr_percent_complete = safeDivision(data.cr_work, field_project_data.planned_work);

        const cr_schedule_value = safeDivision(
          min([1, bDuration(
            dayjs.max([dayjs(field_project_data.planned_start), dayjs(current_start_date)]),
            dayjs.min([dayjs(field_project_data.planned_finish), dayjs(current_end_date)]),
          )]),
          field_project_data.planned_duration,
        );
        if (cr_schedule_value < 0)
          data.cr_scheduled_percent_complete = 0;
        else data.cr_scheduled_percent_complete = cr_schedule_value;
        data.cr_scheduled_work = safeMultiplication(data.cr_scheduled_percent_complete, field_project_data.planned_work);
        data.cr_difference_work = safeSubtraction(data.cr_work, data.cr_scheduled_work);
        if (field_project_data.remaining_work && data.cr_work_rate)
          data.cr_est_finish = dayjs().businessDaysAdd(safeDivision(field_project_data.remaining_work, data.cr_work_rate)).format('DD MMM YYYY');
        data.cr_difference_percent_complete = safeSubtraction(data.cr_percent_complete, data.cr_scheduled_percent_complete);
        data.cr_difference_finish = bDuration(field_project_data.planned_finish, data.cr_est_finish);
        if (data.cr_est_finish && field_project_data.planned_finish && dayjs(data.cr_est_finish).isAfter(field_project_data.planned_finish))
          data.cr_status = 'Behind';
        else if (data.cr_est_finish && field_project_data.planned_finish && dayjs(data.cr_est_finish).isBefore(field_project_data.planned_finish))
          data.cr_status = 'Ahead';
        else data.cr_status = 'NA';
        if (data.cr_work_rate && field_project_data.planned_work_rate) {
          const cr_efficiency = safeDivision(data.cr_work_rate, field_project_data.planned_work_rate);
          data.cr_efficiency = data.cr_scheduled_percent_complete > 0 ? cr_efficiency : 1 + cr_efficiency;
        }
        field_hash_metrics[`${data.workflow}:${data.project}:${data.field}`] = data;
      }
    });
    state.range_analytics_response_previous.data.forEach((item) => {
      const workflow_data = terra_store.workflow_fields_hash({ workflow: item.workflow });
      if (workflow_data) {
        const project_name = terra_store.active_projects_data_map({ all_projects: true })[item.project].name;
        const workflow_name = workflow_data?.name;
        const field_name = workflow_data?.fields[item.field]?.name;
        const field_project_data = state.field_project_metrics[`${workflow_name}:${project_name}:${field_name}`] || {};
        const data = { ...field_hash_metrics[`${workflow_name}:${project_name}:${field_name}`] };
        data.workflow = workflow_name;
        data.field = field_name;
        data.project = project_name;
        data.pr_work = item.total_value;
        if (field_project_data.planned_start) {
          const pr_start_scheduled_percent_complete = min([1, safeDivision(bDuration(field_project_data.planned_start, previous_start_date), field_project_data.planned_duration)]);
          const pr_finish_scheduled_percent_complete = min([1, safeDivision(bDuration(field_project_data.planned_start, previous_end_date), field_project_data.planned_duration)]);
          if (pr_start_scheduled_percent_complete < 0)
            data.pr_start_scheduled_percent_complete = 0;
          else data.pr_start_scheduled_percent_complete = pr_start_scheduled_percent_complete;
          if (pr_finish_scheduled_percent_complete < 0)
            data.pr_finish_scheduled_percent_complete = 0;
          else data.pr_finish_scheduled_percent_complete = pr_finish_scheduled_percent_complete;
        }

        data.pr_work_rate = safeDivision(data.pr_work, data.pr_duration);
        data.pr_difference_work_rate = safeSubtraction(data.pr_work_rate, field_project_data.planned_work_rate);
        data.pr_percent_complete = safeDivision(data.pr_work, field_project_data.planned_work);
        const pr_schedule_value = safeDivision(
          min([1, bDuration(
            dayjs.max([dayjs(field_project_data.planned_start), dayjs(previous_start_date)]),
            dayjs.min([dayjs(field_project_data.planned_finish), dayjs(previous_end_date)]),
          )]),
          field_project_data.planned_duration,
        );
        if (pr_schedule_value < 0)
          data.pr_scheduled_percent_complete = 0;
        else data.pr_scheduled_percent_complete = pr_schedule_value;
        data.pr_scheduled_work = safeMultiplication(data.pr_scheduled_percent_complete, field_project_data.planned_work);
        data.pr_difference_percent_complete = safeSubtraction(data.pr_percent_complete, data.pr_scheduled_percent_complete);
        data.pr_difference_work = safeSubtraction(data.pr_work, data.pr_scheduled_work);
        data.range_difference_percent = round(safeMultiplication(safeDivision(safeSubtraction(data.cr_work, data.pr_work), data.pr_work), 100));
        data.pr_est_finish = (field_project_data.remaining_work && !isNil(data.cr_work) && !isNil(data.pr_work) && data.pr_work_rate) ? dayjs().businessDaysAdd(safeDivision(safeSubtraction(safeSubtraction(field_project_data.remaining_work, data.cr_work), data.pr_work)), data.pr_work_rate).format('DD MMM YYYY') : null;
        if (data.pr_work_rate && field_project_data.planned_work_rate) {
          const pr_efficiency = safeDivision(data.cr_work_rate, field_project_data.planned_work_rate);
          data.pr_efficiency = data.pr_scheduled_percent_complete > 0 ? pr_efficiency : 1 + pr_efficiency;
        }
        field_hash_metrics[`${data.workflow}:${data.project}:${data.field}`] = data;
      }
    });
    logger.log('Range analytics data by project and field', field_hash_metrics);
    state.range_project_field_hash_metrics = { ...field_hash_metrics };
    state.pr_range_data_loading = false;
  }
  catch (error) {
    logger.error(error);
    state.pr_range_data_loading = false;
  }
}
function plannedStartFinishParser({ workflow, field, project, type }) {
  const schedules = terra_store.container?.schedule;
  const data = schedules?.[workflow]?.[field];
  if (data) {
    const parsed_start_date = `${project}_start_date`;
    const parsed_finish_date = `${project}_finish_date`;
    const planned_start_date = data[parsed_start_date];
    const planned_finish_date = data[parsed_finish_date];
    if (type === 'start')
      return planned_start_date;
    else if (type === 'finish')
      return planned_finish_date;
    return [planned_start_date, planned_finish_date];
  }
  return null;
}
function parseMasterData(prev_data_field_project) {
  try {
    const progress_data_hash = {};
    state.terra_metrics_response.forEach((progress) => {
      progress_data_hash[`${progress.project}:${progress.field}`] = {
        terra: progress,
        project: progress.project,
        field: progress.field,
        pm: {},
      };
    });

    const metrics_data = [];
    const data_skeleton = {
      project: null,
      workflow_uid: null,
      field_uid: null,
      field: null,
      field_type: null,
      workflow: null,
      units: null,
      weightage: null,
      planned_start: null,
      planned_finish: null,
      actual_start: null,
      actual_finish: null,
      planned_duration: null,
      actual_duration: null,
      remaining_duration: null,
      planned_work: null,
      actual_work: null,
      percent_complete: null,
      scheduled_percent_complete: null,
      difference_percent_complete: null,
      scheduled_work: null,
      remaining_work: null,
      difference_work: null,
      planned_work_rate: null,
      actual_work_rate: null,
      difference_work_rate: null,
      required_work_rate: null,
      est_finish: null,
      difference_finish: null,
      status: null,
      work_rate_status: null,
      latest_date: null,
      field_index: null,
      efficiency: null,
    };
    const field_project_metrics = {};
    Object.values(progress_data_hash).forEach((progress_data) => {
      const terra_data = progress_data.terra;
      const workflow_data = terra_store.workflow_fields_hash({ workflow: terra_data.workflow });
      const field_data = workflow_data?.fields[terra_data.field];
      if (workflow_data && field_data) {
        let prev_data = {};
        const data = { ...data_skeleton };
        data.project = terra_store.active_projects_data_map({ all_projects: true })[terra_data.project]?.name;
        data.project_uid = terra_data.project;
        data.field = field_data?.name;
        data.field_uid = field_data?.uid;
        data.field_type = field_data?.type;
        data.workflow_uid = workflow_data?.uid;
        if (state.compare_maps)
          prev_data = prev_data_field_project[`${workflow_data.name}:${data.project}:${data.field}`];
        data.workflow = workflow_data.name;
        data.field_index = field_data?.field_index;
        data.units = field_data?.units;
        data.weightage = workflow_data?.meta?.progress_type === 'cumulative' ? Number(workflow_data?.meta?.weights[field_data?.uid]) : safeDivision(1, Object.keys(workflow_data?.fields)?.length);
        data.planned_start = plannedStartFinishParser({
          workflow: data.workflow,
          field: data.field,
          project: data.project,
          type: 'start',
        });
        data.planned_finish = plannedStartFinishParser({
          workflow: data.workflow,
          field: data.field,
          project: data.project,
          type: 'finish',
        });
        data.actual_start = terra_data.actual_start_date;
        data.actual_work = terra_data.current;
        data.latest_date = terra_data.latest_date;
        data.actual_finish = terra_data.actual_finish_date;
        data.planned_duration = bDuration(data.planned_start, data.planned_finish);
        data.actual_duration = bDuration(data.actual_start, (data.actual_finish || data.latest_date || new Date()));
        if (data.actual_start && data.actual_duration === 0)
          data.actual_duration = 1;
        data.remaining_duration = Math.abs(bDuration(new Date(), data.planned_finish));
        if (state.compare_maps && prev_data_field_project) {
          data.actual_work = prev_data?.current;
          data.actual_start = dayjs(data.actual_start).isAfter(dayjs(state.form.date)) ? null : data.actual_start;
          data.actual_finish = dayjs(data.actual_finish).isAfter(dayjs(state.form.date)) ? null : data.actual_finish;
          data.actual_duration = bDuration(data.actual_start, (data.actual_finish || data.latest_date || state.form.date));
          data.remaining_duration = Math.abs(bDuration(state.form.date, data.planned_finish));
        }

        data.planned_work = terra_data.total;
        data.percent_complete = safeDivision(data.actual_work, data.planned_work);
        if (data.planned_start && data.planned_duration) {
          const duration = safeDivision(bDuration(data.planned_start, new Date()), data.planned_duration);
          data.scheduled_percent_complete = min([1, duration < 0 ? 0 : duration]);
        }
        data.difference_percent_complete = safeSubtraction(data.percent_complete, data.scheduled_percent_complete);
        data.scheduled_work = safeMultiplication(data.scheduled_percent_complete, data.planned_work);
        data.remaining_work = safeSubtraction(data.planned_work, data.actual_work);
        data.difference_work = safeSubtraction(data.actual_work, data.scheduled_work);
        data.planned_work_rate = round(safeDivision(data.planned_work, data.planned_duration));
        data.actual_work_rate = safeDivision(data.actual_work, data.actual_duration);
        data.difference_work_rate = safeSubtraction(data.actual_work_rate, data.planned_work_rate);
        if (data.planned_finish && dayjs().isSameOrAfter(dayjs(data.planned_finish)))
          data.required_work_rate = data.remaining_work;

        else data.required_work_rate = safeDivision(data.remaining_work, data.remaining_duration);
        if (data.remaining_work && data.actual_work_rate) {
          const days_from_now = dayjs().add(safeDivision(data.remaining_work, data.actual_work_rate), 'day');
          const business_days = bDuration(state.compare_maps ? state.form.date : dayjs(), days_from_now);
          data.est_finish = dayjs().businessDaysAdd(business_days).format('DD MMM YYYY');
        }

        data.difference_finish = bDuration(data.planned_finish, data.est_finish);
        if ((dayjs(data.planned_start).isAfter(dayjs()) && data.percent_complete === 0)) {
          data.status = 'Not started';
          data.work_rate_status = 'Not started';
        }

        else if (data.percent_complete === 1) {
          data.status = 'Completed';
          data.work_rate_status = 'Completed';
        }
        else if (data.est_finish && data.planned_finish && dayjs(data.est_finish).isAfter(dayjs(data.planned_finish))) {
          data.status = 'Behind';
        }
        else if (data.est_finish && data.planned_finish && dayjs(data.est_finish).isBefore(dayjs(data.planned_finish))) {
          data.status = 'Ahead';
        }
        else if (dayjs(data.planned_start).isBefore(dayjs())) {
          data.status = 'Behind';
        }

        else { data.status = 'NA'; }
        if (isNil(data.work_rate_status)) {
          if (data.difference_work_rate > 0)
            data.work_rate_status = 'Ahead';

          else if (isNil(data.difference_work_rate))
            data.work_rate_status = 'NA';
          else data.work_rate_status = 'Behind';
        }
        if (data.actual_work_rate && data.planned_work_rate) {
          const efficiency = safeDivision(data.actual_work_rate, data.planned_work_rate);
          data.efficiency = data.scheduled_percent_complete > 0 ? efficiency : 1 + efficiency;
        }
        metrics_data.push({ ...data });
        field_project_metrics[`${data.workflow}:${data.project}:${data.field}`] = data;
      }
    });

    logger.log('Terra and pm data by project and field', field_project_metrics);

    return { metrics_data, field_project_metrics };
  }
  catch (err) {
    logger.error(err);
  }
}
async function getData(use_local_data = false) {
  try {
    if (!use_local_data) {
      const response = await $services.terra.get_progress_updates_metrics({
        container_id: terra_store.container?.uid,
      });
      logger.log('Terra metrics response', response.data);

      state.terra_metrics_response = response.data;
    }
    const { metrics_data, field_project_metrics } = parseMasterData();
    logger.log('🚀 ~ file: terra-charts.vue:1031 ~ getData ~ field_project_metrics:', field_project_metrics);
    state.progress_data = Object.values(field_project_metrics);
    state.field_project_metrics = field_project_metrics;
    selectWorkflow(metrics_data[0]?.workflow, false);
    state.selected_range_metrics_field = unique_fields.value[1]?.name;
    state.selected_project_for_range_metrics = unique_projects.value[0];
    await getRangeAnalytics(use_local_data);
  }
  catch (err) {
    logger.error(err);
  }
}

async function toggleCompare() {
  state.compare_maps = !state.compare_maps;
  if (state.compare_maps) {
    map_instance_1.value = null;
    await selectDateForPrevMasterData(state.form.date, false);
    nextTick(async () => {
      map_instance_compare_1.value = await initMapbox({
        container_id: 'map-viewer-compare-1',

      });
      map_instance_compare_2.value = await initMapbox({
        container_id: 'map-viewer-compare-2',

      });
      syncMaps(map_instance_compare_1.value, map_instance_compare_2.value);
    });
  }

  else {
    map_instance_compare_1.value = map_instance_compare_2.value = null;
    nextTick(async () => {
      map_instance_1.value = await initMapbox({
        container_id: 'map-viewer-1',
      });
    });
  }
}
function selectRange(e) {
  state.form.range_date = e;
  getRangeAnalytics();
  terra_store.terra_track_events('Charts metrics range changed', { range: e });
}
function selectWorkflow(workflow, load_map_colors = true) {
  state.selected_activities_workflow = workflow;
  selectActivityField(unique_fields.value[0]?.name, load_map_colors);
  state.selected_range_metrics_field = unique_fields.value[1]?.name;
  state.selected_project_for_range_metrics = auth_store.check_split('range_overall_progress') ? 'Overall progress' : unique_projects.value[0];
}
function selectActivityField(field, load_map_colors = true) {
  state.selected_activity_workflow_field = field;
  if (field !== 'Overall progress')
    state.selected_range_metrics_field = field;

  if (load_map_colors) {
    if (state.compare_maps) {
      loadBoundaryColorsForMap('map-viewer-compare-1');
      loadBoundaryColorsForMap('map-viewer-compare-2');
    }
    else { loadBoundaryColorsForMap('map-viewer-1'); }
  }
}
function selectColorByStrategy(e, type = 'maps') {
  const map_hash = {
    map_instance: map_instance_1.value,
    map_instance_compare_1: map_instance_compare_1.value,
    map_instance_compare_2: map_instance_compare_2.value,
  };
  const instances = state.compare_maps
    ? ['map_instance_compare_1', 'map_instance_compare_2']
    : ['map_instance'];

  try {
    const layers = ['fill_layer', 'line_layer', 'label_layer'];
    if (type === 'maps') {
      state.map_color_by = e;
      layers.forEach((layer) => {
        instances.forEach((instance) => {
          if (map_hash[instance].getLayer(layer))
            map_hash[instance].removeLayer(layer);
        });
      });
      instances.forEach((instance) => {
        if (state.map_color_by === 'Status')
          setLayers(layers_map.color_by_status, map_hash[instance]);
        else if (state.map_color_by === 'Percentage')
          setLayers(layers_map.color_by_percentage, map_hash[instance]);
        else if (state.map_color_by === 'Work rate')
          setLayers(layers_map.color_by_status, map_hash[instance]);
      });
      if (state.compare_maps) {
        loadBoundaryColorsForMap('map-viewer-compare-1');
        loadBoundaryColorsForMap('map-viewer-compare-2');
      }
      else { loadBoundaryColorsForMap('map-viewer-1'); }
      terra_store.terra_track_events('Charts map view changed', { view: state.map_color_by });
    }
  }
  catch (err) {
    logger.error(err);
  }
}

function onMapMouseMoveEvent(e, map_hash, instance) {
  if (state.selected_block)
    return;
  if (e.features.length > 0) {
    if (!(isNil(state.hovered_block?.id))) {
      map_hash[instance].setFeatureState(
        { source: 'all_features_source', id: state.hovered_block?.id },
        { hover: false },
      );
    }

    state.hovered_block = e.features[0];
    map_hash[instance].setFeatureState(
      { source: 'all_features_source', id: state.hovered_block?.id },
      { hover: true },
    );
  }
}
function onMapMouseLeaveEvent(map_hash, instance) {
  if (state.selected_block)
    return;
  if (!(isNil(state.hovered_block?.id))) {
    map_hash[instance].setFeatureState(
      { source: 'all_features_source', id: state.hovered_block?.id },
      { hover: false },
    );
  }
  state.hovered_block = null;
}
function loadMapEvents() {
  const map_hash = {
    map_instance: map_instance_1.value,
    map_instance_compare_1: map_instance_compare_1.value,
    map_instance_compare_2: map_instance_compare_2.value,
  };
  const layer = 'fill_layer';
  const instances = state.compare_maps
    ? ['map_instance_compare_1', 'map_instance_compare_2']
    : ['map_instance'];
  instances.forEach((instance) => {
    map_hash[instance].on('mousemove', layer, (e) => {
      onMapMouseMoveEvent(e, map_hash, instance);
    });
    map_hash[instance].on('click', (e) => {
      const features = map_hash[instance].queryRenderedFeatures(e.point, { layers: [layer] });
      if (features.length > 0 && state.selected_activity_workflow_field !== 'Overall progress') {
        if (!(isNil(state.selected_block?.id))) {
          map_hash[instance].setFeatureState(
            { source: 'all_features_source', id: state.selected_block?.id },
            { hover: false },
          );
        }

        state.selected_block = features[0];
        if (state.selected_activity_workflow_field !== 'Overall progress') {
          state.selected_project_for_range_metrics = state.selected_block?.properties.name;
          state.selected_range_metrics_field = state.selected_activity_workflow_field;
        }
        map_hash[instance].setFeatureState(
          { source: 'all_features_source', id: state.selected_block?.id },
          { hover: true },
        );
      }
      else {
        map_hash[instance].setFeatureState(
          { source: 'all_features_source', id: state.selected_block?.id },
          { hover: false },
        );
        state.selected_block = null;
        state.hovered_block = null;
      }
    });
    map_hash[instance].on('mouseleave', layer, () => {
      onMapMouseLeaveEvent(map_hash, instance);
    });
  });
}

function resetMap() {
  const bounds = turf.bbox({
    type: 'FeatureCollection',
    features: [

      ...((terra_store.container?.block_boundary?.features) || []),
    ],
  });
  if (state.compare_maps) {
    map_instance_compare_1.value.fitBounds(bounds);
    map_instance_compare_2.value.fitBounds(bounds);
  }
  else { map_instance_1.value.fitBounds(bounds); }
}
async function selectDateForPrevMasterData(e, load_map_colors = true) {
  state.form.date = e;
  const response = await $services.terra.post({
    url: `container/${terra_store.container?.uid}/metrics/latest-updates`,
    body: {
      latest_date: dayjs(state.form.date).toISOString(),
    },

  });
  const prev_field_project_data = {};

  response.data.forEach((item) => {
    const workflow_data = terra_store.workflow_fields_hash({ workflow: item.workflow });
    if (workflow_data) {
      const payload = {};
      const field_data = workflow_data?.fields[item.field];

      payload.project = terra_store.active_projects_data_map({ all_projects: true })[item.project].name;
      payload.field = field_data?.name;
      payload.workflow = workflow_data.name;
      payload.current = item.current_count;
      prev_field_project_data[`${state.selected_activities_workflow}:${payload.project}:${payload.field}`] = { ...payload };
    }
  });
  const res = parseMasterData(prev_field_project_data);
  state.prev_field_project_metrics = res.field_project_metrics;
  if (load_map_colors)
    loadBoundaryColorsForMap('map-viewer-compare-1');
}
async function selectSettingsOption(e) {
  switch (e.value) {
    case 'schedule_tracker':
      state.is_schedule_tracker_open = true;
      break;
    case 'publish_unpublish':
      try {
        state.is_container_patch_loading = true;
        const response = await $services.terra.patch({
          url: `containers/${terra_store.container?.uid}`,
          body: {
            properties: {
              ...(terra_store.container.properties || {}),
              is_charts_published: !terra_store.container.properties?.is_charts_published,
            },
          },

        });
        terra_store.set_container({
          container: { ...terra_store.container, properties: response.data.properties },
        });
        $toast({
          text: response.data.properties.is_charts_published ? $t('Published successfully') : $t('Unpublished successfully'),
          type: 'success',
        });
        terra_store.terra_track_events(`Charts ${response.data.properties.is_charts_published ? 'published' : 'unpublished'}`);
        state.is_container_patch_loading = false;
      }
      catch (err) {
        logger.error(err);
        state.is_container_patch_loading = false;
      }
      break;
    case 'import_boundaries':
      file_input_ref.value.click();
      break;

    default:
      break;
  }
}
function handleFileChange(event) {
  const fileObj = event?.target?.files?.[0];
  if (!fileObj)
    return;
  event.target.value = null;

  if (fileObj) {
    const reader = new FileReader();

    reader.onload = async function (e) {
      const content = e.target.result;

      try {
        const geojson = JSON.parse(content);
        state.is_container_patch_loading = true;
        const response = await $services.terra.patch({
          url: `containers/${terra_store.container?.uid}`,
          body: {
            block_boundary: {
              type: 'FeatureCollection',
              features: geojson.features,
            },
          },

        });

        await terra_store.set_container({
          container: { ...terra_store.container, block_boundary: response.data.block_boundary },
        });
        await loadBoundaryColorsForMap('map-viewer-1', true);
        $toast({
          text: 'Boundaries imported successfully',
          type: 'success',
        });
        state.is_container_patch_loading = false;
      }
      catch (error) {
        logger.error('Error parsing GeoJSON:', error);
        $toast({
          text: 'Error parsing the file',
          type: 'error',
        });
        state.is_container_patch_loading = false;
      }
    };

    reader.readAsText(fileObj);
  }
}
function openFilterLayerPopup() {
  const project_hash = groupBy(selected_workflow_data.value, 'project');
  const uniq_projects = uniq(Object.keys(project_hash));
  let keys = Object.keys(state.filtered_projects);
  if (!keys.length)
    keys = sortBy(uniq_projects, item => item);
  filter_selector_modal.patchOptions({
    attrs: {
      texts: {
        heading: $t('Filter layers'),
        left_heading: $t('Available layers'),
        right_heading: $t('Displayed layers'),
      },
      all_columns: uniq_projects.map(key => ({ name: key, id: key })),
      active_columns: keys.map(key => ({ name: key, id: key })),
      onSave(event) {
        state.filtered_projects = event.reduce((acc, column, i) => {
          acc[column.id] = i + 1;
          return acc;
        }, {});
        state.selected_project_for_range_metrics = auth_store.check_split('range_overall_progress') ? 'Overall progress' : unique_projects.value[0];
        state.re_render_heatmap += 1;
        loadBoundaryColorsForMap('map-viewer-1');
      },
    },
  });
  filter_selector_modal.open();
}
async function exportingReport() {
  if (!state.progress_data.length) {
    setExporting(false);
    return;
  }
  await exportTerraReports(state.progress_data);
  terra_store.terra_track_events('Exported charts data');
}
</script>

<template>
  <HawkExportToast
    v-if="is_report_exporting"
    :submit="exportingReport"
    progress_text="Exporting report"
    completed_text="Report exported"
    @cancel="cancelExportingTerraReport"
    @close="() => setExporting(false)"
  />
  <div :class="add_classes">
    <div v-show="state.is_loading" class="absolute h-full w-full z-40 flex justify-center items-center" :class="add_classes">
      <HawkLoader />
    </div>
    <div v-show="!state.is_loading && !state.is_schedule_tracker_open">
      <input ref="file_input_ref" type="file" class="hidden" @change="handleFileChange">
      <div v-if="!terra_store.container.properties?.is_charts_published" class="flex p-4 m-2 border border-[#FEC84B] rounded-lg bg-[#FFFCF5] items-center">
        <div class="mr-2">
          <IconWarn class="text-[#DC6803]" />
        </div>
        <div class="text-[#B54708] text-sm font-semibold">
          {{ $t('The dashboard is not published and is not available for the users.') }}
        </div>
      </div>
      <HawkPageHeader :title="`${$t('Activities')}`" class="flex">
        <template #right>
          <div class="flex gap-3 items-center">
            <HawkLoader
              v-if="state.is_container_patch_loading"
              class="w-6 h-6 !m-0"
            />
            <HawkButton icon type="text">
              <IconHawkFilterFunnelOne @click="openFilterLayerPopup" />
            </HawkButton>

            <HawkMenu
              v-if="type === 'terra-charts'"
              :items="settings_menu"
              @select="selectSettingsOption"
            >
              <template #trigger>
                <HawkButton icon type="text">
                  <IconHawkSettingsOne />
                </HawkButton>
              </template>
              <template #item="{ item }">
                <div v-if="item.label === 'Export'" class="flex items-center">
                  <div class="mr-2">
                    {{ item.label }}
                  </div>
                </div>
                <div v-else>
                  {{ item.label }}
                </div>
              </template>
            </HawkMenu>
          </div>
        </template>
      </HawkPageHeader>

      <div class="px-4 flex flex-col gap-6">
        <HawkHorizontalCardSlider :items="sortBy(Object.values(workflow_metrics) || [], ['name'])">
          <template #content="{ content }">
            <div class="w-56 bg-white border rounded-2xl border-gray-200 p-3 grid gap-3 hover:bg-gray-50 cursor-pointer" :class="{ 'border-primary-700': state.selected_activities_workflow === content.name }" @click="selectWorkflow(content.name)">
              <HawkText class="text-sm font-medium" :content="content.name" :length="22" />

              <div class="flex gap-2 items-center">
                <div class="font-semibold text-lg">
                  {{ round(content.avg_percent_complete * 100) }}%
                </div>
                <div v-if="!isNil(content.avg_diff) && content.avg_diff !== 0">
                  <HawkBadge v-if="content.avg_diff < 0" :is_rounded="false" color="red">
                    <span>{{ content.avg_diff }} %</span>
                  </HawkBadge>
                  <HawkBadge v-else :is_rounded="false" color="green">
                    <span>+</span>
                    <span>{{ content.avg_diff }}%</span>
                  </HawkBadge>
                </div>
              </div>
            </div>
          </template>
        </HawkHorizontalCardSlider>

        <div v-show="state.selected_activities_workflow" class="bg-white p-5 border rounded-2xl border-gray-200">
          <div class="flex items-center justify-between flex-wrap gap-2">
            <div class="text-base font-semibold">
              {{ workflow_metrics[state.selected_activities_workflow]?.name }}
            </div>
            <div class="flex gap-3">
              <HawkButton icon type="text">
                <IconHawkRefreshCcwFive @click="resetMap" />
              </HawkButton>
              <HawkButton icon type="text" @click="toggleCompare">
                <IconHawkReflectTwo />
              </HawkButton>

              <Vueform>
                <SelectElement
                  name="map_color_by"
                  size="sm"
                  :default="state.map_color_by"
                  :native="false"
                  :can-deselect="false"
                  :can-clear="false"
                  :items="color_by_menu"
                  @select="selectColorByStrategy($event, 'maps')"
                />
              </Vueform>
            </div>
          </div>
          <hr class="my-4">
          <div class="lg:flex gap-4 sm:grid sm:grid-cols-1">
            <div class="basis-60 max-h-[540px] scrollbar flex-shrink-0">
              <div
                v-for="item in unique_fields"
                :key="item.id" class="p-3 border border-white hover:border-gray-500 rounded-lg text-sm flex justify-between gap-2 cursor-pointer" :class="{ '!border-blue-500 font-semibold': state.selected_activity_workflow_field === item.name }"
                @click="selectActivityField(item.name)"
              >
                <HawkText :content="item.name" :length="20" />
                <div class="flex gap-1 items-center flex-shrink-0">
                  <div class="font-normal">
                    {{ round(item.percent_complete * 100) }} %
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full">
              <div class="flex items-center  mb-4 gap-5 flex-wrap justify-start md:justify-end">
                <div v-for="item in map_color_by_menu" :key="item.id" class="flex items-center text-xs">
                  <div class="h-3 w-3 flex-shrink-0 rounded-full" :class="item.color" />
                  <span class="ml-2 mr-1">{{ item.name }}</span>
                  <span>{{ item.value }}</span>
                </div>
              </div>
              <div v-if="state.compare_maps" class="flex gap-4 place-content-center">
                <div id="map-viewer-compare-1" class="bg-gray-100 basis-1/2 h-[540px] rounded-2xl relative">
                  <div class="absolute top-2 right-2 rounded-lg  z-1 bg-white">
                    <Vueform size="sm">
                      <DateTimeElement
                        name="date"
                        :add-class="{
                          input: 'w-[185px] pr-[10px]',
                        }"

                        :loading="true"
                        :default="dayjs().format('YYYY-MM-DD')"
                        :options="{
                          clearable: false,

                        }"
                        @change="selectDateForPrevMasterData"
                      />
                    </Vueform>
                  </div>
                </div>
                <div id="map-viewer-compare-2" class="bg-gray-100 basis-1/2 h-[540px] rounded-2xl relative">
                  <div class="absolute top-2 right-2 rounded-lg px-2 py-1 z-1 bg-white">
                    Current
                  </div>
                </div>
              </div>
              <div v-else class=" bg-gray-100 rounded-2xl place-content-center w-full">
                <div id="map-viewer-1" class="h-[540px] w-full">
                  <div v-if="state.selected_activity_workflow_field !== 'Overall progress' && (state.hovered_block || state.selected_block)" class="bg-white p-3 border w-[300px] rounded-2xl border-gray-200 absolute z-10 right-[10px] bottom-[10px]">
                    <div class="flex flex-col items-start gap-2 text-xs">
                      <div class="text-sm font-semibold mb-1">
                        {{ hovered_block_metrics.block_name }}: {{ hovered_block_metrics.block_completion_percentage }} %
                      </div>
                      <div class="flex items-center gap-3">
                        <div class="text-gray-500 w-28">
                          Status:
                        </div>
                        <HawkBadge :color="status_color_map[hovered_block_metrics.status]">
                          {{ hovered_block_metrics.status }}
                        </HawkBadge>
                      </div>
                      <div class="flex items-center gap-3">
                        <div class="text-gray-500 w-28">
                          Current work rate:
                        </div>
                        <div class="text-gray-900">
                          {{ hovered_block_metrics.current_work_rate }} {{ hovered_block_metrics.units }}/day
                        </div>
                      </div>
                      <div class="flex items-center gap-3">
                        <div class="text-gray-500 w-28">
                          Est. Finish date:
                        </div>
                        <div class="text-gray-900">
                          {{ hovered_block_metrics.est_finish_date }}
                        </div>
                      </div>
                      <div class="flex items-center gap-3">
                        <div class="text-gray-500 w-28">
                          Planned Finish date:
                        </div>
                        <div class="text-gray-900">
                          {{ hovered_block_metrics.planned_finish_date }}
                        </div>
                      </div>
                      <div v-if="hovered_block_metrics.required_work_rate" class="flex items-start gap-3 p-2 rounded-lg border">
                        <div class="w-5 h-5">
                          <IconHawkInfoCircle />
                        </div>
                        <div class="text-xs">
                          Maintain a work rate of <span class="text-gray-900 font-semibold">{{ hovered_block_metrics.required_work_rate }} units/day</span> to finish on schedule.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="state.selected_activities_workflow" class="grid gap-6">
          <TerraChartsHeatMap
            :data="{
              workflow_metrics,
              field_project_metrics: state.field_project_metrics,
              selected_project_for_range_metrics: state.selected_project_for_range_metrics,
              selected_range_metrics_field: state.selected_range_metrics_field,
              selected_workflow_data,
              selected_workflow: state.selected_activities_workflow,
              re_render_heatmap: state.re_render_heatmap,
              unique_projects,
            }"
            @select-project="state.selected_project_for_range_metrics = $event"
            @select-field="state.selected_range_metrics_field = $event"
            @track-view-event="terra_store.terra_track_events('Charts heatmap view changed', { view: $event })"
          />
        </div>

        <div class="bg-white p-5 border rounded-2xl border-gray-200">
          <div class="flex items-center justify-between mb-4 flex-wrap gap-2">
            <div>
              <div class="text-base font-semibold mb-1">
                {{ state.selected_activities_workflow }} completion
              </div>
              <div class="text-xs text-gray-700">
                Recorded in last {{ state.form.range_date }} days
              </div>
            </div>
            <div class="w-[200px]">
              <Vueform size="sm">
                <SelectElement
                  name="range_date"
                  :native="false"
                  :can-deselect="false"
                  :default="90"
                  :can-clear="false"
                  :loading="state.pr_range_data_loading"
                  :items="range_dates"
                  @select="selectRange"
                />
              </Vueform>
            </div>
          </div>
          <HawkHorizontalCardSlider :items="unique_fields">
            <template #content="{ content }">
              <div v-if="content.name !== 'Overall progress'" class="w-56  min-h-[86px] bg-white border rounded-2xl border-gray-200 p-3 grid gap-3 hover:bg-gray-50 cursor-pointer" :class="{ 'border-primary-700': state.selected_range_metrics_field === content.name }" @click="state.selected_range_metrics_field = content.name">
                <HawkText class="text-sm font-medium" :content="content.name" :length="22" />
                <div class="flex gap-2 items-center">
                  <div class="font-semibold text-lg">
                    {{ content.range_metric }}
                  </div>
                  <div v-if="!isNil(content.range_badge_value) && content.range_badge_value > 0">
                    <HawkBadge :is_rounded="false" :color="content.range_badge_color">
                      <span v-if="content.range_badge_color === 'red'">-</span>
                      <span v-else>+</span>
                      <span v-tippy="content.range_badge_tooltip">{{ round(content.range_badge_value) }} %</span>
                    </HawkBadge>
                  </div>
                </div>
              </div>
            </template>
          </HawkHorizontalCardSlider>
        </div>
        <div v-if="state.selected_range_metrics_field" class="bg-white p-5 border rounded-2xl mb-4 border-gray-200">
          <RangeDataTable
            :key="state.form.range_date"
            :last_range_date="state.form.range_date"
            :range_data_hash_by_field="range_data_hash_by_field"
            :field_project_metrics="state.field_project_metrics"
            :range_project_field_hash_metrics="state.range_project_field_hash_metrics"
            :status_color_map="status_color_map"
            :selected_workflow="state.selected_activities_workflow"
            :selected_project_for_range_metrics="state.selected_project_for_range_metrics"
            :selected_range_metrics_field="state.selected_range_metrics_field"
            :selected_workflow_data="selected_workflow_data"
            :data="{ unique_projects, filtered_projects, filtered_projects_state: state.filtered_projects }"
            :terra_store_name="terra_store_name"
            @selected-range-project="state.selected_project_for_range_metrics = $event"
          />
        </div>
      </div>
    </div>
    <div v-if="state.is_schedule_tracker_open">
      <ScheduleTracker :progress_data="state.progress_data" @tracker-updated="getData(true)" @close="state.is_schedule_tracker_open = false" />
    </div>
  </div>
</template>

<style scoped>
.snap-type {
  overscroll-behavior-inline: contain;
  scroll-snap-type: inline mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.snap-type > * {
  scroll-snap-align: start;
}

.snap-type::-webkit-scrollbar {
  display: none;
}
</style>
