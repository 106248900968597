<script setup>
import { storeToRefs } from 'pinia';
import { useModal } from 'vue-final-modal';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import SmNoResultsIllustration from '~/system-model/atoms/sm-no-results-illustration.vue';
import SmAddInventoryItemForm from '~/system-model/components/sm-inventory-items/sm-add-inventory-item-form.vue';
import { useSystemModelStore } from '~/system-model/store/system-model.store';

const { $t } = useCommonImports();

const system_model_store = useSystemModelStore();
const { can_modify_resources, active_instance, active_instance_details, active_component_details } = storeToRefs(system_model_store);

const state = reactive({
  items_details: null,
});

const measurement_units = computed(() => state.items_details?.measurement_units || []);

const inventory_items = computed(() => {
  const materials = active_instance.value ? active_instance_details.value?.materials || [] : active_component_details.value?.materials || [];
  return materials.map((item) => {
    const inventory_item = state.items_details?.items?.find(i => i.uid === item.uid);
    item.uom = get_uom_name(inventory_item?.uom);
    item.category = getCategoryName(inventory_item?.category);
    return item;
  });
});

async function getData(items) {
  state.items_details = await system_model_store.set_inventory_items_list({
    body: {
      filters: {
        item_uids: items,
      },
    },
    query: {
      'include[]': ['uom.*', 'category.*'],
    },
  });
}

onMounted(async () => {
  if (inventory_items.value.length) {
    await getData(inventory_items.value.map(item => item.uid));
  }
});

const add_item_modal = useModal({
  component: SmAddInventoryItemForm,
  attrs: {
    onClose() {
      add_item_modal.close();
    },
    onFetchItems: (items) => {
      getData(items);
    },
  },
});

function get_uom_name(uom) {
  const item = measurement_units.value.find(unit => unit.uid === uom);
  if (item)
    return `${item?.symbol || ''}`;
  return '';
}

function getCategoryName(uid) {
  const category_obj = state.items_details?.categories?.find(category => category.uid === uid);
  return category_obj?.name || null;
}
</script>

<template>
  <div>
    <HawkPageSecondaryHeader class="my-6">
      <template #left>
        <div class="text-gray-900 font-semibold text-lg">
          {{ $t('Items') }}
        </div>
      </template>
      <template #right>
        <template v-if="can_modify_resources">
          <hawkButton v-if="inventory_items.length" color="primary" type="outlined" class="font-semibold" @click="add_item_modal.open()">
            {{ $t('Update items') }}
          </hawkButton>
          <hawkButton v-else type="outlined" class="font-semibold" @click="add_item_modal.open()">
            <IconHawkPlusGray class="h-3 w-3" /> {{ $t('Add items') }}
          </hawkButton>
        </template>
      </template>
    </HawkPageSecondaryHeader>
    <SmNoResultsIllustration v-if="!inventory_items.length" :title="$t('No #Items found')" />
    <HawkInfiniteList v-else :list="inventory_items" container_class="max-h-96 mb-2 px-2">
      <template #item="{ item, index }">
        <div class="flex py-2 px-2 cursor-pointer group hover:bg-gray-50">
          <div class="flex-auto">
            <div class="flex justify-between mb-2">
              <HawkText
                class="text-sm font-medium text-gray-900"
                :length="50"
                :content="item.name"
              />
              <div class="flex gap-2 flex-shrink-0 items-center">
                <div v-if="item?.quantity" class="text-xs font-medium">
                  {{ `${item.quantity} ${item.uom}` }}
                </div>
              </div>
            </div>
            <div v-if="item?.category" class="flex items-center gap-1">
              <HawkBadge>
                {{ item?.category }}
              </HawkBadge>
            </div>
            <div v-else>
              -
            </div>
          </div>
        </div>
        <hr v-if="index !== inventory_items - 1" class="my-2">
      </template>
    </HawkInfiniteList>
  </div>
</template>
