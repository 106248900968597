<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { compact } from 'lodash-es';

const props = defineProps({
  schema_columns: {
    type: Array,
    default: () => [],
  },
  csv_columns: {
    type: Array,
    default: () => [],
  },
  data: {
    type: Array,
    default: () => [],
  },
  mapping: {
    type: Object,
    default: () => {},
  },
  automap_columns: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['close', 'mapping']);

const form$ = ref(null);
const form_data = ref({});

const state = reactive({
  preview_col: null,
  preview_data: [],
  preview_data_count: 0,
});

const required_columns = props.schema_columns.filter(column => column.required).map(column => column.field);
const required_columns_mapped = computed(() => {
  const mapped_columns = props.csv_columns.map(col => form$?.value?.data[col]);
  return required_columns.every((col) => {
    return mapped_columns.includes(col);
  });
});

const available_columns = computed(() => {
  const disabled_columns = compact(Object.values(form_data.value));
  return props.schema_columns.map(col => ({ ...col, disabled: disabled_columns.includes(col.field) }));
});

onMounted(() => {
  props.automap_columns && autoMatchColumns();
  if (compact(Object.values(props.mapping)).length)
    form_data.value = props.mapping;
});

function clearMatching() {
  form$.value.reset();
}

async function autoMatchColumns() {
  const mapping = {};
  props.csv_columns.forEach((header) => {
    let best_score = 0;
    props.schema_columns.forEach((schema_col) => {
      const score = getSimilarityScore(header, schema_col.label);
      if (score > best_score && !Object.values(mapping).includes(schema_col.field)) {
        best_score = score;
        mapping[header] = schema_col.field;
      }
    });
  });
  form_data.value = mapping;
}

function autoMatch(csv_col) {
  let best_score = 0;
  let matched_col = null;
  props.schema_columns.forEach((schema_col) => {
    const score = getSimilarityScore(csv_col, schema_col.label);
    if (score > best_score && !Object.values(form$.value.data).includes(schema_col.field)) {
      best_score = score;
      matched_col = schema_col.field;
    }
  });
  form$.value.elements$[csv_col].load(matched_col);
}

function getSimilarityScore(str1, str2, gramSize = 2) {
  function getNGrams(s, len) {
    s = ' '.repeat(len - 1) + s.toLowerCase() + ' '.repeat(len - 1);
    const v = Array.from({ length: s.length - len + 1 });
    for (let i = 0; i < v.length; i++)
      v[i] = s.slice(i, i + len);

    return v;
  }

  if (!str1?.length || !str2?.length)
    return 0.0;

  const s1 = str1.length < str2.length ? str1 : str2;
  const s2 = str1.length < str2.length ? str2 : str1;

  const pairs1 = getNGrams(s1, gramSize);
  const pairs2 = getNGrams(s2, gramSize);
  const set = new Set(pairs1);

  const total = pairs2.length;
  let hits = 0;
  for (const item of pairs2) {
    if (set.delete(item))
      hits++;
  }

  return hits / total;
}

function setPreviewData({ csv_column, col_index }, remove = false) {
  if (remove) {
    state.preview_col = null;
    state.preview_data = [];
    state.preview_data_count = 0;
    return;
  }
  state.preview_col = csv_column;
  const preview_data = props.data.map(row => row[col_index]).filter(data => data !== '');
  state.preview_data_count = preview_data.length;
  state.preview_data = preview_data.slice(0, preview_data.length < 5 ? preview_data.length : 5);
}

const mapping_data = computed(() => {
  const form_data = form$.value.data;
  Object.keys(form_data).forEach((key) => {
    if (!form_data[key])
      delete form_data[key];
  });
  return form_data;
});

function trimAsterisk(s) {
  if (s[s.length - 1] === '*')
    return s.substring(0, s.length - 1);
  return s;
}

async function handleSave() {
  emit('mapping', mapping_data.value);
}
</script>

<template>
  <hawk-modal-container content_class="!w-[1000px]">
    <Vueform
      ref="form$"
      v-model="form_data"
      sync
      size="sm"
      :columns="{
        default: { container: 12, label: 5, wrapper: 12 },
        sm: { container: 12, label: 5, wrapper: 12 },
        md: { container: 12, label: 5, wrapper: 12 },
      }"
      class="grid grid-rows-1 grid-flow-col gap-4"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close', required_columns_mapped, mapping_data)">
          <template #title>
            <div class="flex items-center">
              <div>
                <p>{{ $t('Column mapper') }}</p>
              </div>
              <IconHawkShuffleOne v-tippy="$t('Automatch columns')" class="text-primary-500 cursor-pointer ml-4" @click="autoMatchColumns" />
            </div>
          </template>
        </hawk-modal-header>
        <!-- Body -->
        <hawk-modal-content :is_scroll="false" class="!p-0 !px-6">
          <div class="grid grid-cols-12 pt-6">
            <div class="col-span-7 scrollbar max-h-[350px] pr-3">
              <div
                v-for="(csv_column, col_index) in csv_columns"
                :key="col_index"
                class="p-2 hover:bg-gray-100 rounded-md grid grid-cols-12"
                @mouseover="setPreviewData({ csv_column, col_index })"
                @mouseleave="setPreviewData({ csv_column, col_index }, true)"
              >
                <div class="col-span-11">
                  <SelectElement
                    :name="csv_column"
                    :items="available_columns"
                    :label="trimAsterisk(csv_column)"
                    :native="false"
                    label-prop="label"
                    value-prop="field"
                  >
                    <template #option="{ option }">
                      {{ option.label }} {{ option.required ? `(${$t('required')})` : '' }}
                    </template>
                  </SelectElement>
                </div>
                <div class="col-span-1 flex items-center justify-center">
                  <IconHawkShuffleOne v-show="!form$?.data?.[csv_column]" class="text-gray-400 cursor-pointer" @click="autoMatch(csv_column)" />
                </div>
              </div>
            </div>
            <div class="col-span-5 bg-gray-25 rounded-md border border-gray-300 flex items-center justify-center">
              <div v-if="state.preview_col" class="border border-gray-300 rounded-md bg-white w-[350px]">
                <p class="text-center text-sm font-medium p-1 bg-gray-100 text-gray-600">
                  {{ $t('Preview for') }} {{ state.preview_col }}, {{ $t('Total') }} {{ state.preview_data_count }} {{ $t('entries') }}
                </p>
                <div
                  v-for="(col_data, index) in state.preview_data" :key="`${col_data}-${index}`"
                  class="p-2 text-center border-t border-gray-300 text-xs text-gray-700 font-medium min-h-[35px] truncate"
                >
                  {{ col_data }}
                </div>
              </div>
              <div v-else class="text-center">
                <p class="text-md font-semibold">
                  {{ $t('Data Preview') }}
                </p>
                <p class="text-xs">
                  {{ $t('Hover over a line on left for more details') }}
                </p>
              </div>
            </div>
          </div>
          <hawk-alert-banner :color="required_columns_mapped ? 'success' : 'warning'" class="text-sm !p-2 font-semibold my-4">
            <template #icon>
              <IconHawkInfoCircle />
            </template>
            <template #content>
              <p v-if="required_columns_mapped">
                {{ $t('All required columns are mapped') }}
              </p>
              <p v-else>
                {{ $t('Map all the required columns') }}
              </p>
            </template>
          </hawk-alert-banner>
        </hawk-modal-content>
        <hawk-modal-footer class="flex justify-between items-center">
          <template #left>
            <hawk-button type="text" @click="clearMatching">
              {{ $t('Clear matching') }}
            </hawk-button>
          </template>
          <template #right>
            <div class="flex justify-end items-center">
              <hawk-button class="mr-5" type="outlined" @click="emit('close', required_columns_mapped, mapping_data)">
                {{ $t('Cancel') }}
              </hawk-button>
              <hawk-button type="default" :disabled="!required_columns_mapped" @click="handleSave">
                {{ $t('Save') }}
              </hawk-button>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
