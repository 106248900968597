<script setup>
import HawkTable from '~/common/components/organisms/hawk-table/hawk-table.vue';

const props = defineProps({
  data: {
    type: Object,
    default: () => {},
  },
  columns: {
    type: Array,
    default: () => [],
  },
});
const $t = inject('$t');

const columns_list = computed(() => ([
  { header: $t('Date'), id: 'date', accessorKey: 'date' },
  ...props.columns,
  { header: $t('Total'), id: 'Total', accessorKey: 'Total' },
]));
</script>

<template>
  <div class="py-3">
    <div class="text-lg font-semibold py-3 flex items-center gap-x-3">
      {{ $t('Scan history') }} <IconHawkInfoCircle v-tippy="$t('Shows daily count of modules scanned. Only scan additions are considered')" class="w-4 h-4" />
    </div>
    <div class="max-h-[369px] w-full overflow-auto scrollbar border rounded-lg scan-history-table">
      <HawkTable
        is_gapless
        :data="data"
        :columns="columns_list"
        :show_menu_header="false"
        :disable_resize="true"
        show_column_borders
      >
        <template #date="{ data: { row } }">
          <div v-if="row.original.date !== 'Total'">
            {{ $date(row.original.date, 'DD MMM YYYY') }}
          </div>
        </template>
      </HawkTable>
    </div>
  </div>
</template>

<style lang="scss">
.scan-history-table {
  thead tr th:first-child,
  tbody td:first-child {
    position: sticky !important;
    left: 0;
    z-index: 1;
  }
  thead tr th:last-child,
  tbody td:last-child {
    @apply border-l bg-gray-50;
    position: sticky !important;
    right: 0;
    z-index: 1;
  }

  tbody td:last-child {
    @apply font-medium;
  }

  tbody tr:last-child {
    @apply border-t;
    position: sticky;
    bottom: 0;
    z-index: 1;
    td {
      @apply bg-gray-50 font-medium;
    }
  }
}
</style>
