<script setup>
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { computed, inject, ref } from 'vue';
import SmActivities from '~/system-model/components/sm-activities.vue';
import SmFieldList from '~/system-model/components/sm-field-list.vue';
import SmInstanceHierarchyWidget from '~/system-model/components/sm-terra/sm-instance-hierarchy-widget.vue';
import FeatureActivities from '~/terra/components/feature-details/feature-activities.vue';
import FeatureDetailsMenu from '~/terra/components/feature-details/feature-details-menu.vue';
import VolumeHistory from '~/terra/components/feature-details/feature-volume-history.vue';
import ProgressHistory from '~/terra/components/feature-details/progress-history.vue';
import { useTerraStore } from '~/terra/store/terra.store.js';
import { useTerraHelperComposable } from '~/terra/utils/helper-composable.js';

const props = defineProps({
  feature: {
    type: Object,
    required: true,
  },
  // eslint-disable-next-line vue/prop-name-casing
  hawk_menu_items: {
    type: Array,
  },
});

const $t = inject('$t');
const terra_store = useTerraStore();

const { getStyles } = useTerraHelperComposable();
const show_detailed_view = ref(false);
const active_tab = ref('details');

const selected_feature_workflow = computed(() => {
  return terra_store.terra_workflows?.[
    terra_store.selected_features[0]?.properties.workflow
  ]?.data;
});
const feature_type = computed(() => {
  return terra_store.feature_types[
    props.feature?.properties?.featureTypeId
  ] || {};
});
const extraProperties = computed(() => {
  const { extraProperties } = terra_store.selected_features[0].properties;
  if (!extraProperties)
    return [];

  return Object.keys(extraProperties).map((key) => {
    return {
      name: key,
      value: extraProperties[key],
    };
  });
});

const tabs = computed(() => ([
  {
    uid: 'details',
    label: $t('Details'),
    on_click: () => {
      active_tab.value = 'details';
    },
    disabled: false,
  },
  {
    uid: 'hierarchy',
    label: $t('Hierarchy'),
    on_click: () => {
      active_tab.value = 'hierarchy';
    },
    disabled: false,
  },
  // ...((split_store.split_show_progress_history && selected_feature_workflow.value)
  ...((selected_feature_workflow.value)
    ? [{
        uid: 'progress-history',
        label: $t('Charts'),
        on_click: () => {
          active_tab.value = 'progress-history';
        },
        disabled: false,
      }]
    : []
  ),
]
));
function updateTargetElement(feature) {
  terra_store.selected_features = [
    feature,
  ];
  terra_store.create_or_update_selected_features({
    clearSelectedFeatures: false,
    updateFeatureRequest: false,
  });
}

function getFields(instance) {
  return [...(instance?.component?.category?.fields || []), ...(instance?.component?.fields || [])];
}

function getFieldValues(instance) {
  return [...(instance?.component?.fieldvalues || []), ...(instance?.fieldvalues || [])];
}
async function openDetailedView() {
  if (!terra_store.selected_features.length)
    terra_store.selected_features = [terra_store.features_hash[props.feature.properties.uid]];
  show_detailed_view.value = true;
}
</script>

<template>
  <HawkButton type="outlined" class="!h-auto" block @click="openDetailedView">
    {{ $t('View details') }}
  </HawkButton>
  <TransitionRoot as="template" :show="show_detailed_view">
    <Dialog as="div" class="relative z-[1000]" @close="show_detailed_view = false">
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div
            class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full"
          >
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel
                class="pointer-events-auto relative w-screen lg:max-w-3xl md:max-w-2xl sm:max-w-full"
              >
                <div
                  class="flex h-full flex-col bg-white shadow-xl h-screen scrollbar"
                >
                  <SmInstanceWidget
                    :key="feature?.properties?.name"
                    :feature="feature"
                  >
                    <template #widget_content="{ instance_detail, loading, location }">
                      <HawkLoader v-if="loading" class="h-[100vh]" />
                      <template v-else>
                        <template v-if="instance_detail?.projectUid">
                          <div class="top-0 sticky bg-white z-[11]">
                            <div class="p-6 sm:p-6">
                              <div class="flex justify-between">
                                <div class="text-gray-900 text-xl font-semibold">
                                  {{ feature.properties?.name || "Untitled" }}
                                </div>
                                <HawkButton type="text" icon class="pointer-events-auto" @click="show_detailed_view = false">
                                  <span class="sr-only">Close panel</span>
                                  <IconHawkXClose class="text-gray-500" />
                                </HawkButton>
                              </div>
                              <div v-if="instance_detail?.component" class="text-sm text-gray-600 mt-1 flex items-center">
                                <div class="flex items-center">
                                  <div
                                    v-if="feature_type?.name"
                                    class="cursor-pointer bg-gray-200 rounded-2xl px-2 py-0.5 text-[12px] font-medium"
                                    :class="{ 'opacity-60': !feature_type.name }"
                                    :style="feature_type ? getStyles(feature_type, 'tag') : {}"
                                  >
                                    <div class="font-medium text-[13px]">
                                      <span class="flex items-center"> {{ feature_type.name }} </span>
                                    </div>
                                  </div> ,
                                </div>
                                <div class="ml-1">
                                  {{ instance_detail?.component?.name }}
                                </div>
                              </div>
                              <div class="mt-6 flex justify-between">
                                <HawkPageHeaderTabs class="w-full mr-2" :tabs="tabs" :active_item="active_tab" />
                                <FeatureDetailsMenu v-if="hawk_menu_items.length" :hawk_menu_items="hawk_menu_items" @open-creation-popup="$emit('openCreationPopup', $event)" />
                              </div>
                            </div>
                          </div>
                          <div>
                            <div class="px-6 pb-6">
                              <div v-if="active_tab === 'details'">
                                <div>
                                  <div class="text-lg font-semibold">
                                    {{ $t('Properties') }}
                                  </div>
                                  <SmFieldList
                                    has_quick_filter
                                    :extra_properties="extraProperties"
                                    :properties="getFieldValues(instance_detail)"
                                    :fields="getFields(instance_detail)"
                                  />
                                </div>
                                <VolumeHistory :selected_feature="feature" />
                                <SmActivities
                                  :feature="feature"
                                  :active_item_detail="instance_detail"
                                  :location="location"
                                  @update-feature="updateTargetElement"
                                />
                                <FeatureActivities />
                              </div>
                              <SmInstanceHierarchyWidget
                                v-if="active_tab === 'hierarchy'"
                                :instance_uid="instance_detail?.uid"
                              />
                              <ProgressHistory
                                v-if="active_tab === 'progress-history'"
                              />
                            </div>
                          </div>
                        </template>
                      </template>
                    </template>
                  </SmInstanceWidget>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
