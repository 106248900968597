<script setup>
import DOMPurify from 'dompurify';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const props = defineProps({
  on_submit: {
    type: Function,
    default: () => {
      return true;
    },
  },
  section: {
    type: Object,
    default: () => {},
  },
});
const emit = defineEmits(['close']);

const {
  $t,
  route,
  router,
} = useCommonImports();

const section = computed(() => props.section);
const active_fields = section.value.fields.filter(field => field.status === 'active');
function getColumnSummaryData() {
  const data = {};
  if (props.section?.properties?.section_summary?.data && props.section?.properties?.section_summary.summary_type === 'column_summary') {
    Object.keys(props.section.properties.section_summary?.data).forEach((key) => {
      data[key] = props.section.properties.section_summary?.data[key].footer;
    });
  }
  return data;
}
function get_fields_info() {
  const data = $t('Fields letter Assignment');
  let added_data = data;
  for (let i = 0; i < active_fields?.length; i++)
    added_data += `<div>${active_fields[i].name} - ${String.fromCharCode(65 + i)} </div>`;

  return DOMPurify.sanitize(added_data);
}
const load_data_map = props.section.properties?.section_summary || {};
const column_summary_data = getColumnSummaryData();
load_data_map.footer = column_summary_data;

function get_items(field) {
  const is_formula = !!field?.config?.formula;
  const is_number_field = is_formula || ['number', 'money'].includes(field?.properties?.type);
  return is_number_field
    ? [
        {
          value: 'None',
          label: $t('None'),
        },
        {
          value: 'Sum',
          label: $t('Sum'),
        },
        {
          value: 'AVERAGE',
          label: $t('Avg'),
        },
        {
          value: 'Max',
          label: $t('Max'),
        },
        {
          value: 'Min',
          label: $t('Min'),
        },
        {
          value: 'Count',
          label: $t('Count'),
        },
      ]
    : [{
        value: 'None',
        label: $t('None'),
      }, {
        value: 'Count',
        label: $t('Count'),
      }];
}

function preview() {
  router.push({ query: { form: btoa(JSON.stringify({ form_uid: route.params.template_uid, is_preview: true })) } });
}
</script>

<template>
  <hawk-modal-container @click.stop>
    <Vueform id="summary-popup" size="sm" :display-errors="false" :endpoint="(form_data, form) => props.on_submit(form, row)" @mounted="form$ => props.section.properties?.section_summary ? form$.load(load_data_map) : null" @keypress.enter.prevent>
      <div class="col-span-12 w-[600px]">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            <div>
              {{ props.section.name }} -
              {{ $t('Summary') }}
            </div>
          </template>
        </hawk-modal-header>
        <hawk-modal-content class="h-[27rem] overflow-y-scroll scrollbar">
          <div class="text-sm font-medium mb-4" @click="preview">
            {{ $t('Summary can be viewed in') }} <span class="cursor-pointer">'<icon-hawk-eye class="text-gray-900 inline" /> {{ $t('Preview') }}'</span> {{ $t('mode only') }}
          </div>
          <div class="max-w-[1100px] grid gap-4 ">
            <RadiogroupElement
              name="summary_type"
              view="blocks"
              default="column_summary"
              :add-classes="{
                RadiogroupRadio: {
                  wrapper: ['!rounded-lg'],
                  wrapper_first_sm: ['mb-2'],
                },
              }"
              :remove-classes="{
                RadiogroupRadio: {
                  wrapper_not_last: ['!border-b-0'],
                  wrapper_selected: ['!bg-primary-50', '!border-primary-500', 'form-bg-selected'],
                },
              }"
              :items="[
                {
                  value: 'column_summary',
                  label: $t('Column Summary'),
                  description: $t('Aggregate values column-wise'),
                },
                {
                  value: 'detailed_summary',
                  label: $t('Detailed summary'),
                  description: $t('Aggregate values across columns using formula'),
                },
              ]"
            />
            <StaticElement
              name="summary"
              :content="`<b>${$t('Summary')}</b>`"
              :conditions="[
                [
                  'summary_type',
                  'in',
                  [
                    'column_summary',
                  ],
                ],
              ]"
            />
            <StaticElement
              name="summary_1"
              :label="`${$t('Summary')}`"
              :info="{ content: { content: get_fields_info(), allowHTML: true } }"
              :add-classes="{
                ElementLabel: {
                  wrapper: ['font-bold'],
                },
              }"
              :conditions="[
                [
                  'summary_type',
                  'in',
                  [
                    'detailed_summary',
                  ],
                ],
              ]"
            />
            <ObjectElement
              name="footer"
              :presets="['repeatable_list']"
              class="mb-4"
              :add-classes="{
                ObjectElement: {
                  wrapper: ['items-center', '!gap-6'],
                },
              }"
            >
              <SelectElement
                v-for="field in active_fields"
                :key="field.name"
                :name="field.uid"
                :items="get_items(field)"
                :columns="{
                  default: { container: 12, label: 4, wrapper: 12 },
                  sm: { container: 12, label: 12, wrapper: 12 },
                  md: { container: 12, label: 4, wrapper: 12 },
                }"
                :search="true"
                :native="false"
                input-type="search"
                autocomplete="off"
                :label="field.name"
                :conditions="[
                  [
                    'summary_type',
                    'in',
                    [
                      'column_summary',
                    ],
                  ],
                ]"
              />
            </ObjectElement>
            <div class="-mt-6 col-span-12">
              <ListElement
                name="data"
                :add-text="`+ ${$t('Add summary')}`"
                :columns="{
                  default: { container: 12, label: 12, wrapper: 12 },
                  sm: { container: 12, label: 12, wrapper: 12 },
                  md: { container: 12, label: 12, wrapper: 12 },
                }"
                :presets="['repeatable_list']"
                :initial="1"
                class="mb-4"
                :add-classes="{
                  ListElement: {
                    add: ['!py-2.25 hover:bg-gray-100 !align-middle'],
                    listItem: ['!mb-3'],
                  },
                }"
                :conditions="[
                  [
                    'summary_type',
                    'in',
                    [
                      'detailed_summary',
                    ],
                  ],
                ]"
              >
                <template #default="{ index }">
                  <ObjectElement
                    :name="index"
                    :columns="{
                      default: { container: 11, label: 12, wrapper: 12 },
                      sm: { container: 11, label: 12, wrapper: 12 },
                      md: { container: 11, label: 12, wrapper: 12 },
                    }"
                    :add-classes="{
                      ObjectElement: {
                        wrapper: ['items-center', '!gap-10'],
                      },
                    }"
                  >
                    <GroupElement
                      name="column_1"
                      :columns="{
                        default: { container: 6, label: 12, wrapper: 12 },
                        sm: { container: 6, label: 12, wrapper: 12 },
                        md: { container: 6, label: 12, wrapper: 12 },
                      }"
                    >
                      <TextElement
                        name="name"
                        :placeholder="$t('Enter Name')"
                      />
                    </GroupElement>

                    <GroupElement
                      name="column_2"
                      :columns="{
                        default: { container: 6, label: 12, wrapper: 12 },
                        sm: { container: 6, label: 12, wrapper: 12 },
                        md: { container: 6, label: 12, wrapper: 12 },
                      }"
                    >
                      <TextElement
                        name="formula"
                        :placeholder="$t('Enter Formula')"
                      />
                    </GroupElement>
                  </ObjectElement>
                </template>
              </ListElement>
            </div>
          </div>
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="col-span-12">
              <div class="flex justify-end w-full">
                <HawkButton type="outlined" text="Cancel" class="mr-4" @click="emit('close')">
                  {{ $t('Cancel') }}
                </HawkButton>
                <ButtonElement submits size="sm" name="submit" :button-label="$t('Save')" button-class="vf-btn-primary" />
              </div>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
