import AwsS3 from '@uppy/aws-s3';
import Uppy from '@uppy/core';
import { $toast, isFileExtensionAllowed } from '~/common/utils/common.utils';

export function createUppyInstance({
  attachment_config,
  fire_element,
  upload_cb,
  upload_progress_cb,
  upload_success_cb,
  upload_error_cb,
  complete_cb,
} = {}) {
  const uppy = new Uppy({
    onBeforeFileAdded: (current_file) => {
      const is_allowed = isFileExtensionAllowed(current_file?.name, true);
      current_file.data.has_unsupported_extension = !is_allowed;

      if (!is_allowed) {
        $toast({
          title: 'Unsupported file type',
          text: `Failed to add files because ${current_file?.name || 'at least one file'} has a disallowed extension. Please try again.`,
          type: 'error',
          timeout: 4000,
        });
      }

      return is_allowed;
    },
    autoProceed: true,
    meta: {
      stage: attachment_config?.meta?.service,
      resource: JSON.stringify({
        type: attachment_config?.meta?.type,
        uid: attachment_config?.meta?.id,
        name: attachment_config?.meta?.name,
      }),
    },
  });

  uppy.use(AwsS3, {
    shouldUseMultipart() {
      return true;
    },
    limit: 4,
    endpoint: import.meta.env.VITE_APP_STORAGE_COMPANION_URL,
  });

  uppy.on('upload', (data) => {
    if (fire_element) {
      fire_element.fire('updated', { type: 'upload_started' });
      fire_element.uploading.value = true;
    }
    upload_cb?.(data);
  });

  uppy.on('upload-progress', (file, progress) => {
    if (file.data.progress)
      file.data.progress.value = Number.parseInt((progress.bytesUploaded / progress.bytesTotal) * 100);
    upload_progress_cb?.(file, progress);
  });

  uppy.on('upload-success', (file, response) => {
    const location = decodeURIComponent(response.uploadURL);

    // eslint-disable-next-line regexp/no-useless-flag
    const pattern = /https:\/\/(.*)\.s3\.(.*)\.amazonaws.com\/(.*)/g;
    if (!response.uploadURL) {
      if (file.data.progress)
        file.data.progress.value = 100;
      return;
    }
    const [, bucket, region, key] = pattern.exec(location);
    const serviceObj = {
      stage: file.meta.stage,
      name: 'aws_s3',
      key,
      bucket,
      region,
      location,
    };
    if (file.data.progress)
      file.data.progress.value = 100;
    file.data.upload_url = response.uploadURL;
    file.data.service_object = serviceObj;
    file.data.fname = file.data.name;
    file.data.file_size = file.data.size;
    file.data.mime_type = file.data.type;
    upload_success_cb?.(file, response);
  });

  uppy.on('upload-error', (file, error, response) => {
    if (file?.data?.error)
      file.data.error.value = true;
    upload_error_cb?.(file, error, response);
  });

  uppy.on('complete', (result) => {
    if (fire_element) {
      fire_element.fire('updated', { type: 'upload_completed' });
      fire_element.uploading.value = false;
    }
    complete_cb?.(result);
  });

  return uppy;
}
