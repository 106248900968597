<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { useFocus } from '@vueuse/core';
import { orderBy } from 'lodash-es';
import { computed } from 'vue';
import { currencies } from '~/common/utils/constants.ts';

const props = defineProps({
  modelValue: {
    type: Number,
    default: 0,
  },
  currency_code: {
    type: String,
    default: 'USD',
  },
  search_placeholder: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  hide_dropdown: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue', 'unitChanged', 'inputChanged']);

const all_currencies = ref(Object.values(orderBy(currencies, 'name', 'asc')));

// only value
const currency_value = ref(0);

const currency_code = computed(() => props.currency_code);

// only unit
const currency_unit = ref({ symbol: '$', name: 'US Dollar', symbol_native: '$', decimal_digits: 2, rounding: 0, code: 'USD', name_plural: 'US dollars' });

// full
const currency_object = ref({
  value: currency_value.value,
  ...currency_unit.value,
});

const search = ref('');
const searchInput = ref();

const open = ref(false);

const { focused } = useFocus(searchInput, { initialValue: true });

const filter_currencies = computed(() => {
  // filter the list of currencies when the user search
  return all_currencies.value.filter(item =>
    item.name.toLowerCase().includes(search.value.toLowerCase()) || item.code.toLowerCase().includes(search.value.toLowerCase()),
  );
});

watch(currency_code, () => {
  currency_unit.value = currencies[props.currency_code];
  currency_object.value = { value: currency_value.value, ...currency_unit.value };
});

function emitInput() {
  currency_object.value = { value: currency_value.value, ...currency_unit.value };
  emit('update:modelValue', currency_value.value);
  emit('inputChanged', currency_object.value);
}

function onCurrencyUnitChanged(value) {
  currency_unit.value = value;
  currency_object.value = { value: currency_value.value, ...currency_unit.value };
  emit('update:modelValue', currency_value.value);
  emit('unitChanged', currency_unit.value);
}
const add_menu_class = props.hide_dropdown ? 'hidden' : '';
onMounted(() => {
  setTimeout(() => {
    currency_value.value = props.modelValue;
    currency_unit.value = currencies[props.currency_code];
    currency_object.value = {
      value: props.modelValue,
      ...currency_unit.value,
    };
  }, 100);
});
</script>

<template>
  <div class="flex items-center border border-gray-300 h-9 rounded-lg text-sm focus-within:ring-4 focus-within:ring-primary-100 focus-within:border-primary-600">
    <span class="ml-2">
      {{ currency_object?.value?.symbol || currency_unit.symbol }}
    </span>
    <input
      v-model="currency_value"
      type="number"
      class="h-full w-full text-sm px-2 custom-input bg-transparent"
      :placeholder="placeholder || $t('Enter the currency')"
      autocomplete="off"
      min="0"
      @input="emitInput"
    >
    <HawkMenu
      :class="add_menu_class" additional_trigger_classes="!ring-0" @open="search = ''; open = true;"
      @close="search = ''; open = false;"
    >
      <template #trigger>
        <div class="w-[4rem] h-8 rounded-r-lg hover:bg-gray-100 flex items-center justify-center">
          <span class="mr-1">{{ currency_object?.value?.code || currency_unit.code }}</span>
          <IconHawkChevronDown v-if="!open" class="h-4" />
          <IconHawkChevronUp v-else class="h-4" />
        </div>
      </template>
      <template #content="{ close }">
        <div class="w-60">
          <div class="mx-3">
            <input
              ref="searchInput"
              v-model="search"
              type="text"
              class="h-9 w-full placeholder-gray-500 text-sm font-light"
              :placeholder="search_placeholder || $t('Search currency')"
            >
          </div>
          <hr>
          <div class="m-1.5 h-52 scrollbar">
            <div
              v-for="currency in filter_currencies" :key="currency.name"
              class="py-2.5 px-2 hover:bg-gray-50 text-gray-700 rounded-lg flex items-center justify-between cursor-pointer"
              @click="onCurrencyUnitChanged(currency); close();"
            >
              {{ currency.name }} ({{ currency.code }})
              <IconHawkcheck
                v-if="currency_object?.value?.code === currency.code"
                class="text-primary-500"
              />
            </div>
          </div>
        </div>
      </template>
    </HawkMenu>
  </div>
</template>

<style lang="scss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
    -moz-appearance:textfield;
}
</style>
