<script>
import { h } from 'vue';
import HawkMembers from '~/common/components/organisms/hawk-members-badge/hawk-members.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

export default {
  props: {
    activity: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { $t } = useCommonImports();
    const get_member_render_fn = members => h(HawkMembers, { members, type: 'badge', max_badges_to_display: 1, class: 'ml-2' });

    function getReviewedDocumentText({ review, workflow_step }) {
      if (workflow_step?.name) {
        if (review.comment)
          return `${$t('marked the document  as')} ${review.status_label} ${$t('with a comment')} ${review.comment} ${$t('for')} ${workflow_step?.name}`;
        else
          return `${$t('marked the document  as')} ${review.status_label} ${$t('for')} ${workflow_step.name}`;
      }
      else
        if (review.comment) {
          return `${$t('marked the document  as')} ${review.status_label} ${$t('with a comment')} ${review.comment}`;
        }
        else {
          return `${$t('marked the document  as')} ${review.status_label}`;
        }
    }

    const TRANSMITTAL_ACTIVITY_VERB_MAP = {
      CREATED: ({ transmittal }) => `${$t('created a new transmittal')} ${transmittal?.number}`,
      UPDATED: ({ transmittal }) => `${$t('updated the transmittal')} ${transmittal?.number}`,
      CLOSED: ({ transmittal }) => `${$t('closed the transmittal')} ${transmittal?.number}`,
      DELETED: ({ transmittal }) => `${$t('deleted the transmittal')} ${transmittal?.number}`,
      TRANSMITTED: ({ transmittal }) => `${$t('transmitted')} ${transmittal?.number}`,
      PUBLISHED: ({ transmittal }) => `${$t('published the transmittal')} ${transmittal?.number}`,
      CANCELLED: ({ transmittal }) => `${$t('cancelled the transmittal')} ${transmittal?.number}`,
      MESSAGED: ({ transmittal }) => `${$t('sent a message for the transmittal')} ${transmittal?.number}`,
      TRANSMITTAL_STARTED: ({ transmittal }) => `${$t('transmitted the file in the transmittal')} ${transmittal?.number} ${transmittal?.name}`,
      TRANSMITTAL_CANCELLED: ({ transmittal }) => `${$t('cancelled the file in the transmittal')} ${transmittal?.number} ${transmittal?.name}`,
      TRANSMITTAL_COMPLETED: ({ transmittal }) => `${$t('completed the file in the transmittal')} ${transmittal?.number} ${transmittal?.name}`,
      TRANSMITTAL_SYNCED: ({ transmittal }) => `${$t('synced the file from the transmittal')} ${transmittal?.number} ${transmittal?.name}`,
      TRANSMITTAL_PAUSED: ({ transmittal }) => `${$t('paused the file from the transmittal')} ${transmittal?.number} ${transmittal?.name}`,
      TRANSMITTAL_RESUMED: ({ transmittal }) => `${$t('resumed the file from the transmittal')} ${transmittal?.number} ${transmittal?.name}`,
    };

    const TRANSMITTAL_DOCUMENT_ACTIVITY_VERB_MAP = {
      VIEWED: () => $t('viewed the document '),
      UPDATED: () => $t('updated the document '),
      TRANSMITTAL_PUBLISHED: () => $t('published the document '),
      SUBMITTED: ({ submission }) => submission?.comment ? `${$t('submitted the document  with comment')} ${submission?.comment}` : $t('submitted the document '),
      REVIEWED: getReviewedDocumentText,
      UNCANCELLED: ({ cancellation }) => `${$t('un-cancelled the document  with a message')} ${cancellation?.message}`,
      CANCELLED: ({ cancellation }) => `${$t('cancelled the document  with a message')} ${cancellation?.message}`,
      SYNCED: ({ synced }) => `${$t('synced the document with a message')} ${synced?.message}`,
      DELEGATED: ({ delegation }) => [`${$t('delegated the document for')} ${delegation?.for} ${$t('to')} `, get_member_render_fn(delegation?.user)],
      REVIEWERS_ADDED: ({ additional_reviews }) => [$t('requested review for the document  from '), get_member_render_fn(additional_reviews?.consolidator), get_member_render_fn(additional_reviews?.reviewers)],
      REVIEWERS_REMOVED: ({ additional_reviews }) => [$t('revoked the request to review from '), get_member_render_fn(additional_reviews?.consolidator), get_member_render_fn(additional_reviews?.reviewers)],
      REVIEW_UNLOCKED: ({ unlocked, workflow_step }) => workflow_step?.name ? [$t('unlocked the '), get_member_render_fn(unlocked?.for_user), `${$t('review with a message')} ${unlocked?.message} ${$t('for')} ${workflow_step?.name}`] : ['unlocked the ', get_member_render_fn(unlocked?.for_user), ` ${$t('review with a message')} ${unlocked?.message}`],
      REVIEW_REOPENED: ({ reopened, workflow_step }) => workflow_step?.name ? [$t('reopened the '), get_member_render_fn(reopened?.for_user), ` ${$t('review with a message')} ${reopened?.message} ${$t('for')} ${workflow_step?.name}`] : ['reopened the ', get_member_render_fn(reopened?.for_user), ` ${$t('review with a message')} ${reopened?.message}`],
      WORKFLOW_OUTCOME: ({ outcome }) => `${$t('The final outcome for the document is')} ${outcome}`,
      WORKFLOW_STEP_OUTCOME: ({ outcome, workflow_step }) => `${$t('The')} ${workflow_step?.name} ${$t('for the document is')} ${outcome}`,
      SUB_WORKFLOW_STARTED: () => $t('started a sub-workflow for the document '),
      SUB_WORKFLOW_COMPLETED: () => $t('completed a sub-workflow for the document '),
      SUB_WORKFLOW_CANCELLED: () => $t('cancelled the sub-workflow'),
    };

    function getDescriptionText() {
      if (props.activity.resource_name === 'TRANSMITTAL' || props.activity.resource_name === 'FILE')
        return TRANSMITTAL_ACTIVITY_VERB_MAP[props?.activity?.verb] ? TRANSMITTAL_ACTIVITY_VERB_MAP[props?.activity?.verb](props?.activity?.meta) : '';
      else
        return TRANSMITTAL_DOCUMENT_ACTIVITY_VERB_MAP[props?.activity?.verb] ? TRANSMITTAL_DOCUMENT_ACTIVITY_VERB_MAP[props?.activity?.verb](props?.activity?.meta) : '';
    }
    const text = getDescriptionText();
    // return the render function
    return () => h('div', { class: 'flex items-center' }, text);
  },
};
</script>
