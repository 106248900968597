<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { useClipboard } from '@vueuse/core';
import { cloneDeep, groupBy, orderBy } from 'lodash-es';
import { computed, ref } from 'vue';
import { useModal } from 'vue-final-modal';

import useEmitter from '~/common/composables/useEmitter';
import { parseStringifiedObjectsInArray } from '~/common/utils/common.utils';
import EditFeaturePropertiesPopup from '~/terra/components/feature-details/edit-feature-properties-popup.vue';

import FeatureDetailsMenu from '~/terra/components/feature-details/feature-details-menu.vue';
import featureDetailsSlider from '~/terra/components/feature-details/feature-details-slider.vue';
import FeatureExtraProperties from '~/terra/components/feature-details/feature-extra-properties.vue';
import FeatureTypes from '~/terra/components/feature-type-groups/feature-types.vue';
import ProgressWorkflow from '~/terra/components/workflow/progress-workflow.vue';
import { useTerraStore } from '~/terra/store/terra.store.js';
import { useTerraHelperComposable } from '~/terra/utils/helper-composable.js';

defineProps({
  show_view_details_only: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['openCreationPopup', 'filterGlDrawConvertedFeatures', 'isTableActive']);
const terra_store = useTerraStore();
const $t = inject('$t');
const $toast = inject('$toast');

const emitter = useEmitter();
const { getStyles } = useTerraHelperComposable();

const is_loading = ref(false);
const edit_mode = ref([]);
const is_dirty = ref(false);
const feature = ref({});
const is_class_updated = ref(false);

const show_detailed_view = ref(false);
const count_hovered_feature_id = ref(0);
const is_dropdown_open = ref(false);

emitter.on('get-instance-detail', async (data) => {
  const new_feature = terra_store?.all_features?.find(
    feature => feature.properties?.name === data,
  );
  if (new_feature) {
    const turf = (await import('@turf/turf'));
    const centroid = turf.centroid(new_feature);
    terra_store.selected_features = [new_feature];
    terra_store.map.flyTo({
      center: centroid.geometry.coordinates,
    });
  }
  else {
    $toast({
      title: 'Feature not found',
      text: 'The selected component is not available on the visible features. Turn on other layers to navigate.',
      type: 'error',
    });
  }
});
onBeforeUnmount(() => {
  emitter.off('get-instance-detail');
});
function glDrawKeyCombination(e) {
  if (terra_store.check_permission('modify_features') && e.shiftKey && e.key === 'E')
    changeToGlDraw();
}
onMounted(async () => {
  document.addEventListener('keydown', glDrawKeyCombination);
});
onUnmounted(() => {
  document.removeEventListener('keydown', glDrawKeyCombination);
});

const tasks_forms = computed(() => {
  return Object.values(terra_store.selected_tasks_forms).reduce((acc, cur) => {
    if (acc[cur.properties.feature_type]) {
      acc[cur.properties.feature_type].data.push(JSON.parse(cur.properties.data));
      acc[cur.properties.feature_type].count = acc[cur.properties.feature_type].count + 1;
    }
    else {
      acc[cur.properties.feature_type] = {
        data: [JSON.parse(cur.properties.data)],
        count: 1,
      };
    }
    return acc;
  }, {});
});

const selected_features = computed(() => {
  return parseStringifiedObjectsInArray(terra_store.selected_features);
});

const grouped_features = computed(() => {
  return groupBy(selected_features.value, feature => feature.properties.featureTypeId);
});

function getRemainingGroupedFeatures(grouped_features) {
  const transformed_features = grouped_features.map((feature) => {
    return {
      label: `${feature.properties?.name}`,
      uid: feature.properties.uid,
    };
  });
  return transformed_features;
}

const selected_feature = computed(() => {
  return selected_features.value[0];
});
const is_bulk_select = computed(() => {
  return selected_features.value.length > 1;
});
const multiple_feature_workflow = computed(() => {
  return terra_store.selected_features.every(
    feature =>
      feature.properties.featureTypeId
      === terra_store.selected_features[0].properties.featureTypeId,
  );
});
const same_workflows = computed(() => {
  return terra_store.selected_features.every(
    feature =>
      feature.properties.workflow
      === terra_store.selected_features[0].properties.workflow,
  );
});
const selected_feature_workflow = computed(() => {
  return terra_store.terra_workflows?.[
    terra_store.selected_features[0]?.properties.workflow
  ]?.data[terra_store.selected_features[0].properties.featureType];
});
const is_update_progress_enabled = computed(() => {
  if (is_bulk_select.value) {
    return (
      multiple_feature_workflow.value
      && selected_feature_workflow.value
      && same_workflows.value
    );
  }
  return selected_feature_workflow.value;
});
const message_for_disabled_workflow_option = computed(() => {
  let message;
  if (!multiple_feature_workflow.value) {
    message = $t(
      'Can not update progress for the features of different types',
    );
  }
  else if (!selected_feature_workflow.value) {
    message = $t(
      'No workflow available for the selected features',
    );
  }
  else if (!same_workflows.value) {
    message = $t(
      'Features do not belong to the same workflow',
    );
  }
  else {
    message = '';
  }
  return message;
});
const feature_type = computed(() => {
  return terra_store.feature_types[
    feature.value?.properties?.featureTypeId
  ] || {};
});

const extra_properties = computed(() => {
  const data = Object.keys(selected_feature.value?.properties?.extraProperties || {});
  if (data.length) {
    const extraProperties = data.map(key => ({
      name: key,
      value: selected_feature.value?.properties?.extraProperties[key],
    })) || [];

    return orderBy(extraProperties || [], 'name');
  }
  return [];
});

const edit_properties_popup = useModal({
  component: EditFeaturePropertiesPopup,
});
const progress_workflow_popup = useModal({
  component: ProgressWorkflow,
  attrs: {
    onClose() {
      progress_workflow_popup.close();
    },
  },
});

function unSelectTaskOrForm(key) {
  terra_store.selected_tasks_forms = Object.keys(terra_store.selected_tasks_forms).reduce((obj, uid) => {
    if (terra_store.selected_tasks_forms[uid].properties.feature_type !== key)
      obj[uid] = terra_store.selected_tasks_forms[uid];
    return obj;
  }, {});
}

const hawk_menu_items = computed(() => {
  return [
    {
      label: $t('Edit'),
      uid: 'edit',
      id: 1,
      show_bulk: false,
      on_click: () => {
        edit_properties_popup.patchOptions({
          attrs: {
            disable_feature_select: Boolean(selected_feature.value.properties.workflow),
            properties: selected_feature.value.properties,
            ftg: terra_store.ftg,
            features_object: terra_store.feature_types,
            is_bulk_select: is_bulk_select.value,
            onClose() {
              edit_properties_popup.close();
            },
            async on_submit(form) {
              terra_store.selected_features = terra_store.selected_features.map(feature => ({
                ...feature,
                properties: {
                  ...feature.properties,
                  ...form,
                },
              }),
              );
              await saveFeature();
              feature.value = cloneDeep({ ...selected_feature.value });
              edit_properties_popup.close();
            },
          },
        });
        edit_properties_popup.open();
      },
    },
    {
      label: $t('Copy'),
      uid: 'copy',
      id: 2,
      all: true,
      on_click: () => {
        const { copy } = useClipboard();
        copy(terra_store.selected_features.map(row => row.properties.name || '').join('\n'));
        $toast({
          title: $t('Successfully copied!'),
          text: $t('Feature names are copied to your clipboard.'),
          type: 'success',
          position: 'top-right',
        });
      },
    },
    {
      label: $t('Edit geometry'),
      uid: 'edit-geometry',
      id: 8,
      all: true,
      on_click: () => {
        changeToGlDraw();
      },
    },
    ...((tasks_forms.value.task?.data || []).length
      ? [{
          label: tasks_forms.value.task?.count > 1 ? $t('Update tasks') : $t('Update task'),
          uid: 'update-task',
          id: 6,
          // disabled: !auth_store.check_permission('use_forms', route.params.asset_id),
          all: tasks_forms.value.task?.count > 0,
          tasks: tasks_forms.value.task?.data || [],
          on_click: () => {},
          handleDelete: () => unSelectTaskOrForm('task'),
        }]
      : []),
    ...((tasks_forms.value.form?.data || []).length
      ? [{
          label: tasks_forms.value.form?.count > 1 ? $t('Update forms') : $t('Update form'),
          uid: 'update-form',
          id: 7,
          // disabled: !auth_store.check_permission('use_forms', route.params.asset_id),
          all: tasks_forms.value.form?.count > 0,
          forms: tasks_forms.value.form?.data || [],
          on_click: () => {},
          handleDelete: () => unSelectTaskOrForm('form'),
        }]
      : []),
  // {
  //   label: 'Create submittal',
  //   uid: 'create-submittal',
  //   show_bulk: true,
  //   on_click: () => {
  //   },
  // },
  // {
  //   label: 'Create RFI',
  //   uid: 'create-rfi',
  //   show_bulk: true,
  //   on_click: () => {
  //   },
  // },
  ];
});

function openProgressWorkflow() {
  progress_workflow_popup.patchOptions({
    attrs: {
      selected_feature: selected_feature.value,
      selected_features: selected_features.value,
      bulk_update: is_bulk_select.value,
      selected_feature_workflow: selected_feature_workflow.value,
    },
  });
  progress_workflow_popup.open();
}

function handleDeselectAllFeatures(featureId) {
  terra_store.selected_features = terra_store.selected_features.filter((feature) => {
    return feature.properties.featureTypeId !== Number(featureId);
  });
}

function handleDeselectFeature(featureUid) {
  terra_store.selected_features = terra_store.selected_features.filter((feature) => {
    return feature.properties.uid !== featureUid;
  });
}

function handleItemSelect(item) {
  handleDeselectFeature(item.uid);
}

function deleteOrResetSelectedFeature() {
  if (selected_feature.value.properties.uid) {
    if (terra_store.draw.getSelected()?.features?.length)
      terra_store.clear_gl_draw();
    if (is_dirty.value) {
      feature.value = cloneDeep({ ...selected_feature.value });
      is_dirty.value = false;
    }
    terra_store.features_updated = terra_store.features_updated.filter(val => val !== selected_feature.value?.id);
  }
  else {
    terra_store.draw.delete(selected_feature.value.id);
    terra_store.selected_features = [];
  }
}

function updateFeature(val, type = 'feature', bulk_update = false) {
  if (bulk_update) {
    terra_store.selected_features = terra_store.selected_features.map((item) => {
      if (item.properties.workflow) {
        $toast({
          text: 'Can not change type for the features associated with a workflow',

          type: 'error',
          position: 'top-center',
          has_dismiss_button: false,
          timeout: 20000,

        });

        throw new Error('Can not change type for the features associated with a workflow');
      }
      item.properties.oldfeatureTypeId = item.properties.featureTypeId;
      item.properties.featureType = val.uid;
      item.properties.featureTypeId = val.id;

      return item;
    });
    edit_mode.value = terra_store?.selected_features;
  }
  else if (type === 'feature') {
    feature.value.properties.oldfeatureTypeId = feature.value.properties.featureTypeId;
    feature.value.properties.featureType = val.uid;
    feature.value.properties.featureTypeId = val.id;
  }
  else {
    feature.value.properties[type] = String(val.target.value || '');
  }
  is_class_updated.value = true;
  is_dirty.value = true;
}

async function saveFeature() {
  try {
    is_loading.value = true;
    if (is_dirty.value && terra_store.features_updated.includes(feature.value.id))
      terra_store.selected_features[0] = cloneDeep({ ...feature.value, geometry: terra_store.selected_features[0].geometry });
    else if (is_dirty.value && terra_store.selected_features?.length === 1)
      terra_store.selected_features[0] = cloneDeep({ ...feature.value });
    await terra_store.create_or_update_selected_features({
      clearSelectedFeatures: terra_store.is_creating_vector,
      updateFeatureRequest: true,
    });

    const uid = terra_store.selected_features[0]?.properties?.uid;
    if (terra_store.is_creating_vector)
      terra_store.terra_track_events('Feature created');
    else if (is_class_updated.value)
      terra_store.terra_track_events('Feature class updated', { count: terra_store.selected_features.length, uid });
    else
      terra_store.terra_track_events('Feature updated', { uid });

    terra_store.is_creating_vector = false;
    is_class_updated.value = false;
    edit_mode.value = [];
    is_loading.value = false;
  }
  catch (err) {
    is_loading.value = false;
    logger.log(err);
  }
  is_dirty.value = false;
}

function init() {
  if (!is_bulk_select.value)
    feature.value = cloneDeep({ ...selected_feature.value });
}

watch(selected_feature, (val) => {
  if (!is_bulk_select.value && val)
    feature.value = cloneDeep({ ...selected_feature.value });
});

init();

async function changeToGlDraw() {
  try {
    if (terra_store?.draw.getSelected()?.features?.length)
      saveFeature();

    await terra_store.draw.set({
      type: 'FeatureCollection',
      features: terra_store.selected_features,
    });
    emit('filterGlDrawConvertedFeatures');
    terra_store.draw.changeMode('simple_select', {
      featureIds: terra_store.draw.getAll()?.features.map(item => item.id),
    });
    edit_mode.value = terra_store.draw.getAll()?.features;
  }
  catch (err) {
    logger.log(err);
  }
}
</script>

<template>
  <div v-if="!show_view_details_only" class="fixed bottom-5 right-5 w-[352px] bg-white rounded-lg" :class="[is_dropdown_open ? 'z-[101]' : 'z-[99]']">
    <div class="p-4">
      <template v-if="is_bulk_select">
        <div class="flex justify-between items-center mb-2">
          <div class="text-[16px] font-semibold">
            {{ selected_features.length }} selected
          </div>
          <FeatureDetailsMenu
            :hawk_menu_items="hawk_menu_items.filter((menu) => menu.show_bulk || menu.all)"
            @update-feature-type="updateFeature($event, 'feature', true)"
            @open-creation-popup="emit('openCreationPopup', $event)"
            @update-dropdown-open="is_dropdown_open = $event"
          />
        </div>
        <div class="flex flex-col gap-4 mt-4 grouped_features__container max-h-[160px] scrollbar overflow-y-scroll">
          <div v-for="(value, id) in grouped_features" :key="id" class="grouped_features__container_details">
            <div class="name_count flex justify-between py-[7px] mb-2 items-center">
              <div class="grouped_features__container_details_name flex gap-3 items-center">
                <div
                  class="w-4 h-4 rounded-full"
                  :style="getStyles(terra_store.feature_types[id])"
                />
                <span class="font-medium text-sm text-gray-700">{{ terra_store.feature_types[id]?.name || 'Unassociated' }}</span>
              </div>
              <div class="features_count bg-gray-100 cursor-pointer" @mouseover="count_hovered_feature_id = id" @mouseleave="count_hovered_feature_id = 0">
                <span class="grouped_features__container_details_count font-medium text-xs text-gray-700" @click="handleDeselectAllFeatures(id)">
                  <span v-if="count_hovered_feature_id === id"><IconHawkTrashThree class="w-3.5 h-3.5" /></span>
                  <span v-else>{{ value.length }}</span>
                </span>
              </div>
            </div>
            <div class="grouped_features__container_details_features relative flex items-center flex-row gap-2 flex-wrap">
              <div v-for="feature_data in value.slice(0, 2)" :key="feature_data.properties.uid" class="grouped_features__container_details_features_name">
                <span class="cursor-pointer pt-[3px] pr-[4px] w-[150px] pb-[4px] pl-[8px] border border-gray-300 rounded-lg flex items-center justify-center text-sm font-medium">
                  <HawkText :content="feature_data.properties?.name || 'Untitled'" :length="12" />
                  <IconHawkXClose class="w-[10px] h-[10px] text-gray-400 ml-auto" @click="handleDeselectFeature(feature_data.properties.uid)" />
                </span>
              </div>
              <div v-if="value.length > 2" class="grouped_features__container_details_extra_features">
                <HawkMenu
                  :items="getRemainingGroupedFeatures(value.slice(2))"
                  :has_bordered_trigger="false"
                  position="fixed"
                  class="z-30"
                  additional_item_classes="!pl-[14px] !pr-0 !py-0 !my-[8px] !min-w-[8rem]"
                  additional_dropdown_classes="max-h-[200px] overflow-auto scrollbar"
                  @select="handleItemSelect"
                  @open="is_dropdown_open = true"
                  @close="is_dropdown_open = false"
                >
                  <template #trigger>
                    <span class="cursor-pointer pt-[3px] min-w-[60px] pr-[4px] pb-[4px] pl-[8px] border border-gray-300 rounded-lg flex items-center  text-sm font-medium">
                      + {{ `${value.length}` - 2 }}
                      <IconHawkXClose class="w-[10px] h-[10px] text-gray-400 ml-auto" />
                    </span>
                  </template>
                  <template #item="item">
                    <div class="cursor-pointer pt-[3px] pr-[4px] w-[150px] h-[29px] pb-[4px] pl-[8px] border border-gray-300 rounded-lg flex items-center justify-center text-sm font-medium">
                      <HawkText :content="item.item.label" :length="12" class="cursor-pointer pt-[3px] pb-[3px] pl-[8px]" />
                      <IconHawkXClose class="w-[10px] h-[10px] text-gray-400 ml-auto mr-1" />
                    </div>
                  </template>
                </HawkMenu>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="flex justify-between items-center">
          <input
            :value="feature.properties?.name"
            type="text"
            :placeholder="$t('Untitled')"
            class="text-[18px] w-[90%] font-medium border-0 border-dashed   focus:outline-0 p-0 focus:ring-0 focus:border-b"
            :readonly="!terra_store.check_permission('modify_features')"
            @input="updateFeature($event, 'name')"
          >

          <FeatureDetailsMenu
            :hawk_menu_items="hawk_menu_items.filter((menu) => !menu.show_bulk || menu.all)"
            @open-creation-popup="emit('openCreationPopup', $event)"
            @update-dropdown-open="is_dropdown_open = $event"
          />
        </div>
        <div class="-mt-1">
          <input
            :value="feature.properties?.description"
            type="text"
            :placeholder="$t('Enter description')"
            class="text-[12px] text-gray-500 border-0 w-64 border-dashed focus:outline-0 p-0 focus:ring-0 focus:border-b"
            :readonly="!terra_store.check_permission('modify_features')"
            @input="updateFeature($event, 'description')"
          >
        </div>

        <HawkMenu
          :class="{ 'pointer-events-none': feature.properties?.workflow || !terra_store.check_permission('modify_feature_properties') }" additional_trigger_classes="!ring-0 !border-0" :additional_dropdown_classes="!is_update_progress_enabled ? '!w-80 !max-h-60    !left-0 z-2 scrollbar' : 'w-0 h-0'"
          @open="is_dropdown_open = true"
          @close="is_dropdown_open = false"
        >
          <template #trigger>
            <div
              class="cursor-pointer mt-1 bg-gray-200 rounded-2xl px-2 py-0.5 text-[12px] font-medium"
              :class="{ 'opacity-60': !feature_type?.name }"
              :style="feature_type ? getStyles(feature_type, 'tag') : {}"
            >
              <div class="font-medium text-[13px]">
                <span class="flex items-center"> {{ feature_type?.name || "Select type" }} </span>
              </div>
            </div>
          </template>
          <template #content="{ close }">
            <FeatureTypes type="dropdown" :dropdown_value="feature_type" @select="updateFeature($event); close()" />
          </template>
        </HawkMenu>
      </template>
    </div>
    <div v-if="extra_properties.length && !is_bulk_select" class="max-h-[125px] px-4 overflow-y-auto scrollbar">
      <div class="border-t border-gray-200 pt-2">
        <FeatureExtraProperties />
      </div>
    </div>
    <div class="w-full flex flex-row gap-2.5 p-4">
      <template v-if="is_bulk_select">
        <HawkButton type="outlined" class="!h-auto" block @click="emit('isTableActive')">
          {{ terra_store.check_permission('modify_features') ? $t('Edit details') : $t('View details') }}
        </HawkButton>
        <HawkButton v-if="edit_mode.length === 0" :disabled="!is_update_progress_enabled && !is_dirty" class="!h-auto" color="primary" block @click="openProgressWorkflow()">
          <span v-tippy="`${is_update_progress_enabled ? '' : message_for_disabled_workflow_option}`">
            {{ $t('Update Progress') }}
          </span>
        </HawkButton>
        <HawkButton v-else color="primary" block :loading="is_loading" @click="saveFeature">
          {{ is_dirty ? $t('Save') : $t('Save geometry') }}
        </HawkButton>
      </template>

      <template v-else>
        <template v-if="is_dirty || !selected_feature.properties.uid || terra_store.features_updated.includes(selected_feature.id) || edit_mode.length">
          <HawkButton type="outlined" block @click="deleteOrResetSelectedFeature()">
            {{ $t('Cancel') }}
          </HawkButton>
          <HawkButton color="primary" block :loading="is_loading" :disabled="!(is_dirty || terra_store.features_updated.includes(selected_feature.id))" @click="saveFeature">
            {{ edit_mode.length ? $t('Save geometry') : $t('Save') }}
          </HawkButton>
        </template>
        <template v-else>
          <feature-details-slider
            :feature="selected_feature"
            :hawk_menu_items="hawk_menu_items.filter((menu) => !menu.show_bulk || menu.all)"
            @click="show_detailed_view = true"
            @open-creation-popup="emit('openCreationPopup', $event)"
          />
          <HawkButton v-if="is_update_progress_enabled" color="primary" block @click="openProgressWorkflow()">
            {{ $t('Update Progress') }}
          </HawkButton>
        </template>
      </template>
    </div>
  </div>
  <feature-details-slider
    v-else
    :feature="selected_feature"
    :hawk_menu_items="hawk_menu_items.filter((menu) => !menu.show_bulk || menu.all)"
    @click="show_detailed_view = true"
    @open-creation-popup="emit('openCreationPopup', $event)"
  />
</template>

<style lang="scss" scoped>
.features_count {
  display: inline-block;
  border-radius: 50%;
  min-width: 20px;
  min-height: 20px;
  text-align: center;
  line-height: 1;
  box-sizing: content-box;
  white-space: nowrap;
}
.features_count:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  padding-top: 100%;
  height: 0;
}
.features_count span {
  display: inline-block;
  vertical-align: middle;
}
</style>
