<script setup>
import { snakeCase } from 'lodash-es';
import { useRoute, useRouter } from 'vue-router';
import { TEMPLATE_PERMISSIONS } from '~/forms/constants';
import { searchData } from '~/common/utils/common.utils';
import HawkTable from '~/common/components/organisms/hawk-table/hawk-table.vue';
import TableWrapperVue from '~/common/components/organisms/hawk-table/table.wrapper.vue';
import { useStatusMetrics } from '~/forms/composables/form-list-configuration';
import FormStatusGroup from '~/forms/molecules/form-status-group.vue';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useCommonStore } from '~/common/stores/common.store';

const props = defineProps({
  is_loading: {
    type: Boolean,
    default: false,
  },
  forms: {
    type: Array,
    default: () => [],
  },
});

const $t = inject('$t');

const auth_store = useAuthStore();
const router = useRouter();
const route = useRoute();
const common_store = useCommonStore();

const search = ref('');
const table_instance = ref(null);
const columns = computed(() => [{
  header: $t('ID'),
  accessorKey: 'number',
  id: 'number',
  enableResizing: false,
  size: 100,
}, {
  header: $t('Template name'),
  accessorKey: 'name',
  id: 'name',
  size: 200,
  enableResizing: false,
},
{
  header: $t('Category'),
  accessorKey: 'category',
  id: 'category',
  enableResizing: false,
},
{
  header: $t('Asset'),
  accessorKey: 'target_element',
  id: 'asset',
  enableResizing: false,
  sortingFn: (rowA, rowB, columnId) => {
    const asset_name_A = getAssetName(rowA.original.target_element);
    const asset_name_B = getAssetName(rowB.original.target_element);

    return asset_name_A.localeCompare(asset_name_B);
  },
},
{
  header: $t('Status'),
  accessorKey: 'metrics',
  id: 'metrics',
  size: 300,
  enableResizing: false,
},
]);

const getStatusMetrics = metric => useStatusMetrics(metric);

function getAssetName(target_element) {
  const asset_id = target_element?.asset || '';

  return asset_id && common_store.assets_map?.[asset_id]?.name;
};

function routeToFormTemplateDetailPage(template, query = {}) {
  if (query.open) {
    const metric_data = template.metrics[snakeCase(query.open)];
    query.open = metric_data.block_uid || metric_data.step_index || query.open;
    query.status_type = 'submission_status';
    metric_data.block_uid && (query.status_type = 'block');
    metric_data.step_index && (query.status_type = 'step_index');
  }

  if (query.group_by || template.status === 'published')
    router.push({
      name: auth_store.check_split('forms_v2') ? 'fam' : 'form-template-overview',
      params: {
        template_uid: template.uid,
        asset_id: route.params.asset_id,
        template_name: template?.name?.replaceAll(' ', '-'),
      },
      query,
    });
  else
    router.push({ name: template.workflow ? 'forms-template-workflow-builder' : 'forms-template-builder', params: { template_uid: template.uid, asset_id: route.params.asset_id } });
}
// when search value is present reset the pagination
watch(() => search?.value?.length, () => {
  table_instance.value.resetPagination();
});
const filtered_data = computed(() => searchData(props?.forms || [], search.value));

const has_data = computed(() => !!filtered_data.value.length);
const has_filter_applied = computed(() => search.value);
const is_boarding = computed(() => !has_data.value && !has_filter_applied.value);
const has_no_results = computed(() => !has_data.value && has_filter_applied.value);
</script>

<template>
  <div>
    <hawk-sticky-header>
      <div class="flex justify-between">
        <hawk-search-input v-model="search" :placeholder="$t('Search')" class="my-[1px]" />
        <new-form-template-button />
      </div>
    </hawk-sticky-header>
    <div v-if="is_loading">
      <hawk-loader />
    </div>
    <HawkIllustrations v-else-if="has_no_results" type="no-results" for="forms" />
    <HawkIllustrations v-else-if="is_boarding" type="on-boarding" for="forms" :is_create_indicator="auth_store.check_permission(TEMPLATE_PERMISSIONS.V2_CREATE_FORM_TEMPLATES, route.params.asset_id)" />
    <TableWrapperVue v-else class="max-w-[calc(100vw-80px)] !max-h-[calc(100vh-120px)]" container_class="mt-0">
      <HawkTable
        :is_loading="is_loading"
        :pagination_config="{ totalRows: filtered_data.length, pageSize: 25 }"
        :data="filtered_data" :columns="columns"
        :is_gapless="true"
        :show_menu_header="false"
        :disable_resize="true"
        @row-clicked="$event => routeToFormTemplateDetailPage($event)"
        @tableInstance="table_instance = $event"
      >
        <template #name="form">
          <div class="whitespace-nowrap">
            <hawk-text :length="40" class="text-gray-900 font-medium" :content="form.data.getValue()" />
          </div>
        </template>
        <template #category="category">
          <HawkCategoryName v-if="category.data.getValue()" :truncate_length="20" :uid="category.data.getValue()" />
          <span v-else>-</span>
        </template>
        <template #metrics="metric">
          <div class="flex flex-wrap">
            <FormStatusGroup v-if="getStatusMetrics(metric).length" :data="getStatusMetrics(metric)" @click.native.prevent.stop @clicked="$event => routeToFormTemplateDetailPage(metric.data.row.original, { group_by: 'status', open: $event.name })" />
          </div>
        </template>
        <template #asset="asset">
          <div class="flex flex-wrap">
            <HawkText v-if="getAssetName(asset.data.getValue())" :length="30" class="text-gray-600" :content="getAssetName(asset.data.getValue())" />
            <span v-else>-</span>
          </div>
        </template>
      </HawkTable>
    </TableWrapperVue>
  </div>
</template>
