<script setup>
// --------------------------------- Imports -------------------------------- //
import HawkTreeSelect from '~/common/components/organisms/hawk-tree/hawk-treeselect.vue';

// ---------------------------------- Props --------------------------------- //
const props = defineProps({
  el$: {
    type: Object,
    default: () => ({}),
  },
});
// ---------------------------------- Emits --------------------------------- //

// ---------------------------- Injects/Provides ---------------------------- //
const terra_containers = inject('terra-containers');
const is_fetching_terra_containers = inject('is-fetching-terra-containers');

// ----------------------- Variables - Pinia - consts ----------------------- //

// --------------------- Variables - Pinia - storeToRefs -------------------- //

// ------------------- Variables - Local - consts and lets ------------------ //

// ------------------------ Variables - Local - refs ------------------------ //

// ---------------------- Variables - Local - reactives --------------------- //

// --------------------------- Computed properties -------------------------- //
const terra_container_tree = computed(() =>
  terra_containers.value.map(container => ({
    ...container,
    children: container.groups.map(group => ({
      ...group,
      children: group.projects,
    })),
  })),
);

const default_lookup_projects = computed(() => props.el$.data?.associate_metadata_object?.lookup_projects || []);

// -------------------------------- Functions ------------------------------- //
function get_children(item) {
  return item.children;
}

function update_layer_selection(layers) {
  props.el$.form$.update({
    ...props.el$.form$.data,
    config: {
      ...props.el$.form$.data.config,
      associate_metadata_object: {
        ...props.el$.form$.data.config.associate_metadata_object,
        lookup_projects: layers,
      },
    },
  });
}

// -------------------------------- Watchers -------------------------------- //

// ----------------------------- Lifecycle Hooks ---------------------------- //
</script>

<template>
  <div>
    <hr class="border-t border-gray-300 mb-4">
    <ToggleElement
      name="associate_metadata" :label="$t('Associate metadata')"
      :add-classes="{
        ToggleElement: {
          wrapper: ['justify-end'],
        },
      }"
    />
    <span class="w-2/3 text-gray-700 text-xs mt-1">{{ $t('Define properties/metadata to track along with the component while viewing/exporting the form.') }}</span>

    <template v-if="props.el$.data.associate_metadata_object.associate_metadata">
      <!-- Layer -->
      <div class="font-medium my-2">
        {{ $t('Layers') }}<span class="text-amber-600">*</span>
      </div>
      <HawkLoader v-if="is_fetching_terra_containers" class="col-span-12" />
      <HawkTreeSelect
        v-show="!is_fetching_terra_containers"
        :key="`${is_fetching_terra_containers}_${terra_container_tree.length}`"
        class="mb-4"
        :options="{
          name: 'lookup_projects',
          object: false,
          search: true,
          rules: 'required',
          description: $t('Select the layers to lookup values in'),
        }"
        :initial_state="default_lookup_projects"
        children_key="children"
        :get_children_func="get_children"
        :data="terra_container_tree"
        :option_text_length="25"
        :show_expanded_search="true"
        select_type="LEAF_PRIORITY"
        @updateForm="update_layer_selection"
      />
      <!-- Properties -->
      <div class="font-medium mb-2">
        {{ $t('Properties') }}
      </div>
      <ListElement
        name="properties"
        :controls="{ add: true, remove: true, sort: true }"
        :add-text="`+ ${$t('Add Property')}`"
        :sort="true"
        :min="0"
        :remove-classes="{
          ListElement: {
            add: ['form-bg-primary', 'form-border-color-primary', 'form-color-on-primary', 'form-border-width-btn', 'hover:scale-105', 'focus:form-ring'],
          },
        }"
        :add-classes="{
          ListElement: {
            add: ['text-gray-700', 'font-semibold', 'text-sm'],
          },
        }"
      >
        <template #default="{ index }">
          <TextElement
            :name="index"
            :attrs="{ autofocus: true }"
          />
        </template>
      </ListElement>
    </template>
  </div>
</template>
