<script setup>
import { MenuItem } from '@headlessui/vue';

const props = defineProps({
  // eslint-disable-next-line vue/prop-name-casing
  default_value: {
    type: Object,
    default: () => ({}),
  },
});
const emits = defineEmits(['applyFilters']);

const $t = inject('$t');
const THERM_FILTER_OPTIONS = [
  { value: 'all', label: $t('All images') },
  { value: 'raw_images', label: $t('Raw images') },
  { value: 'external_images', label: $t('External Images') },
];

const state = reactive({
  type_filter: props.default_value?.value || 'all',
});

function handleFilterSelection(type_filter) {
  state.type_filter = type_filter.value;
  emits('applyFilters', { uid: 'type', value: state.type_filter });
}
</script>

<template>
  <hawk-menu
    position="fixed"
    additional_trigger_classes="!ring-0 w-full"
  >
    <template #trigger="{ open }">
      <div class="flex items-center">
        <div class="text-sm">
          {{ THERM_FILTER_OPTIONS.find((option) => option.value === state.type_filter)?.label }}
        </div>
        <IconHawkChevronDown
          class="transition-transform"
          :class="{ 'rotate-180 !visible': open }"
        />
      </div>
    </template>
    <template #content="{ close }">
      <MenuItem
        v-for="type_filter in THERM_FILTER_OPTIONS"
        v-slot="{ active }"
        :key="type_filter.value"
        as="div"
        class="px-0 w-[200px] cursor-pointer"
      >
        <div
          class="p-3 text-sm text-gray-700"
          :class="{ 'bg-gray-50': active, 'bg-gray-100': type_filter.value === state.type_filter }"
          @click="handleFilterSelection(type_filter); close()"
        >
          {{ type_filter?.label }}
        </div>
      </MenuItem>
    </template>
  </hawk-menu>
</template>
