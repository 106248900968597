import dayjs from 'dayjs';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

export function $dateChecker(date) {
  return (
    dayjs()
      .startOf('day')
      .toDate() <= new Date(date)
      && new Date(date)
      <= dayjs()
        .endOf('day')
        .toDate()
  );
}
export function $weekChecker(date) {
  return (
    dayjs()
      .startOf('week')
      .toDate() <= new Date(date)
      && new Date(date)
      <= dayjs()
        .endOf('week')
        .toDate()
        && !$dateChecker(date)
  );
}
export function $monthChecker(date) {
  return (
    dayjs()
      .startOf('month')
      .toDate() <= new Date(date)
      && new Date(date)
      <= dayjs()
        .endOf('month')
        .toDate()
        && !$weekChecker(date)
        && !$dateChecker(date)
  );
}
export function $earlier(date) {
  const week_text
    = dayjs().startOf('week') > dayjs().startOf('month')
      ? 'week'
      : 'month';
  return (
    dayjs().startOf(week_text) > new Date(date)
    && !$monthChecker(date)
    && !$weekChecker(date)
    && !$dateChecker(date)
  );
}
export function $get_sorted_dates(date) {
  const { $t } = useCommonImports();

  if ($dateChecker(date))
    return $t('Today');

  else if ($weekChecker(date))
    return $t('This week');

  else if ($monthChecker(date))
    return $t('This month');
  else if ($earlier(date))
    return $t('Earlier');
}
