<script setup>
import { MenuItem } from '@headlessui/vue';
import dayjs from 'dayjs';
import { useFamConstants } from '~/forms-as-module/composables/fam-constants.composable.js';

const props = defineProps({
  // eslint-disable-next-line vue/prop-name-casing
  default_value: {
    type: Object,
    default: () => ({}),
  },
});
const emits = defineEmits(['applyFilters']);

const $t = inject('$t');
const DATE_FILTER_OPTIONS = [
  { value: 'all_time', label: $t('All time') },
  { value: 'today', label: $t('Today') },
  { value: 'yesterday', label: $t('Yesterday') },
  { value: 'last_7_days', label: $t('Last 7 days') },
  { value: 'last_30_days', label: $t('Last 30 days') },
  { value: 'custom', label: $t('Custom') },
];
const { getFormattedDate } = useFamConstants();

const state = reactive({
  date_filter: 'all_time',
  date_range: null,
});
const form_data = ref(null);

function handleCustomDateSelection() {
  state.date_filter = 'custom';
  state.date_range = form_data.value.date_range;

  emits('applyFilters', {
    uid: 'created_at',
    value: state.date_range.map(value => dayjs(value)),
    option_value: state.date_filter,
  });
}

function handleFilterSelection(date_filter) {
  state.date_filter = date_filter.value;
  state.date_range = getFormattedDate(state.date_filter);

  emits('applyFilters', {
    uid: 'created_at',
    value: state.date_filter === 'all_time' ? null : state.date_range,
    option_value: state.date_filter,
  });
}

function init() {
  state.date_filter = props.default_value?.option_value || 'all_time';
  if (state.date_filter === 'custom' && props.default_value?.value?.[0] !== null) {
    form_data.value = {
      date_range: props.default_value?.value?.map(value => value?.$d),
    };
  }
}

init();
</script>

<template>
  <hawk-menu
    position="fixed"
    additional_trigger_classes="!ring-0 w-full"
  >
    <template #trigger="{ open }">
      <div class="flex items-center">
        <div class="text-sm">
          {{ DATE_FILTER_OPTIONS.find((option) => option.value === state.date_filter)?.label }}
        </div>
        <IconHawkChevronDown
          class="transition-transform"
          :class="{ 'rotate-180 !visible': open }"
        />
      </div>
    </template>
    <template #content="{ close }">
      <MenuItem
        v-for="date_filter in DATE_FILTER_OPTIONS"
        v-slot="{ active }"
        :key="date_filter.value"
        as="div"
        class="px-0 w-[200px] cursor-pointer"
      >
        <hawk-menu
          v-if="date_filter.value === 'custom'"
          additional_trigger_classes="!ring-0 w-[200px] flex items-center justify-start"
          position="fixed"
        >
          <template #trigger>
            <div class="p-3 text-sm text-gray-700">
              {{ date_filter.label }}
            </div>
          </template>
          <template #content>
            <div class="p-3 w-[410px]" @click.stop>
              <Vueform v-model="form_data" sync size="sm">
                <div class="col col-span-12">
                  <div class="font-medium text-sm mb-2">
                    {{ $t('Date Range') }}
                  </div>
                  <DateTimeElement
                    name="date_range"
                    class="w-full"
                    :options="{
                      placeholder: 'Select date range',
                      clearable: false,
                      enableTimePicker: true,
                      range: true,
                      teleport: false,
                      format: 'MMM dd yyyy hh:mm aa',
                    }"
                  />
                </div>
              </Vueform>
            </div>
          </template>
          <template #footer="{ close: closeDateRangePicker }">
            <Vueform size="sm" class="p-2">
              <div class="flex justify-end w-full col-span-full">
                <ButtonElement
                  name="cancel"
                  :secondary="true"
                  @click="close()"
                >
                  {{ $t('Cancel') }}
                </ButtonElement>
                <ButtonElement
                  name="apply"
                  class="ml-3"
                  :disabled="!form_data?.date_range?.length"
                  @click="handleCustomDateSelection(date_filter);closeDateRangePicker();close();"
                >
                  {{ $t('Apply') }}
                </ButtonElement>
              </div>
            </Vueform>
          </template>
        </hawk-menu>
        <div
          v-else
          class="p-3 text-sm text-gray-700"
          :class="{ 'bg-gray-50': active, 'bg-gray-100': date_filter.value === state.date_filter }"
          @click="handleFilterSelection(date_filter); close()"
        >
          {{ date_filter?.label }}
        </div>
      </MenuItem>
    </template>
  </hawk-menu>
</template>
