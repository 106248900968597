<script setup>
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
// composables
import { useTransmittalPermissions } from '~/dms/composables/transmittal-permissions.composable';

// stores
import { useAuthStore } from '~/auth/stores/auth.store';

const transmittal_permissions = useTransmittalPermissions();
const auth_store = useAuthStore();
const { $t, route, router } = useCommonImports();

// Form List page navigation
const tabs = computed(() => [{
  uid: 'files-documents',
  label: $t('Documents'),
  to: { name: 'files-documents', params: { asset_id: route.params.asset_id } },
  disabled: false,
}, ...(auth_store.check_split('files_v2_advanced')
  ? [{
      uid: 'files-transmittals',
      label: $t('Transmittals'),
      to: { name: 'files-transmittals', params: { asset_id: route.params.asset_id } },
      disabled: false,
    }]
  : [])]);

const active_tab = computed(() => route.matched.findIndex(route => route.name === 'files-documents') >= 0 ? 'files-documents' : 'files-transmittals');
</script>

<template>
  <div class="flex min-h-full flex-col justify-center">
    <HawkPageHeader v-if="!route.path.includes('/settings')" :title="$t('Files')">
      <template #left>
        <hawk-page-header-tabs :tabs="tabs" :active_item="active_tab" />
      </template>
      <template #right>
        <div class="flex items-center gap-2">
          <HawkButton v-if="auth_store.check_split('files_v2_advanced') && transmittal_permissions.checkTransmittalPermission({ permission: 'setting_icon' })" type="outlined" icon @click="router.push({ name: 'dms-settings', params: { asset_id: route.params.asset_id } });">
            <IconHawkSettingsOne class="h-5 w-5" />
          </HawkButton>
          <HawkOnboardingInfo on_boarding_for="files" />
        </div>
      </template>
    </HawkPageHeader>
    <div class="px-4">
      <router-view />
    </div>
  </div>
</template>
