<script setup>
import { reactive } from 'vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { isFileExtensionAllowed } from '~/common/utils/common.utils.js';
import { useTerraStore } from '~/terra/store/terra.store.js';

const emit = defineEmits(['loadData']);

const { $services, $toast, route } = useCommonImports();
const terra_store = useTerraStore();
const excel_columns = ['Block', 'Tracker/Table', 'Module number', 'Old serial number', 'Module(X,Y)', 'New serial number'];

const state = reactive({
  is_downloading_template: false,
  form: null,
  data: [],
});

async function handleDownloadTemplate() {
  try {
    state.is_downloading_template = true;
    const ExcelJS = await import('exceljs');
    const { saveAs } = await import('file-saver');

    const workbook = new ExcelJS.Workbook();

    const worksheet = workbook.addWorksheet();

    worksheet.columns = excel_columns.map(key => ({ value: key, header: key, width: 30 }));

    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), 'correction_template.xlsx');
    state.is_downloading_template = false;
  }
  catch (error) {
    logger.log(`Error: ${error}`);
    state.is_downloading_template = false;
  }
}

function createData() {
  const file_data = state.form.file;
  const reader = new FileReader();

  reader.onload = async function (event) {
    try {
      const data = new Uint8Array(event.target.result);
      const ExcelJS = await import('exceljs');
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(data);

      // Get the first worksheet
      const worksheet = workbook.worksheets[0];
      const lines = [];

      // Extract data
      worksheet.eachRow({ includeEmptyCells: true }, (row) => {
        const rowData = [];
        row.eachCell({ includeEmpty: true }, (cell) => {
          if (cell.type === ExcelJS.ValueType.Formula)
            rowData.push(cell.value.result);
          else
            rowData.push(cell.value);
        });
        if (rowData.length)
          lines.push(rowData);
      });
      const columns = lines[0];

      const rows_data = lines.slice(1).map((line) => {
        return columns.reduce((row_Object, col, index) => {
          if (col.length)
            row_Object[col] = line[index];
          return row_Object;
        }, {});
      });

      state.data = rows_data.reduce((acc, row) => {
        if (row.Block && row['Tracker/Table'] && row['Module(X,Y)'] && row['New serial number']) {
          acc.push({
            block: row.Block,
            tracker: row['Tracker/Table'],
            module_x_y: row['Module(X,Y)'],
            updated_serial_number: row['New serial number'],
          });
        }
        return acc;
      }, []);

      if (state.data.length > 25000) {
        $toast({
          title: 'Data Limit Exceeded',
          text: 'Error: The file contains more than 25,000 entries.',
          type: 'error',
          position: 'top-right',
        });
      }
    }
    catch (error) {
      $toast({
        title: 'Error',
        text: 'Please check your file something went wrong.',
        type: 'error',
        position: 'top-right',
      });
      console.error(error);
    }
  };
  const is_excel = (isFileExtensionAllowed(file_data?.name) && file_data?.name.endsWith('.xlsx'));
  if (file_data && is_excel) {
    reader.readAsArrayBuffer(file_data);
  }
  else {
    state.data = [];
    state.deleted_columns = [];
    state.added_columns = [];
  }
  if (file_data && !is_excel) {
    $toast({
      title: 'Error',
      text: 'Please check your file. Only .xlsx file is allowed.',
      type: 'error',
      position: 'top-right',
    });
  }
}

async function onSave() {
  try {
    if (state.data.length > 25000) {
      $toast({
        title: 'Data Limit Exceeded',
        text: 'Error: The file contains more than 25,000 entries.',
        type: 'error',
        position: 'top-right',
      });
    }
    else {
      await $services.features.post({
        attribute: `container/${route.params.id}/barcode-correction`,
        body: {
          barcodes: state.data,
        },
      });
      $toast({
        text: 'Serial numbers correction successful',
        type: 'success',
      });
      emit('loadData');
    }
  }
  catch (error) {
    logger.error(error);
    $toast({
      title: 'Something went wrong',
      text: 'Please try again',
      type: 'error',
    });
  }
}
</script>

<template>
  <div v-if="terra_store.check_permission('modify_features') && terra_store.check_permission('modify_scan_data')" class="py-3">
    <div class="text-lg font-semibold py-3">
      {{ $t('Serial number correction') }}
    </div>
    <ul class="flex flex-col gap-y-1 !list-disc ml-4">
      <li class="list-item text-sm">
        {{ $t('Download sample file to enter your data') }}
      </li>
      <li class="list-item text-sm">
        {{ $t('Serial numbers corrected through import will not reflect in Scan history.') }}
      </li>
    </ul>
    <HawkButton type="outlined" class="my-3" :loading="state.is_downloading_template" @click="handleDownloadTemplate">
      {{ $t('Download template') }}
    </HawkButton>

    <Vueform
      v-model="state.form"
      sync
      :display-errors="false"
      :endpoint="onSave"
    >
      <div class="col-span-12">
        <FileElement
          name="file"
          accept=".xlsx"
          :rules="['required']"
          class="mb-4"
          :drop="true"
          :use_uppy="false"
          :presets="['hawk_file_element']"
          :options="{
            clickable_text: $t('Click to upload'),
            text: $t('or drag and drop'),
            description: $t('Format: XLSX'),
          }"
          @change="createData"
        />
        <div class="flex justify-end items-center">
          <hawk-button
            v-if="state.form?.file"
            class="mr-5"
            type="outlined"
            @click="state.form.file = null"
          >
            {{ $t('Cancel') }}
          </hawk-button>
          <ButtonElement button-class="w-full bg-blue-600" name="submit" :submits="true">
            {{ $t('Save') }}
          </ButtonElement>
        </div>
      </div>
    </Vueform>
  </div>
</template>

<style scoped lang="scss">

</style>
