<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { inject } from 'vue';

const props = defineProps({
  progress_text: {
    type: String,
    default: 'Exporting to pdf',
  },
  completed_text: {
    type: String,
    default: 'Exported PDF',
  },
  subtitle: {
    type: String,
    default: '',
  },
  completed_subtitle: {
    type: String,
    // default: 'Check your downloads folder',
  },
  submit: {
    type: Function,
    default: () => {
      return true;
    },
  },
  position: {
    type: String,
    default: 'top-right',
    validator(value) {
      return ['top-right', 'bottom-right', 'bottom-left', 'top-left', 'right', 'bottom', 'left', 'top'].includes(value);
    },
  },
  show_cancel_button: {
    type: Boolean,
    default: true,
  },
  show_subtitle: {
    type: Boolean,
    default: true,
  },
  close_after_download: {
    type: Boolean,
    default: true,
  },
  close_after: {
    type: Number,
    default: 5000,
  },
});

const emit = defineEmits(['close', 'cancel', 'complete', 'openFolder']);
const $t = inject('$t');

const loading = ref(true);
const title = ref(props.progress_text);
const subtitle = ref(props.subtitle);
const toast_position_class = computed(() => {
  const classes = [];
  switch (props.position) {
    case 'top-right':
      classes.push('right-6 top-20');
      break;

    case 'bottom-right':
      classes.push('right-6 bottom-10');
      break;

    case 'bottom-left':
      classes.push('left-6 bottom-10');
      break;

    case 'top-left':
      classes.push('left-6 top-20');
      break;

    case 'right':
      classes.push('right-6 top-1/2 -translate-y-1/2');
      break;

    case 'bottom':
      classes.push('left-1/2 -translate-x-1/2 bottom-10');
      break;

    case 'left':
      classes.push('left-6 top-1/2 -translate-y-1/2');
      break;

    case 'top':
      classes.push('left-1/2 -translate-x-1/2 top-20');
      break;

    default: classes.push('right-6 top-20');
      break;
  }
  return classes;
});

async function getData() {
  loading.value = true;
  await props.submit();
  loading.value = false;
  title.value = props.completed_text;
  subtitle.value = props.completed_subtitle ?? $t('Check your downloads folder');
  emit('complete');
  if (props.close_after_download) {
    setTimeout(() => {
      emit('close');
    }, props.close_after);
  }
}
// can have error text

onMounted(() => {
  getData();
});
</script>

<template>
  <div class="fixed z-50" :class="toast_position_class">
    <div
      class="bg-gray-800 px-4 text-white shadow-lg rounded-lg w-[400px] flex items-center justify-between text-sm"
      :class="loading ? 'py-2.5' : 'py-4'"
    >
      <div class="flex gap-4 items-center">
        <div v-if="loading" class="h-6 w-6 grid place-items-center ">
          <HawkLoader container_class="m-0" :height="5" :width="5" />
        </div>
        <HawkIconExportTick v-else />
        <div>
          <div class="font-semibold">
            <slot v-if="$slots.title" name="title" />
            <div v-else>
              {{ title }}
            </div>
          </div>
          <div v-if="show_subtitle" class="text-grey-200">
            <slot v-if="$slots.subtitle" name="subtitle" />
            <div
              v-else
              @click="$emit('openFolder')"
            >
              {{ subtitle }}
            </div>
          </div>
        </div>
      </div>
      <div>
        <HawkButton
          v-if="show_cancel_button"
          :icon="!loading"
          type="dark"
          @click="loading ? $emit('cancel') : $emit('close')"
        >
          <span v-if="loading">{{ $t('Cancel') }}</span>
          <IconHawkX v-else />
        </HawkButton>
      </div>
    </div>
  </div>
</template>
