import { useCommonImports } from '~/common/composables/common-imports.composable.js';

export const DOCUMENT_PERMISSIONS = {
  VIEW_DOCUMENTS: 'view_documents',
  CREATE_DOCUMENTS: 'create_documents',
  MODIFY_DOCUMENTS: 'modify_documents',
  SHARE_DOCUMENTS: 'share_documents',
};

export const TRANSMITTAL_PERMISSIONS = {
  CREATE_TRANSMITTALS: 'create_transmittals',
  CONFIGURE_DMS_SETTINGS: 'configure_dms_settings',
  VIEW_WORKFLOWS: 'view_dms_workflow_templates',
  CREATE_WORKFLOWS: 'create_dms_workflow_templates',
  MODIFY_WORKFLOWS: 'modify_dms_workflow_templates',
  CREATE_DMS_STATUS: 'create_dms_statuses',
  MODIFY_DMS_STATUS: 'modify_dms_statuses',
  CREATE_DMS_CUSTOM_FIELDS: 'create_dms_custom_fields',
  MODIFY_DMS_CUSTOM_FIELDS: 'modify_dms_custom_fields',
  VIEW_TRANSMITTAL: 'view_transmittals',
};

export const document_status_keys = ['pending_review', 'reviewed', 'approved', 'approved_with_comments', 'revise_resubmit', 'rejected'];

export function get_transmittal_status() {
  const { $t } = useCommonImports();

  const TRANSMITTAL_STATUS = {
    pending: {
      title: $t('Pending'),
      color: 'yellow',
      class: '!bg-gray-500',
    },
    pending_review: {
      title: $t('Pending review'),
      color: 'gray',
      class: '!bg-gray-500',
    },
    waiting_for_submission: {
      title: $t('Waiting for submission'),
      color: 'yellow',
    },
    pending_upload: {
      title: $t('Pending upload'),
      color: '',
      class: '!bg-gray-500',
    },
    waiting_for_review: {
      title: $t('Waiting for review'),
      color: 'yellow',
      class: '!bg-warning-600',
    },
    waiting_for_other_transmittal: {
      title: $t('Waiting'),
      color: 'gray',
    },
    waiting_for_upload: {
      title: $t('Waiting for upload'),
      color: 'yellow',
      class: '!bg-warning-600',
    },
    request_for_review: {
      title: $t('Request for review'),
      color: 'purple',
    },
    revise_resubmit: {
      title: $t('Revise & Resubmit'),
      color: 'orange',
      class: '!bg-orange-400',
    },
    cancelled: {
      title: $t('Cancelled'),
      color: 'gray',
    },
    completed: {
      title: $t('Completed'),
      color: 'green',
    },
    approved: {
      title: $t('Approved'),
      color: 'green',
      class: '!bg-green-600',

    },
    approved_with_comments: {
      title: $t('Approved with comments'),
      color: 'lightgreen',
      class: '!bg-green-400',
    },
    rejected: {
      title: $t('Rejected'),
      color: 'red',
      class: '!bg-red-500',
    },
    reviewed: {
      title: $t('Reviewed'),
      color: 'blue',
      class: '!bg-blue-600',
    },
    submitted: {
      title: $t('Submitted'),
      color: 'green',
      class: '!bg-green-600',
    },
    not_submitted: {
      title: $t('Not submitted'),
      color: 'gray',
    },
  };

  return { TRANSMITTAL_STATUS };
}
