<script setup>
import { useRoute } from 'vue-router';
import { useModal } from 'vue-final-modal';
import { useCommonStore } from '~/common/stores/common.store.js';
import HawkShare from '~/common/components/organisms/hawk-share.vue';
import { useFormPermissions } from '~/forms/composables/form-permissions.composable';
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store.js';

defineProps({
  is_share_loading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['close']);

const route = useRoute();
const $t = inject('$t');
const $track_event = inject('$track_event');
const $toast = inject('$toast');
const { share_access_levels } = useFormPermissions();
const form_template_detail_store = useFormTemplateDetailStore();
const common_store = useCommonStore();

const state = reactive({
  is_loading: false,
});

const members_teams = computed(() => {
  if (form_template_detail_store?.form_template_detail?.members?.length || form_template_detail_store?.form_template_detail?.teams?.length)
    return ([...(form_template_detail_store.form_template_detail.members || []), ...(form_template_detail_store?.form_template_detail?.teams || [])]).map(item => item?.uid) || [];
});

function openShareModal() {
  const { open: openShareModal, close: closeShareModal } = useModal({
    component: HawkShare,
    attrs: {
      onClose() {
        closeShareModal();
      },
      is_modal: true,
      access_levels: share_access_levels,
      members: common_store.filter_users(form_template_detail_store?.form_template_detail?.members),
      teams: common_store.filter_teams(form_template_detail_store?.form_template_detail?.members),
      get_share_data(data) {
        onShare(data);
      },
    },
  });
  openShareModal();
}

function formatSharedData(data, obj) {
  data?.length && data?.forEach((item) => {
    obj[`${item.uid}`] = `${item?.access}`;
  });
  return obj;
}

async function onShare(data) {
  try {
    state.is_loading = true;
    const load_data = {};
    load_data.members = formatSharedData(data?.users, {});
    load_data.teams = formatSharedData(data?.teams, {});

    const payload = { body: { ...load_data } };

    await form_template_detail_store.update_form_details(payload, $t);
    const team_or_user = data?.teams?.length ? 'Team' : 'User';
    $track_event('form_template_shared', {
      type: (data?.users?.length && data?.teams?.length) ? 'Both' : team_or_user,
    });
    await form_template_detail_store.set_form_template({ id: route.params.template_uid });
    state.is_loading = false;
  }
  catch (error) {
    state.is_loading = false;
    logger.log('🚀 ~ onShare ~ error:', error);
  }
}
</script>

<template>
  <HawkLoader v-if="state.is_loading || is_share_loading" container_class="m-0" :width="5" />
  <div v-else class="cursor-pointer" @click="openShareModal">
    <div v-if="members_teams?.length">
      <HawkMembers :members="members_teams" type="group" has_border has_tooltip :max_badges_to_display="4" />
    </div>
    <HawkButton v-else type="outlined">
      <IconHawkShareSeven />
      {{ $t("Share") }}
    </HawkButton>
  </div>
</template>
