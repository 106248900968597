<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { cloneDeep, debounce, has, isArray, isEqual, lowerCase, uniq } from 'lodash-es';
import { useRoute } from 'vue-router';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { useCommonStore } from '~/common/stores/common.store.js';
import { useTagsStore } from '~/common/stores/tags.store';
import { getUserFullName, sortData } from '~/common/utils/common.utils.js';
import { useFamConstants } from '~/forms-as-module/composables/fam-constants.composable.js';

const props = defineProps({
  trigger_label: { type: String },
  name: { type: String, required: true },
  uid: { type: String, required: true },
  data_type: { type: String },
  option_type: { type: String },
  default_value: { type: Object, default: () => {} },
  default_operator: { type: String },
  label_prop: { type: String, default: 'name' },
  value_prop: { type: String, default: 'uid' },
  value_format: { type: String, default: 'string' },
  track_key: { type: String, default: 'name' },
  search: { type: Boolean, default: true },
  options: { type: Array, default: () => {} },
  autocomplete_options: { type: Array, default: () => {} },
  is_blank_option: { type: Boolean, default: true },
  operators: { type: Array, default: () => [] },
  can_clear: { type: Boolean, default: false },
  close_on_select: { type: Boolean, default: false },
  search_placeholder: { type: String },
  input_placeholder: { type: String, default: '' },
  filter_resetting: {},
  has_vueform_wrapper: { type: Boolean, default: false },
  quick_filters: { type: Boolean, default: true },
  field: { type: Object, default: () => {} },
  custom_date_filter_options: { type: Object, default: null },
  isAutoApplyFilters: { type: Boolean, default: true },
});
const emit = defineEmits(['apply', 'select', 'updateForm', 'updateOperator']);
const { $t } = useCommonImports();
const common_store = useCommonStore();
const tags_store = useTagsStore();
const route = useRoute();

const { DATE_FILTER_OPTIONS, getFormattedDate } = useFamConstants($t);

const DATE_FILTERS = props.custom_date_filter_options || DATE_FILTER_OPTIONS;

const form$ = ref(null);

const selected_value = reactive({
  value: '',
  operator: '',
  operator_option: '',
});
const state = reactive({
  search_key: '',
});

const show_count = computed(() =>
  !['boolean', 'date', 'date_time', 'number', 'text', 'autocomplete']
    .includes(props?.data_type)
    && !['count', 'blankNotBlank', 'isGreaterThan', 'isLessThan']
      .includes(props?.default_value?.operator) && props?.default_value?.value?.length);

const is_scrollbar = computed(() => (['single_select', 'single_value_select', 'multi_select', 'approvers'].includes(props?.data_type)
  && !['blankNotBlank', 'count', 'isGreaterThan', 'isLessThan']
    .includes(selected_value.operator)));
const input_type = computed(() => getInputType(selected_value?.operator));

const items = computed(() => {
  let items = [];
  if (props?.options?.length) {
    items = props.options;
  }
  else if (props?.option_type === 'members') {
    items = [
      ...(common_store?.scope_users(route?.params?.asset_id || null) || []),
      ...(common_store?.scope_teams(route?.params?.asset_id || null) || []),
    ];
  }
  else if (props?.option_type === 'categories') {
    items = common_store?.scope_categories(route?.params?.asset_id, 'list') || [];
  }
  else if (props?.option_type === 'tags') {
    items = tags_store.tags || [];
  }
  else if (props?.option_type === 'assets') {
    items = sortData(common_store?.assets, 'name', ['asc'], true);
  }
  if (props.quick_filters && props.is_blank_option) {
    return [...items, { uid: 'null', name: 'Blank' }];
  }
  return items;
});
const filtered_items = computed(() => {
  if (state?.search_key) {
    return items.value.filter((item) => {
      return getUserFullName(item)?.toLowerCase().includes(state.search_key.toLowerCase());
    });
  }
  return items.value;
});
const has_filter_applied = computed(() => {
  const value = props.default_value?.value ?? '';
  return isArray(value) ? value.length : String(value).length;
});

function getOperatorName(operator) {
  return operator === 'blankNotBlank' ? $t('blank/not blank') : $t(lowerCase(operator));
}

function getCustomMembers() {
  const members = [];
  const teams = [];

  const should_extract_member = props.field?.config?.allowed_members?.extract_member;

  for (const member_data of (props.field?.config?.allowed_members?.members || [])) {
    if (member_data.member) {
      members.push(member_data.uid);
    }
    else if (should_extract_member && !member_data.member) {
      const team_users = common_store.get_team(member_data.uid).users;
      teams.push(...team_users);
    }
    else {
      teams.push(member_data.uid);
    }
  }
  const custom_options = uniq([...members, ...teams]);
  const has_custom_options = !!props.field?.config?.allowed_members?.members.length;

  return (has_custom_options ? { custom_options, has_custom_options } : {});
}

function onOpen() {
  selected_value.operator = props.default_value?.operator || props.default_operator || props.operators?.[0];
  selected_value.value = cloneDeep(props.default_value?.value);
  selected_value.operator_option = props.default_value?.operator_option || null;

  if (form$.value?.update) {
    form$.value?.update({
      operator_option: props.default_value?.operator_option,
      ...(has(form$.value?.data, 'value') ? { value: selected_value.value } : {}),
      ...(has(form$.value?.data, 'value_1') ? { value_1: selected_value.value?.[0] } : {}),
      ...(has(form$.value?.data, 'value_2') ? { value_2: selected_value.value?.[1] } : {}),
    });
  }
}

function onClear(close, close_on_clear) {
  selected_value.value = cloneDeep(null);
  if (close_on_clear)
    onApply(close);
}

function getInputType(operator) {
  const data_type = props?.data_type;
  if (['text'].includes(data_type) && ['contains', 'doesNotContain', 'isEqualTo', 'isNotEqualTo', 'startsWith', 'endsWith'].includes(operator)) {
    return 'text-element';
  }
  else if (['autocomplete'].includes(data_type) && ['contains', 'doesNotContain', 'isEqualTo', 'isNotEqualTo', 'startsWith', 'endsWith'].includes(operator)) {
    return 'autocomplete-element';
  }

  else if (['boolean', 'attachments'].includes(data_type) && ['isEqualTo', 'blankNotBlank'].includes(operator)) {
    return 'radio-group-element';
  }

  else if (['text', 'single_select', 'multi_select', 'date'].includes(data_type) && operator === 'blankNotBlank') {
    return 'radio-group-element-with-blank-not-blank';
  }

  else if (['number', 'multi_select', 'approvers'].includes(data_type) && ['count', 'isEqualTo', 'notEqualTo', 'isGreaterThan', 'isLessThan'].includes(operator)) {
    return 'number-element';
  }

  else if (['number', 'attachments'].includes(data_type) && ['isBetween', 'isNotBetween', 'countIsBetween'].includes(operator)) {
    return 'number-element-between';
  }

  else if (['single_select', 'multi_select', 'approvers'].includes(data_type)
    && ['isAnyOf', 'isNot', 'containsAnyOf', 'containsAllOf', 'doesNotContain', 'equalsExactly'].includes(operator)) {
    return 'multi-select-input';
  }
  else if (['single_value_select'].includes(data_type)
    && ['isAnyOf', 'isNot', 'containsAnyOf', 'containsAllOf', 'doesNotContain', 'equalsExactly'].includes(operator)) {
    return 'single-value-select';
  }

  else if (data_type === 'date'
    && [
      'isEqualTo',
      'isNotEqualTo',
      'isAfter',
      'isBefore',
      'between',
      'isNotBetween',
    ].includes(operator)) {
    return 'date-input';
  }
}

const auto_apply_filters = debounce(() => {
  if (props.isAutoApplyFilters)
    onApply();
}, 200);

function onSelectOperator(operator, close = () => {}) {
  if (getInputType(operator) !== getInputType(selected_value.operator))
    selected_value.value = null;
  selected_value.operator = operator;
  selected_value.operator_option = null;
  if (form$.value?.update)
    form$.value.update({ operator_option: null });
  else
    emit('updateOperator', selected_value);
  close?.();
  selected_value.value?.length && auto_apply_filters();
}

function onApply(close) {
  emit('apply', {
    field: props.uid,
    value: cloneDeep(selected_value.value),
    operator: selected_value.operator,
    operator_option: selected_value.operator_option,
  });

  close?.();
}
function onSelectItem(newItem, close) {
  selected_value.value = isArray(selected_value?.value) ? selected_value.value : [];
  const index = selected_value.value.indexOf(newItem.uid);
  if (index !== -1) {
    selected_value.value.splice(index, 1);
  }
  else if (input_type.value === 'multi-select-input') {
    selected_value.value.push(newItem.uid);
  }
  else if (input_type.value === 'single-value-select') {
    if (selected_value.value.length && selected_value.value[0] === newItem.uid)
      selected_value.value = [];
    else selected_value.value = [newItem.uid];
  }
  selected_value.value = [...selected_value.value];
  if (props.close_on_select)
    onApply(close);
}

function onClose() {
  selected_value.value = null;
  state.search_key = '';
}

watch(() => props.filter_resetting, () => {
  onOpen();
});

function setSelectedValue(new_value, options = {}) {
  if (
    (input_type.value === 'date-input'
      && !options.date_custom_value
      && ['exact_date', 'custom_range'].includes(selected_value.operator_option))
      || isEqual(new_value, selected_value.value)
  ) {
    selected_value.value = null;
    return;
  }

  if (input_type.value === 'multi-select-input' || input_type.value === 'single-value-select') {
    onSelectItem(new_value, options.close);
  }
  else if (input_type.value === 'number-element-between') {
    selected_value.value = selected_value.value || [];
    selected_value.value[options.is_number_range_first_value ? 0 : 1] = Number(new_value);
  }
  else if (input_type.value === 'number-element') {
    selected_value.value = Number(new_value);
  }
  else {
    selected_value.value = new_value;
  }

  auto_apply_filters();
}
onMounted(() => {
  onOpen();
});
</script>

<template>
  <div v-if="quick_filters" class="quick-filters">
    <HawkMenu
      additional_trigger_classes="!ring-0 !focus:ring-0"
      :items="[]"
      position="fixed"
      @open="onOpen"
      @close="onClose"
    >
      <template #trigger="{ open, close }">
        <slot name="trigger-label">
          <HawkButton type="outlined" :color="has_filter_applied ? 'active' : 'gray'" size="xs" class="!px-2 !py-0 focus:!ring-0">
            <div class="text-sm font-semibold">
              <slot name="trigger_label">
                <div>
                  {{ trigger_label || name }}
                  <span v-if="show_count">
                    ({{ default_value?.value?.length }})
                  </span>
                </div>
              </slot>
            </div>
            <div v-if="can_clear && has_filter_applied" @click.stop="onTriggerClearAll(close)">
              <IconHawkX />
            </div>
            <div v-else>
              <IconHawkChevronUp v-if="open" />
              <IconHawkChevronDown v-else />
            </div>
          </HawkButton>
        </slot>
      </template>
      <template #content="{ close }">
        <div class="w-[356px] px-2 grid py-1">
          <div>
            <HawkMenu
              additional_trigger_classes="!ring-0 !focus:ring-0"
              :items="operators"
              position="fixed"
            >
              <template #trigger="{ open }">
                <div class="p-2 w-full flex items-center flex-1 gap-2 text-sm font-semibold">
                  {{ getOperatorName(selected_value.operator) }}
                  <template v-if="operators?.length > 1">
                    <IconHawkChevronUp v-if="open" />
                    <IconHawkChevronDown v-else />
                  </template>
                </div>
              </template>
              <template #content="{ close: closeOperatorMenu }">
                <div class="bg-white p-1 rounded-lg">
                  <div
                    v-for="operator in operators" :key="operator"
                    class="px-3 h py-3 text-sm font-medium min-w-[10rem] rounded-lg cursor-pointer text-gray-700 hover:bg-gray-50 flex items-center"
                    @click.stop="onSelectOperator(operator, closeOperatorMenu)"
                  >
                    {{ getOperatorName(operator) }}
                    <IconHawkCheck v-if="selected_value.operator === operator" class="ml-auto text-primary-500 w-5 h-5 min-w-5" />
                  </div>
                </div>
              </template>
            </HawkMenu>
          </div>
          <div
            v-if="search && is_scrollbar"
            class="mb-1"
          >
            <HawkSearchInput
              :placeholder="search_placeholder"
              full_width
              @keydown.space.stop
              @update:model-value="e => state.search_key = e"
            />
          </div>
          <div class="overflow-x-hidden scrollbar" :class="{ 'h-64': is_scrollbar }">
            <Vueform ref="form$" size="sm">
              <div class="py-2 col-span-12 grid gap-1" :class="{ relative: data_type !== 'date' }">
                <TextElement
                  v-if="input_type === 'text-element'"
                  name="value"
                  :placeholder="input_placeholder || $t('Enter value')"
                  :default="selected_value.value"
                  class="pb-2 px-2"
                  autocomplete="off"
                  @change="value => setSelectedValue(value)"
                  @keydown.space.stop
                />
                <HawkAutocompleteElement
                  v-else-if="input_type === 'autocomplete-element'"
                  :placeholder="input_placeholder || $t('Enter value')"
                  :options="{
                    default: selected_value.value,
                    value: form$?.data?.value,
                  }"
                  :autocomplete_options="autocomplete_options"
                  class="px-2"
                  @keydown.space.stop
                  @change="setSelectedValue($event)"
                  @on-enter="form$?.update({ ...form$.data, value: $event });"
                  @on-click="form$?.update({ ...form$.data, value: $event });"
                />
                <RadiogroupElement
                  v-else-if="input_type === 'radio-group-element'"
                  name="value"
                  view="tabs"
                  :default="selected_value.value"
                  class="px-2"
                  :items="options?.length ? options : [
                    { value: true, label: $t('Yes') },
                    { value: false, label: $t('No') }]"
                  @change="value => setSelectedValue(value)"
                />
                <RadiogroupElement
                  v-else-if="input_type === 'radio-group-element-with-blank-not-blank'"
                  view="tabs"
                  name="value"
                  :default="selected_value.value"
                  class="px-2"
                  :items="[
                    { value: true, label: $t('Blank') },
                    { value: false, label: $t('Not blank') }]"
                  @change="value => setSelectedValue(value)"
                />
                <TextElement
                  v-else-if="input_type === 'number-element'"
                  autocomplete="off"
                  name="value"
                  input-type="number"
                  :placeholder="$t('Enter value')"
                  class="pb-2 px-2"
                  :default="selected_value.value"
                  @change="value => setSelectedValue(value)"
                />
                <template
                  v-else-if="input_type === 'number-element-between'"
                >
                  <TextElement
                    autocomplete="off"
                    name="value_1"
                    input-type="number"
                    class="w-full px-2"
                    :default="selected_value.value?.[0]"
                    :placeholder="input_placeholder || $t('Enter value')"
                    @change="value => setSelectedValue(value, { is_number_range_first_value: true })"
                  />
                  <TextElement
                    autocomplete="off"
                    name="value_2"
                    input-type="number"
                    class="w-full px-2"
                    :default="selected_value.value?.[1]"
                    :placeholder="input_placeholder || $t('Enter value')"
                    @change="value => setSelectedValue(value)"
                  />
                </template>

                <div
                  v-else-if="input_type === 'multi-select-input'"
                >
                  <div
                    v-for="item in filtered_items" :key="`${item.name}_${filtered_items?.length || 0}`"
                    @click.stop="setSelectedValue(item, { close })"
                  >
                    <slot name="item" :item="item">
                      <div
                        class="w-full py-2.5 px-2 flex items-center hover:bg-gray-50 rounded-lg gap-2 cursor-pointer"
                      >
                        <HawkCheckbox :model-value="selected_value?.value?.includes(item.uid) ? 'checked' : 'unchecked'" tristate>
                          <div class="flex items-center gap-2">
                            <template v-if="option_type === 'members' || option_type === 'member'">
                              <HawkMembers v-if="item.uid !== 'null'" :members="item.uid" size="xs" type="label" />
                              <span v-else class="text-sm text-gray-500 break-word">{{ item.name }}</span>
                            </template>
                            <span v-else class="text-sm text-gray-700 break-word">{{ item.name }}</span>
                          </div>
                        </HawkCheckbox>
                      </div>
                    </slot>
                  </div>
                </div>
                <div
                  v-else-if="input_type === 'single-value-select'"
                >
                  <div
                    v-for="item in filtered_items" :key="item.name"
                    @click.stop="setSelectedValue(item, { close })"
                  >
                    <slot name="item" :item="item">
                      <div
                        class="w-full py-2.5 px-2 flex items-center hover:bg-gray-50 rounded-lg gap-2 cursor-pointer"
                        :class="{ 'bg-gray-100': selected_value?.value?.includes(item.uid) }"
                      >
                        <div class="flex items-center gap-2 justify-between w-full">
                          <template v-if="option_type === 'members' || option_type === 'member'">
                            <HawkMembers v-if="item.uid !== 'null'" :members="item.uid" size="xs" type="label" />
                            <span v-else class="text-sm text-gray-500 break-word">{{ item.name }}</span>
                          </template>
                          <span v-else class="text-sm text-gray-700 break-all">{{ item.name }}</span>
                          <div v-if="selected_value?.value?.includes(item.uid)">
                            <IconHawkCheck class="w-4 h-4 text-blue-500" />
                          </div>
                        </div>
                      </div>
                    </slot>
                  </div>
                </div>

                <template
                  v-else-if="input_type === 'date-input'"
                >
                  <SelectElement
                    name="operator_option"
                    :native="false"
                    :placeholder="$t('Select option')"
                    :items="DATE_FILTERS?.[selected_value.operator]"
                    :can-deselect="false"
                    :default="default_value?.operator_option"
                    class="absolute w-full pr-4"
                    :search="true"
                    track-by="label"
                    @change="value => {
                      selected_value.operator_option = value;
                      setSelectedValue(getFormattedDate(selected_value.operator_option));
                    }"
                  />
                  <div class="mt-12">
                    <DateTimeElement
                      v-if="selected_value.operator_option === 'exact_date'"
                      name="value"
                      :placeholder="$t('Select date')"
                      :options="{
                        inline: true,
                      }"
                      :default="selected_value.value"
                      class="mb-2"
                      @change="value => setSelectedValue(value, { date_custom_value: true })"
                    />
                    <DateTimeElement
                      v-else-if="selected_value.operator_option === 'custom_range'"
                      name="value"
                      :options="{
                        inline: true,
                        placeholder: 'Inline',
                        range: true,
                      }"
                      :default="selected_value.value"
                      class="mb-2"
                      @change="value => setSelectedValue(value, { date_custom_value: true })"
                    />
                  </div>
                </template>
              </div>
            </Vueform>
          </div>
          <div v-if="!close_on_select">
            <hr>
            <div class="flex items-center justify-between text-sm font-semibold">
              <div class="flex items-center">
                <div class="text-gray-600 cursor-pointer p-2" @click="onClear(close, true)">
                  {{ $t('Clear') }}
                </div>
              </div>
              <div class="text-primary-700 cursor-pointer p-2" @click="onApply(close)">
                {{ $t('Apply') }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </HawkMenu>
  </div>
  <div v-else class="w-full">
    <component :is="has_vueform_wrapper ? 'Vueform' : 'div'" ref="form$" size="sm" class="flex py-1.5">
      <div class="w-1/2">
        <SelectElement
          name="operator"
          :native="false"
          :placeholder="$t('Select option')"
          :items="operators.map(item => ({ value: item, label: getOperatorName(item) }))"
          :can-clear="false"
          :can-deselect="false"
          :default="selected_value.operator"
          class="pr-2"
          @select="value => onSelectOperator(value)"
        />
      </div>
      <div v-if="selected_value.operator" class="w-1/2">
        <TextElement
          v-if="
            ['text'].includes(data_type)
              && ['contains', 'doesNotContain', 'isEqualTo', 'isNotEqualTo', 'startsWith', 'endsWith'].includes(selected_value.operator)"
          name="value"
          :placeholder="input_placeholder || $t('Enter value')"
          :default="selected_value.value"
          @change="value => {
            selected_value.value = value;
            emit('updateForm', selected_value);
          }"
          @keydown.space.stop
        />
        <RadiogroupElement
          v-else-if="
            ['boolean', 'attachments'].includes(data_type)
              && ['isEqualTo', 'blankNotBlank'].includes(selected_value.operator)"
          name="value"
          view="tabs"
          :default="selected_value.value"
          :items="[
            { value: true, label: 'Yes' },
            { value: false, label: 'No' }]"
          @change="value => {
            selected_value.value = value;
            emit('updateForm', selected_value);
          }"
        />
        <RadiogroupElement
          v-else-if="
            ['text', 'single_select', 'multi_select', 'date'].includes(data_type)
              && selected_value.operator === 'blankNotBlank'"
          view="tabs"
          name="value"
          :default="selected_value.value"
          :items="[
            { value: true, label: 'Blank' },
            { value: false, label: 'Not blank' }]"
          @change="value => {
            selected_value.value = value;
            emit('updateForm', selected_value);
          }"
        />

        <TextElement
          v-else-if="
            ['number', 'multi_select', 'approvers'].includes(data_type)
              && ['count', 'isEqualTo', 'notEqualTo', 'isGreaterThan', 'isLessThan'].includes(selected_value.operator)"
          name="value"
          input-type="number"
          placeholder="Enter value"
          :default="selected_value.value"
          @change="value => {
            selected_value.value = value;
            emit('updateForm', selected_value);
          }"
        />
        <template
          v-if="['number', 'attachments'].includes(data_type)
            && ['isBetween', 'isNotBetween', 'countIsBetween'].includes(selected_value.operator)"
        >
          <ListElement
            name="value"
            :controls="{ add: false, remove: false, sort: false }"
            :add-classes="{
              ListElement: {
                list: '!flex gap-2',
                listItem: 'w-full',
              },
            }"
            :default="[null, null]"
            @change="(value) => {
              selected_value.value = [value[0] ? Number(value[0]) : null, value[1] ? Number(value[1]) : null];
              emit('updateForm', selected_value);
            }"
          >
            <template #default="{ index }">
              <TextElement
                :name="index"
                :placeholder="index ? 'Max' : 'Min'"
                input-type="number"
                :rules="getRules"
                autocomplete="off"
              />
            </template>
          </ListElement>
        </template>
        <template
          v-if="['single_select', 'multi_select', 'approvers'].includes(data_type)
            && ['isAnyOf',
                'isNot',
                'containsAnyOf',
                'containsAllOf',
                'doesNotContain',
                'equalsExactly',
            ].includes(selected_value.operator)"
        >
          <hawk-assignee-input
            v-if="option_type === 'members' || option_type === 'member'"
            :asset_id="route.params.asset_id"
            :truncate_tag_length="8"
            :multi="true"
            :options="{
              'name': 'value',
              'has_teams': true,
              'placeholder': $t('Select members'),
              'object': false,
              'can-clear': true,
            }"
            v-bind="getCustomMembers()"
            @change="value => {
              selected_value.value = value;
              emit('updateForm', selected_value);
            }"
          />

          <hawk-category-input
            v-else-if="option_type === 'categories'"
            :options="{
              'name': 'value',
              'placeholder': $t('Select Category'),
              'can-clear': true,
              'tags_removable': true,
              'close-on-select': false,
            }"
            :multi="true"
            @change="value => {
              selected_value.value = value;
              emit('updateForm', selected_value);
            }"
          />
          <hawk-tags-input
            v-else-if="option_type === 'tags'"
            :options="{
              'name': 'value',
              'placeholder': $t('Select Tags'),
              'can-clear': true,
              'close-on-select': false,
            }"
            @change="value => {
              selected_value.value = value;
              emit('updateForm', selected_value);
            }"
          />
          <TagsElement
            v-else
            name="value"
            :items="filtered_items"
            :native="false"
            :search="true"
            :tags_removable="true"
            :can-clear="false"
            :close-on-select="false"
            :placeholder="$t('Select')"
            track-by="name"
            label-prop="name"
            value-prop="uid"
            @change="value => {
              selected_value.value = value;
              emit('updateForm', selected_value);
            }"
          />
        </template>

        <div
          v-if="data_type === 'date'
            && [
              'isEqualTo',
              'isNotEqualTo',
              'isAfter',
              'isBefore',
              'between',
              'isNotBetween',
            ].includes(selected_value.operator)
          "
          class="flex gap-2"
        >
          <div class="w-full">
            <SelectElement
              name="operator_option"
              :native="false"
              :placeholder="$t('Select option')"
              :items="DATE_FILTERS?.[selected_value.operator]"
              :can-deselect="false"
              :default="default_value?.operator_option"
              :search="true"
              track-by="label"
              @change="value => {
                selected_value.operator_option = value;
                selected_value.value = getFormattedDate(selected_value.operator_option);
                emit('updateForm', selected_value);
              }"
            />
          </div>
          <div v-if="['custom_range', 'exact_date'].includes(selected_value.operator_option)" class="w-full">
            <DateTimeElement
              :key="`${uid}_${selected_value.operator}`"
              name="value"
              :options="{
                'v-model': selected_value.value,
                'range': ['isNotBetween', 'between'].includes(selected_value.operator),
                'format': field?.config?.type === 'time' ? 'h:mm a' : `dd-MMM-yyyy${field?.config?.type === 'datetime' ? ' h:mm a' : ''}`,
                'placeholder': $t('Select date'),
                'enableTimePicker': field?.config?.type === 'datetime',
                'time-picker': field?.config?.type === 'time',
                'is-24': false,
                ...(field?.config?.type === 'time' ? { 'model-type': 'k:mm' } : { utc: true }),
              }"
              @update:model-value="value => {
                selected_value.value = value;
                emit('updateForm', selected_value);
              }"
            />
          </div>
        </div>
      </div>
    </component>
  </div>
</template>

<style lang="scss">
.quick-filters{
  .dp--menu-wrapper {
    z-index: 1;
  }
}
</style>
