<script setup>
import { onMounted } from 'vue';
import { useModal } from 'vue-final-modal';
import HawkModalTemplate from '~/common/components/hawk-modal/hawk-modal-template.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import FormBuilderHeader from '~/forms/components/form-builder/form-builder-header.vue';
import WorkflowSidebar from '~/forms/components/form-workflows/sidebar/form-workflow-sidebar.vue';
import WorkflowGraph from '~/forms/components/form-workflows/workflow-graph.vue';
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store';
import { useFormWorkflowStore } from '~/forms/store/form-workflow.store';

const form_template_detail_store = useFormTemplateDetailStore();
const form_workflow_store = useFormWorkflowStore();
const { $t, route, router } = useCommonImports();

provide('form_template_detail_store', form_template_detail_store);

const is_loading = ref(false);
const is_template_loading = ref(true);

async function getData() {
  if (route.params.template_uid) {
    is_template_loading.value = true;
    await form_template_detail_store.set_form_template({ id: route.params.template_uid });
    is_template_loading.value = false;
  }
}

onMounted(async () => {
  await getData();
});

watch(() => route.params.asset_id, (val, old_val) => {
  if (val && old_val && val !== old_val)
    router.push({ name: 'forms-templates', params: { asset_id: route.params.asset_id }, query: { active: 'published' } });
});

watch(() => route.params.template_uid, () => {
  getData();
});

const form = computed(() => form_template_detail_store?.form_template_detail);

const normal_modal = useModal({
  component: HawkModalTemplate,
  attrs: {
    footer_buttons: [
      { color: 'primary', type: 'outlined', text: $t('Cancel'), class: 'mr-3' },
      { color: 'primary', text: $t('Proceed'), emit: 'proceed' },
    ],
    onClose() {
      normal_modal.close();
    },
    async onProceed() {
      is_loading.value = true;
      try {
        await form_workflow_store.createFormWorkflow(form.value.uid);
        await form_template_detail_store.set_form_template({
          id: form.value.uid,
        });
      }
      catch (err) {
        logger.log(err);
      }
      is_loading.value = false;
      normal_modal.close();
    },
  },
  slots: {
    title_text: $t('Form Workflow'),
    default: $t('Create a workflow for this form?'),
  },
});
</script>

<template>
  <div class="justify-center py-4 sm:px-6 lg:px-4">
    <hawk-loader v-if="is_template_loading" />
    <div v-else class="flex min-h-full flex-col">
      <div class="min-h-full">
        <FormBuilderHeader class="mb-3" :is_sticky="true" />
        <div class="h-[calc(100vh-155px)]  rounded-lg">
          <div v-if="is_loading" class="absolute h-[calc(100vh-155px)] w-[calc(100vw-32px)] z-50 bg-gray-100 opacity-70 flex justify-center items-center">
            <HawkLoader class="!m-0" />
          </div>

          <div v-if="form.workflow" class="h-full w-full">
            <WorkflowGraph :form="form" @show-loader="is_loading = true" @hide-loader="is_loading = false" />
            <div class="relative">
              <WorkflowSidebar :is_disabled="form.status === 'published'" />
            </div>
          </div>
          <div v-else class="flex justify-center items-center h-full">
            <div class="text-center">
              <div class="flex justify-center mb-[25px]">
                <div class="w-12 h-12 rounded-full flex justify-center items-center ring-8 mb-2 bg-primary-100 ring-primary-50">
                  <IconHawkDataflowOne class="text-center text-lg w-7 h-7 [&>*]:!stroke-primary-600" />
                </div>
              </div>
              <div class="text-[18px] font-semibold">
                {{ $t('Workflow template') }}
              </div>
              <div class="text-[14px] mb-[23px]">
                {{ $t('Build Workflow template for automating your daily tasks in one go') }}
              </div>
              <div class="flex items-center justify-center mt-5">
                <HawkButton class="mr-5" @click="normal_modal.open()">
                  {{ $t('Get Started') }}
                </HawkButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
