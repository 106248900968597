import { defineAsyncComponent } from 'vue';
import Assets from '~/assets/pages/assets.vue';
import HawkLoader from '~/common/components/atoms/hawk-loader.vue';

const AssetForm = defineAsyncComponent({
  loader: () => import('~/assets/pages/asset-form.vue'),
  loadingComponent: HawkLoader,
});
const ViewsList = defineAsyncComponent({
  loader: () => import('~/assets/pages/asset-therm-terra-views-list.vue'),
  loadingComponent: HawkLoader,
});
const MapView = defineAsyncComponent({
  loader: () => import('~/assets/pages/assets-map-view.vue'),
  loadingComponent: HawkLoader,
});

const routes = [
  {
    path: '/',
    name: 'home',
    component: Assets,
    meta: { title: 'Home' },
  },
  {
    path: '/create-asset',
    name: 'create-asset',
    component: AssetForm,
    meta: { title: 'Create asset' },
  },
  {
    path: '/:asset_id/views-list/:type',
    name: 'views-list',
    component: ViewsList,
    meta: { title: 'List' },
  },
  {
    path: '/:asset_id/maps-list',
    name: 'maps-list',
    component: MapView,
    meta: { title: 'Map View' },
  },
];
export default routes;
