<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { isString, pick } from 'lodash-es';
import { useModal } from 'vue-final-modal';
import { useRoute, useRouter } from 'vue-router';
import { useAuthStore } from '~/auth/stores/auth.store';
import TaskForm from '~/tasks/components/molecule/task-form/task-form.vue';
import { useTasksStore } from '~/tasks/store/tasks.store';
import { useTerraStore } from '~/terra/store/terra.store.js';

const props = defineProps({
  marker_location: {
    type: Object,
    default: () => null,
  },
  project_details_for_task_form_creation: {
    type: Object,
    default: () => null,
  },
  opened_from: {
    type: String,
    default: 'Quick action',
  },
});
const emit = defineEmits(['close']);
const $services = inject('$services');
const terra_store = useTerraStore();
const tasks_store = useTasksStore('terra_task_store');
const template_store = useTasksStore('template');
const auth_store = useAuthStore();
const router = useRouter();
const route = useRoute();
const { open: openTaskForm, close: closeTaskForm } = useModal({
  component: TaskForm,
  attrs: {
    task_type: 'task',
    prefix_feature_name: !!terra_store.selected_features.length,
    is_name_required: true,
    onClose() {
      closeTaskForm();
    },
    on_cancel() {
      emit('close');
    },
    async on_submit(data) {
      try {
        let payload = [];
        let elements;
        let response = [];
        const turf = (await import('@turf/turf'));
        const location = props.marker_location
          ? turf.centroid({
            type: 'Point',
            coordinates: Object.values(props.marker_location),
          })
          : null;
        const event_properties = {
          method: props.opened_from,
          linked_to_vector: Boolean(terra_store.selected_features?.length),
        };

        if (!terra_store.selected_features.length) {
          payload = [{
            ...data,
            properties: {
              projectUid:
              props.project_details_for_task_form_creation?.project_details?.uid,
            },
            organization: auth_store.current_organization?.uid,

            target_element: props.project_details_for_task_form_creation?.target_element,
            location: location
              ? {
                  type: 'Point',
                  coordinates: location.geometry?.coordinates,
                }
              : null,
          }];
          response = await tasks_store.create_tasks({ tasks: payload }, event_properties);
        }
        else {
          let features = terra_store.selected_features;
          if (props.project_details_for_task_form_creation)
            features = terra_store.selected_features.filter(item => item.properties.uid === props.project_details_for_task_form_creation.feature_details?.uid);

          const non_created_elements = features.reduce((acc, item) => {
            if (isString(item.properties.element))
              acc.push(item.properties.uid);

            return acc;
          }, []);

          if (non_created_elements.length) {
            const response = await $services.terra_view_service.get_elements({
              body: {
                uids: non_created_elements,
              },
            });

            elements = response.data;
          }
          payload = features.map((feature) => {
            const element_object = elements?.[feature.properties.uid] ?? feature.properties.element;

            const element = pick(element_object, ['uid', 'type', 'asset', 'stage']);
            logger.log('🚀 ~ file: terra-task-form.vue:81 ~ payload=terra_store.selected_features.map ~ element:', element);
            let centroid;
            if (location) {
              centroid = {
                geometry: {
                  coordinates: location.geometry?.coordinates,
                },
              };
            }

            else {
              centroid = turf.centroid(feature);
            }
            logger.log(centroid);
            const task_name = data.template_uid && !data.name ? template_store.tasks_map[data.template_uid]?.name : data.name;
            let name = task_name || feature.properties?.name || '';
            if (data.prefix_feature_name)
              name = [feature.properties?.name || '', task_name].join(' ');
            const body = {
              ...data,
              target_element: element,
              name,
              properties: {
                ...((feature.properties.name || feature.name) && {
                  reference_name: String(feature.properties.name || feature.name),
                }),

                projectUid: props.project_details_for_task_form_creation?.project_details?.uid || feature.properties.project,

              },
              organization: auth_store.current_organization?.uid,
              location: centroid
                ? {
                    type: 'Point',
                    coordinates: centroid.geometry.coordinates,
                  }
                : null,

            };
            logger.log(body);
            return body;
          });
          response = await tasks_store.create_tasks({ tasks: payload }, event_properties);
        }

        if (Object.keys(response || {}).length === 1) {
          router.push({
            ...route,
            query: {
              task: btoa(JSON.stringify({
                id: Object.keys(response || {})[0],
                store_key: 'terra_task_store',
              })),
            },
          });
        }

        terra_store.selected_features = terra_store.selected_features.map((f) => {
          let element_object = f.properties.element;
          if (Object.keys(elements || {}).length)
            element_object = elements?.[f.properties.uid] ?? (f.properties.element);

          f.properties.element = element_object;
          return f;
        });

        const { is_active } = terra_store.gallery_view_state;
        terra_store.create_or_update_selected_features({
          clearSelectedFeatures: !is_active,
          updateFeatureRequest: false,
        });
      }
      catch (err) {
        logger.log(err);
      }
    },
  },

});

openTaskForm();
</script>

<template>
  <div />
</template>
