<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import DsmDtmDropdown from '~/terra/components/map-tools/dsm-dtm-dropdown.vue';
import { useTerraStore } from '~/terra/store/terra.store';
import { useTerraHelperComposable } from '~/terra/utils/helper-composable';

const terra_store = useTerraStore();
const $toast = inject('$toast');
const $services = inject('$services');
const $t = inject('$t');

const { getProjectReports } = useTerraHelperComposable();

const method_items = ref([
  {
    label: $t('Base height'),
    value: 'base-height',
  },
  {
    label: $t('Flat minimum'),
    value: 'flat-minimum',
  },
  {
    label: $t('Triangulated'),
    value: 'triangulated',
  },
  {
    label: $t('Surface to Surface'),
    value: 'surface-to-surface',
  },
]);

const volume_results = ref(null);
const form = ref({
  method: 'base-height',
  height: 10,
  layer: '',
  layer1: [],
  layer2: [],
});
const layer1 = ref(null);
const layer2 = ref(null);
const selected_bound = ref(null);
const is_loading_volume = ref(false);
const select_bound = ref(false);
const error = ref('');
const can_calculate_volume = computed(() => {
  return (
    terra_store.selected_features.length
    && terra_store.selected_features[0].geometry.type === 'Polygon'
  );
});
const selected_feature = computed(() => {
  if (terra_store.selected_features.length)
    return terra_store.selected_features[0];
  return null;
});
const options = computed(() => {
  return Object.values(terra_store.container.groups).map((group) => {
    Object.values(group.projects).forEach((project, index) => {
      project.children = [];
      const reports = terra_store.projects_details_reports_map[project.uid];
      if (reports) {
        if (reports?.dtm?.url)
          project.children.push(reports.dtm);
        if (reports?.dsm?.url)
          project.children.push(reports.dsm);
      }
      if (!project.children.length)
        delete group.projects[index];
    });
    group.children = Object.values(group.projects);
    return group;
  });
});

const reports = computed(() => getProjectReports(terra_store.last_selected_project));

watch(reports, () => {
  if (reports.value?.length)
    form.value.layer = reports.value[0].key;
}, { immediate: true });

function showToast(message) {
  $toast({
    text: message,
    type: 'warning',
  });
}
async function update_data_properties_volume(form, req_body, response) {
  if (!selected_feature.value)
    return;

  const feature = { ...selected_feature.value };

  if (!feature.properties.dataProperties)
    feature.properties.dataProperties = { };
  if (!feature.properties.dataProperties?.volume)
    feature.properties.dataProperties = { ...feature.properties.dataProperties, volume: {} };
  if (feature?.properties?.uid) {
    if (!feature.properties?.dataProperties?.volume_cal_history)
      feature.properties.dataProperties.volume_cal_history = [];
    feature.properties.dataProperties.volume_cal_history.push({
      ...form,
      ...response,
      date: new Date(),
    });
  }

  if (!feature.properties.dataProperties.volume[form.method])
    feature.properties.dataProperties.volume[form.method] = {};

  if (
    !feature.properties.dataProperties.volume[form.method][form.layer]
  ) {
    feature.properties.dataProperties.volume[form.method][
      form.layer
    ] = {
      value: null,
      options: {},
    };
  }

  feature.properties.dataProperties.volume[form.method][
    form.layer
  ].value = response;

  feature.properties.dataProperties.volume[form.method][
    form.layer
  ].options = req_body.options || {};
  // POST IT TO SERVER
  if (feature.properties.uid) {
    terra_store.selected_features[0] = feature;
    terra_store.create_or_update_selected_features({
      updateFeatureRequest: true,
    });
  }
}
function calculate_volume() {
  try {
    if (!can_calculate_volume.value)
      return;

    volume_results.value = null;
    error.value = '';
    let active_project = terra_store.last_selected_project;
    if (
      select_bound.value
      && selected_bound.value
      && selected_bound.value.properties.project_uid
    ) {
      active_project = terra_store.active_projects[
        selected_bound.value.properties.project_uid
      ].info;
    }

    const reports = terra_store.projects_details_reports_map[active_project.uid];

    if (!terra_store.selected_features.length) {
      showToast('Select a feature!');
      return;
    }

    if (
      form.value.method === 'base-height'
      || form.value.method === 'triangulated'
      || form.value.method === 'flat-minimum'
    ) {
      if (!active_project) {
        showToast('Select a project!');
        return;
      }
      if (!form.value.layer) {
        error.value = 'Select a Layer';
        return;
      }
      else if (!reports[form.value.layer].url) {
        error.value = 'Layer not found';
        return;
      }

      const body = {
        url: reports[form.value.layer].url,
        uid: reports[form.value.layer].uid,
        feature: terra_store.selected_features[0],
        options: {
          base_height: Number.parseInt(form.value.height) || 10,
        },
      };
      logger.log(body, 'body');
      is_loading_volume.value = true;
      if (form.value.method === 'base-height') {
        $services.gis_service.volume_base_height({ body })
          .then((res) => {
            is_loading_volume.value = false;
            volume_results.value = res.data.volumes;
            update_data_properties_volume(
              form.value,
              body,
              volume_results.value,
            );
          })
          .catch((err) => {
            is_loading_volume.value = false;
            logger.log('Err:: volume base height', err);
          });
      }
      else if (form.value.method === 'triangulated') {
        delete body.options;
        $services.gis_service.volume_triangulated({ body })
          .then((res) => {
            is_loading_volume.value = false;
            volume_results.value = res.data.volumes;
            update_data_properties_volume(
              form.value,
              body,
              res.data.volumes,
            );
          })
          .catch((err) => {
            is_loading_volume.value = false;
            logger.log('Err:: volume triangulated', err);
          });
      }
      else if (form.value.method === 'flat-minimum') {
        delete body.options;
        $services.gis_service.volume_flat_minimum({ body })
          .then((res) => {
            is_loading_volume.value = false;
            volume_results.value = res.data.volumes;
            update_data_properties_volume(
              form.value,
              body,
              res.data.volumes,
            );
          })
          .catch((err) => {
            is_loading_volume.value = false;
            logger.log('Err:: volume flat-minimum', err);
          });
      }
    }
    else if (form.value.method === 'surface-to-surface') {
      if (
        !layer1.value
        || !layer1.value.url
        || !layer2.value
        || !layer2.value.url
      ) {
        error.value = 'Please select both layers!';
        return;
      }
      const body = {
        feature: terra_store.selected_features[0],
      };

      body.url = layer1.value.url;
      body.uid = layer1.value.uid;
      body.options = {
        target_raster: {
          url: layer2.value.url,
          uid: layer2.value.uid,
        },
      };
      is_loading_volume.value = true;
      $services.gis_service.volume_surface_to_surface({ body })
        .then((res) => {
          is_loading_volume.value = false;
          volume_results.value = res.data.volumes;
          update_data_properties_volume(
            form.value,
            body,
            res.data.volumes,
          );
        })
        .catch((err) => {
          is_loading_volume.value = false;
          logger.log('Err:: volume surface to surface', err);
        });
      const uid = terra_store.selected_features[0]?.properties?.uid;
      terra_store.terra_track_events('Volume calculation', { uid });
    }
  }
  catch (err) {
    logger.log('Error:: while calculating volume', err);
  }
}
onMounted(() => {
  if (!can_calculate_volume.value) {
    $toast({
      text: $t('Select polygon for volume calculation'),
      type: 'info',
    });
  }
});
</script>

<template>
  <div class="p-3 w-80 bg-white rounded-lg">
    <div class="text-[16px] font-semibold mb-2">
      {{ $t('Volume Calculate') }}
    </div>
    <Vueform v-show="!volume_results" v-model="form" :sync="true" size="sm">
      <SelectElement
        name="method"
        :items="method_items"
        :native="false"
        :can-clear="false"
        autocomplete="off"
        :label="$t('Method')"
        :add-classes="{
          ElementLayout: {
            outerWrapper: '!block',
          },
          ElementLabel: {
            container: '!mb-2.5',
          },
        }"
      />
      <SelectElement
        v-show="form.method !== 'surface-to-surface'"
        name="layer"
        :items="reports"
        :native="false"
        :can-clear="false"
        autocomplete="off"
        :label="$t('Layer')"
        :add-classes="{
          ElementLayout: {
            outerWrapper: '!block',
          },
          ElementLabel: {
            container: '!mb-2.5',
          },
        }"
      />
      <DsmDtmDropdown
        v-show="form.method === 'surface-to-surface'"
        name="layer1"
        :label="$t('Layer 1')"
        :data="options"
        :value="form.layer1"
        @update-form="form.layer1 = $event"
        @input-value="layer1 = $event"
      />
      <DsmDtmDropdown
        v-show="form.method === 'surface-to-surface'"
        name="layer2"
        :label="$t('Layer 2')"
        :data="options"
        :value="form.layer2"
        @update-form="form.layer2 = $event"
        @input-value="layer2 = $event"
      />
      <TextElement
        v-show="form.method === 'base-height'"
        name="height"
        autocomplete="off"
        input-type="number"
        :rules="[
          'nullable',
          'numeric',
        ]"
        :label="$t('Height(m)')"
        :add-classes="{
          ElementLayout: {
            outerWrapper: '!block',
          },
          ElementLabel: {
            container: '!mb-2.5',
          },
        }"
      />
      <StaticElement>
        <div v-if="select_bound" class="sh-button is-flex is-outline is-primary">
          {{ $t("Select from Map") }}
        </div>
        <HawkButton class="mt-4" :loading="is_loading_volume" :class="{ 'pointer-events-none opacity-50': !can_calculate_volume }" color="primary" block @click="calculate_volume">
          {{ $t('Calculate volume') }}
        </HawkButton>
      </StaticElement>
    </Vueform>
    <transition name="slide-fade">
      <div v-show="volume_results">
        <template v-if="volume_results">
          <table class="w-full table-auto">
            <tbody>
              <tr>
                <td class="border border-slate-500 px-2 py-1">
                  {{ $t('Cut') }}
                </td>
                <td class="border border-slate-500 px-2 py-1">
                  {{ volume_results.cut.toFixed(3) }} m<sup>3</sup>
                </td>
              </tr>
              <tr>
                <td class="border border-slate-500 px-2 py-1">
                  {{ $t('Fill') }}
                </td>
                <td class="border border-slate-500 px-2 py-1">
                  {{ volume_results.fill.toFixed(3) }} m<sup>3</sup>
                </td>
              </tr>
              <tr>
                <td class="border border-slate-500 px-2 py-1">
                  {{ $t('Total') }}
                </td>
                <td class="border border-slate-500 px-2 py-1">
                  {{ volume_results.total.toFixed(3) }} m<sup>3</sup> sea level
                </td>
              </tr>
              <tr>
                <td class="border border-slate-500 px-2 py-1">
                  {{ $t('Net') }}
                </td>
                <td class="border border-slate-500 px-2 py-1">
                  {{ (volume_results.cut - volume_results.fill).toFixed(3) }} m<sup>3</sup>
                </td>
              </tr>
            </tbody>
          </table>
          <HawkButton class="mt-4" color="primary" block @click="volume_results = null">
            {{ $t('Back') }}
          </HawkButton>
        </template>
      </div>
    </transition>
  </div>
</template>
