<script setup>
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();
const $services = inject('$services');

const show_loader = ref(true);

onMounted(async () => {
  show_loader.value = true;
  try {
    const { data } = await $services.common.get({
      url: `approval-flow/${route.params.approval_id}`,
      query: {
        organization: route.query.organization,
      },
    });
    if (data?.approval?.resource_uid) {
      const form = btoa(JSON.stringify({
        form_uid: data.approval.resource_uid,
      }));
      router.push({ query: { form } });
    }
    else { throw new Error('Form id not present for the approval'); }
  }
  catch (err) {
    logger.log(err);
    router.push({
      name: 'home',
    });
  }
  show_loader.value = false;
});

const query = computed(() => route.query);

watch(query, (curr, prev) => {
  if (!curr?.form && prev?.form)
    router.push({ name: 'forms-overview', params: { asset_id: route.params.asset_id } });
}, { immediate: true, deep: true });
</script>

<template>
  <div>
    <HawkLoader v-if="show_loader" />
  </div>
</template>
