import { defineAsyncComponent } from 'vue';
import AcctSettings from '~/acct-settings/pages/acct-settings.vue';
import HawkLoader from '~/common/components/atoms/hawk-loader.vue';

const AcctSettingsOrganizationDetails = defineAsyncComponent({
  loader: () => import('~/acct-settings/pages/acct-settings-organization-details.vue'),
  loadingComponent: HawkLoader,
});
const AcctSettingsAssetDetails = defineAsyncComponent({
  loader: () => import('~/acct-settings/pages/acct-settings-asset-details.vue'),
  loadingComponent: HawkLoader,
});
const AcctSettingsUsersAndInvitations = defineAsyncComponent({
  loader: () => import('~/acct-settings/pages/acct-settings-users-and-invitations/acct-settings-users-and-invitations.vue'),
  loadingComponent: HawkLoader,
});
const AcctSettingsUsersList = defineAsyncComponent({
  loader: () => import('~/acct-settings/pages/acct-settings-users-and-invitations/acct-settings-users.vue'),
  loadingComponent: HawkLoader,
});
const AcctSettingsUserDetails = defineAsyncComponent({
  loader: () => import('~/acct-settings/pages/acct-settings-user-details.vue'),
  loadingComponent: HawkLoader,
});
const AcctSettingsUsersInvitations = defineAsyncComponent({
  loader: () => import('~/acct-settings/pages/acct-settings-users-and-invitations/acct-settings-invitations.vue'),
  loadingComponent: HawkLoader,
});
const AcctSettingsTeams = defineAsyncComponent({
  loader: () => import('~/acct-settings/pages/acct-settings-teams.vue'),
  loadingComponent: HawkLoader,
});
const AcctSettingsTeamDetails = defineAsyncComponent({
  loader: () => import('~/acct-settings/pages/acct-settings-team-details.vue'),
  loadingComponent: HawkLoader,
});
const AcctSettingsRoles = defineAsyncComponent({
  loader: () => import('~/acct-settings/pages/acct-settings-roles.vue'),
  loadingComponent: HawkLoader,
});
const AcctSettingsRoleDetails = defineAsyncComponent({
  loader: () => import('~/acct-settings/pages/acct-settings-role-details.vue'),
  loadingComponent: HawkLoader,
});
const AcctSettingsAssetFields = defineAsyncComponent({
  loader: () => import('~/acct-settings/pages/acct-settings-asset-fields.vue'),
  loadingComponent: HawkLoader,
});
const AssetSettingsIntegrations = defineAsyncComponent({
  loader: () => import('~/acct-settings/pages/acct-settings-integrations.vue'),
  loadingComponent: HawkLoader,
});
const AcctSettingsAuditLogs = defineAsyncComponent({
  loader: () => import('~/acct-settings/pages/acct-settings-audit-logs.vue'),
  loadingComponent: HawkLoader,
});
const AcctSettingsCategoriesAndTags = defineAsyncComponent({
  loader: () => import('~/acct-settings/pages/acct-settings-categories-and-tags/acct-settings-categories-and-tags.vue'),
  loadingComponent: HawkLoader,
});
const AcctSettingsCategories = defineAsyncComponent({
  loader: () => import('~/acct-settings/pages/acct-settings-categories-and-tags/acct-settings-categories.vue'),
  loadingComponent: HawkLoader,
});
const AcctSettingsTags = defineAsyncComponent({
  loader: () => import('~/acct-settings/pages/acct-settings-categories-and-tags/acct-settings-tags.vue'),
  loadingComponent: HawkLoader,
});

const routes = [
  {
    path: '/:asset_id/account-settings',
    component: AcctSettings,
    meta: { },
    redirect: { name: 'account-settings-asset-general' },
    children: [
      {
        path: 'asset',
        name: 'account-settings-asset-general',
        component: AcctSettingsAssetDetails,
        meta: { title: 'General' },
      },
    ],
  },
  {
    path: '/account-settings',
    component: AcctSettings,
    meta: { },
    children: [
      {
        path: '',
        name: 'account-settings-general',
        component: AcctSettingsOrganizationDetails,
        meta: { title: 'General' },
      },
    ],
  },
  {
    path: '/:asset_id?/account-settings',
    component: AcctSettings,
    meta: { },
    children: [
      {
        path: 'users-and-invitations',
        component: AcctSettingsUsersAndInvitations,
        children: [
          {
            path: 'users',
            name: 'account-settings-users',
            component: AcctSettingsUsersList,
            meta: { title: 'User' },
          },
          {
            path: 'invitations',
            name: 'account-settings-invites',
            component: AcctSettingsUsersInvitations,
            meta: {
              title: 'Invitations',
              parent: 'account-settings-users',
            },
          },
        ],
      },
      {
        path: 'users/:id/details',
        name: 'account-settings-user-details',
        component: AcctSettingsUserDetails,
        meta: {
          title: 'User Details',
          parent: 'account-settings-users',
        },
      },
      {
        path: 'teams',
        name: 'account-settings-teams',
        component: AcctSettingsTeams,
        meta: { title: 'Teams' },
      },
      {
        path: 'teams/:id?/details',
        name: 'account-settings-team-details',
        component: AcctSettingsTeamDetails,
        meta: {
          title: 'Team Details',
          parent: 'account-settings-teams',
        },
      },
      {
        path: 'roles',
        name: 'account-settings-roles',
        component: AcctSettingsRoles,
        meta: { title: 'Roles' },
      },
      {
        path: 'roles/:id?/details',
        name: 'account-settings-role-details',
        component: AcctSettingsRoleDetails,
        meta: {
          module: 'account-settings-roles',
          title: 'Create Role',
          parent: 'account-settings-roles',
        },
      },
      {
        path: 'categories-and-tags',
        component: AcctSettingsCategoriesAndTags,
        meta: { title: 'Categories' },
        children: [
          {
            path: 'categories',
            name: 'account-settings-categories',
            component: AcctSettingsCategories,
            meta: { title: 'Categories' },
          },
          {
            path: 'tags',
            name: 'account-settings-tags',
            component: AcctSettingsTags,
            meta: {
              title: 'Tags',
              parent: 'account-settings-categories',
            },
          },
        ],
      },
      {
        path: 'categories',
        name: 'account-settings-asset-categories',
        component: AcctSettingsCategories,
        meta: { title: 'Categories' },
      },
      {
        path: 'asset-fields',
        name: 'account-settings-asset-fields',
        component: AcctSettingsAssetFields,
        meta: { title: 'Asset Settings' },
      },
      {
        path: 'integrations',
        name: 'account-settings-integrations',
        component: AssetSettingsIntegrations,
        meta: { title: 'Integrations' },
      },
      {
        path: 'audit-logs',
        name: 'account-settings-audit-logs',
        component: AcctSettingsAuditLogs,
        meta: { title: 'Audit logs' },
      },
    ],
  },

];
export default routes;
