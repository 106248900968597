<script setup>
import dayjs from 'dayjs';
import { computed } from 'vue';
import { useTerraHelperComposable } from '~/terra/utils/helper-composable.js';
import DefectPriority from '~/therm/components/atoms/defects-priority.vue';
import DefectStatus from '~/therm/components/atoms/defects-status.vue';
import DefectDetailsSlider from '~/therm/components/defect-details/defect-details-slider.vue';
import { useThermStore } from '~/therm/store/therm.store.js';

const { getStyles } = useTerraHelperComposable();
const therm_store = useThermStore();

const show_detailed_view = ref(false);

const feature = computed(() => {
  const { active_attachment } = therm_store.gallery_view_state;
  return therm_store.features_hash[active_attachment.feature_uid];
});
const data = computed(() => feature.value.properties);
const feature_type = computed(() => {
  return therm_store.feature_types[feature.value?.properties?.featureTypeId];
});
const task_details = computed(() => {
  const task = therm_store.tasks_hash?.[data.value.uid];
  return task;
});

function openCoordinatesOnMap() {
  const coordinates = data.value.center[0];
  const map_url = `https://www.google.com/maps?q=${coordinates.toReversed().join(',')}`;
  window.open(map_url, '_blank');
}
</script>

<template>
  <div class="flex flex-col">
    <div class="border-b pb-4">
      <div class="text-md font-semibold text-gray-900 mb-1">
        {{
          `${data.modified_string_number || data.string_number} ${
            feature_type
              ? ` : ${feature_type.name}`
              : ""
          }`
        }}
      </div>
      <div v-if="data.description" class="text-xs font-semibold text-gray-500 mb-1">
        {{ data.description }}
      </div>
      <div v-if="feature_type" class="flex mb-2">
        <div
          class="cursor-pointer mt-1 bg-gray-200 rounded-2xl px-2 py-0.5 text-[12px] font-medium"
          :style="getStyles(feature_type, 'tag')"
        >
          <div class="font-medium text-xs">
            <span class="flex items-center"> {{ feature_type?.name }} </span>
          </div>
        </div>
      </div>
      <div class="text-sm font-normal flex flex-col gap-y-4 mb-4">
        <div v-if="data?.inverter?.name" class="flex">
          <div class="shrink-0 w-32 break-all mr-1 text-gray-500">
            {{ $t('Inverter') }}:
          </div>
          <div class="flex-auto text-gray-900 break-all">
            {{ data.inverter.name }}
          </div>
        </div>
        <div class="flex">
          <div class="shrink-0 w-32 break-all mr-1 text-gray-500">
            {{ $t('Status') }}:
          </div>
          <div class="flex-auto">
            <template v-if="therm_store.use_tasks">
              <TaskStatus v-if="task_details?.status" :status="task_details.status" />
              <div v-else>
                -
              </div>
            </template>
            <DefectStatus v-else :status="data.status" type="badge" />
          </div>
        </div>
        <div class="flex">
          <div class="shrink-0 w-32 break-all mr-1 text-gray-500">
            {{ $t('Priority') }}:
          </div>
          <div class="flex-auto">
            <template v-if="therm_store.use_tasks">
              <TaskPriority v-if="task_details?.priority" :priority="task_details.priority" />
              <div v-else>
                -
              </div>
            </template>
            <div v-else-if="!data.priority">
              NA
            </div>
            <DefectPriority v-else :priority="data.priority" type="badge" />
          </div>
        </div>
        <div class="flex">
          <div class="shrink-0 w-32 break-all mr-1 text-gray-500">
            {{ $t("Assignees") }}:
          </div>
          <div class="flex-auto text-gray-900">
            <HawkMembers v-if="(therm_store.use_tasks ? task_details?.assignees?.length : data.assignees?.length)" class="font-medium" :members="therm_store.use_tasks ? task_details?.assignees : data.assignees" type="badge" :max_badges_to_display="3" />
            <div v-else>
              NA
            </div>
          </div>
        </div>
        <div class="flex">
          <div class="shrink-0 w-32 break-all mr-1 text-gray-500">
            {{ $t('Location') }}:
          </div>
          <div class="flex-auto text-gray-900 break-all">
            {{ data.string_number || "NA" }}
          </div>
        </div>
        <div v-if="data.temperature_difference" class="flex">
          <div class="shrink-0 w-32 break-all mr-1 text-gray-500">
            {{ $t('Δ Temperature') }}:
          </div>
          <div class="flex-auto text-gray-900 break-all">
            {{ `${feature?.properties?.temperature_difference.toFixed(2)} °C` }}
          </div>
        </div>
        <div v-if="data?.center?.[0]" class="flex">
          <div class="shrink-0 w-32 break-all mr-1 text-gray-500">
            {{ $t("Coordinates") }}:
          </div>

          <div class="flex-auto text-gray-900">
            <HawkButton type="link" class="break-all" @click="openCoordinatesOnMap">
              {{ data.center[0]?.toReversed().join(',') }}
            </HawkButton>
          </div>
        </div>
        <div v-if="data?.timestamp" class="flex">
          <div class="shrink-0 w-32 break-all mr-1 text-gray-500">
            {{ $t("Timestamp") }}:
          </div>
          <div class="flex-auto text-gray-900 break-all">
            {{ $date(dayjs(data.timestamp.slice(0, 10), 'YYYY:MM:DD'), 'DD MMM YYYY') }}  {{ data.timestamp.slice(10) }}
          </div>
        </div>
        <div v-if="data.adjusted_delta_temperature" class="flex">
          <div class="shrink-0 w-32 break-all mr-1 text-gray-500">
            {{ $t("Adjusted Δ Temp") }}:
          </div>
          <div class="flex-auto text-gray-900 break-all">
            {{ `${data.adjusted_delta_temperature?.toFixed(2)} °C` }}
          </div>
        </div>
        <div v-if="data?.yield_loss_estimate" class="flex">
          <div class="shrink-0 w-32 break-all mr-1 text-gray-500">
            {{ $t("Yield Loss Estimate") }}:
          </div>
          <div class="flex-auto text-gray-900 break-all">
            {{ data.yield_loss_estimate }}
          </div>
        </div>
        <div v-if="data?.power_loss_estimate" class="flex">
          <div class="shrink-0 w-32 break-all mr-1 text-gray-500">
            {{ $t("Power Loss Estimate") }}:
          </div>
          <div class="flex-auto text-gray-900 break-all">
            {{ data.power_loss_estimate }}
          </div>
        </div>
      </div>

      <HawkButton type="outlined" class="!h-auto" block @click="show_detailed_view = true">
        {{ $t('View details') }}
      </HawkButton>
      <DefectDetailsSlider
        :defect_details="show_detailed_view ? data : null"
        @close="show_detailed_view = false"
      />
    </div>
  </div>
</template>
