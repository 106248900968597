import { createRouter, createWebHistory } from 'vue-router';
import AcctSettingsRoutes from '~/acct-settings/routes/acct-settings.routes.js';

import AssetsRoutes from '~/assets/routes/assets.routes.js';
import { onRouteError, routerAfterEach, routerBeforeEach } from '~/common/middleware/routes.middleware';
// Module routes
import AuthRoutes from '~/auth/routes/auth.routes.js';
import CncRoutes from '~/cnc/routes/cnc.routes.js';
import NotFound from '~/common/pages/404.vue';

import PermissionDenied from '~/common/pages/permission-denied.vue';
import SomethingWentWrong from '~/common/pages/something-went-wrong.vue';
import ConversationRoutes from '~/conversations/routes/conversations.routes.js';
import DashboardRoutes from '~/dashboard/routes/dashboard.routes.js';
import DMSRoutes from '~/dms/routes/dms.routes.js';
import FormsRoutes from '~/forms/routes/forms.routes.js';
import FamRoutes from '~/forms-as-module/routes/fam.routes.js';
import InventoryRoutes from '~/inventory/routes/inventory-routes.js';
import NotificationsRoutes from '~/notifications/routes/notifications.routes.js';
import PlansRoutes from '~/plans/routes/plans.routes.js';
import ProfileSettingsRoutes from '~/profile-settings/routes/profile-settings.routes.js';
import ProjectManagementRoutes from '~/project-management/routes/pm.routes.js';
import ProjectOutputsRoutes from '~/project-outputs/routes/project-outputs.routes.js';
import SystemModelRoutes from '~/system-model/routes/system-model.routes.js';
import TasksRoutes from '~/tasks/routes/tasks.routes.js';
import TerraRoutes from '~/terra/routes/terra.routes.js';
import ThermRoutes from '~/therm/routes/therm.routes';
import ThirdPartyWidgetsRoutes from '~/third-party-widgets/routes/third-party-widgets.routes.js';

const routes = [
  ...AuthRoutes,
  ...AssetsRoutes,
  ...ProfileSettingsRoutes,
  ...ProjectManagementRoutes,
  ...AcctSettingsRoutes,
  ...DMSRoutes,
  ...TerraRoutes,
  ...ThermRoutes,
  ...TasksRoutes,
  ...FormsRoutes,
  ...FamRoutes,
  ...DashboardRoutes,
  ...SystemModelRoutes,
  ...InventoryRoutes,
  ...PlansRoutes,
  ...ConversationRoutes,
  ...NotificationsRoutes,
  ...ProjectOutputsRoutes,
  ...CncRoutes,
  ...ThirdPartyWidgetsRoutes,
  {
    path: '/permission-denied',
    name: 'permission-denied',
    component: PermissionDenied,
  },
  {
    path: '/something-went-wrong',
    name: 'something-went-wrong',
    component: SomethingWentWrong,
    meta: { layout: 'auth', noAuthRequires: true },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
    meta: { noAuthRequires: true },
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // when opening previews in dashboard and going back,
    // the scroll should not be reset
    if (
      to?.name?.includes('dashboard')
      && from?.name?.includes('dashboard')
      && (
        Object.keys(from?.query || {}).length
        || Object.keys(to?.query || {}).length
      )
    ) {
      return savedPosition;
    }

    // always scroll to top
    return { top: 0 };
  },
});

router.beforeEach(routerBeforeEach);
router.afterEach(routerAfterEach);
router.onError(onRouteError);

export default router;
