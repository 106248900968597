<script setup>
import { useDocumentStore } from '~/dms/store/document.store';

const props = defineProps({
  options: {
    type: Object,
  },
});

const emit = defineEmits(['close', 'save']);

const form$ = ref(null);

async function pasteHandler(event, index) {
  const text = event.clipboardData?.getData('text');
  if (!text)
    return;
  const columns = text.split(/[\n,]+/);
  const items = columns.map((item) => {
    return { name: item.split(/[\t,]+/)?.[0], number: item.split(/[\t,]+/)?.[1] };
  });
  const merged_names = [...(form$.value.data.documents?.filter((c, i) => i < index && (c.name || c.number)) || []), ...items];
  const config$ = form$.value.el$('documents');
  setTimeout(() => {
    config$.update(merged_names);
  }, 5);
}

const $toast = inject('$toast');
const $t = inject('$t');
const document_store = useDocumentStore();
async function uploadFileHandler(data, form$) {
  const response = await document_store.crud_documents({
    request: {
      body: {
        files: {
          add: form$.data.documents.map(({ name, number }) => ({
            name,
            file_name: name,
            number,
            is_placeholder: true,
            ...props.options,
          })),
        },
      },
    },
  });
  emit('close');

  if (response?.data?.documents?.files?.added?.length) {
    emit('save', response?.data?.documents?.files?.added);
    $toast({
      title: $t('File(s) uploaded successfully'),
      type: 'success',
      position: 'bottom-right',
    });
    return response?.data?.documents?.files?.added.map(file => file.uid);
  }
}
</script>

<template>
  <hawk-modal-container>
    <Vueform
      ref="form$"
      size="sm"
      :columns="{ container: 12, label: 4, wrapper: 12 }"
      :display-errors="false"
      class="grid grid-rows-1 grid-flow-col gap-4"
      :endpoint="uploadFileHandler"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            <div class="flex items-center">
              <div class="flex flex-col justify-start">
                {{ $t('Create placeholder document') }}
              </div>
            </div>
          </template>
        </hawk-modal-header>
        <!-- Body -->
        <hawk-modal-content>
          <div class="text-sm mb-4">
            {{ $t('Create a placeholder document to request a submission by transmittal or directly upload the document when it is available.') }}
          </div>
          <div class="p-1">
            <div class="grid grid-cols-12 gap-1 mb-2 text-sm font-medium">
              <div class="col-span-11 grid grid-cols-2 gap-2">
                <div>{{ $t('Name') }}</div>
                <div>{{ $t('Number') }}</div>
              </div>
            </div>
            <ListElement
              name="documents" :presets="['repeatable_list']"
              :add-text="`+ ${$t('Add')} ${$t('document')}`"

              :add-classes="{
                ListElement: {
                  add: [
                    'text-primary-700', 'text-sm',
                  ],
                },
              }"
            >
              <template #default="{ index }">
                <ObjectElement
                  :name="index"
                  :embed="true"
                  :columns="{
                    default: { container: 11, label: 12, wrapper: 12 },
                    sm: { container: 11, label: 12, wrapper: 12 },
                    md: { container: 11, label: 12, wrapper: 12 },
                  }"
                >
                  <div class="col-span-12 grid gap-4 grid-cols-2">
                    <div>
                      <TextElement
                        :rules="['required']"
                        :placeholder="$t('Enter name')" name="name"

                        @paste="e => pasteHandler(e, index)"
                      />
                    </div>
                    <div>
                      <TextElement
                        :placeholder="$t('Enter number')" name="number"
                      />
                    </div>
                  </div>
                </ObjectElement>
              </template>
            </ListElement>
          </div>
        </hawk-modal-content>
        <hawk-modal-footer class="flex justify-between items-center">
          <template v-if="$slots.left_footer" #left>
            <slot name="left_footer" />
          </template>
          <!-- Footer -->
          <template #right>
            <div class="flex justify-end items-center">
              <hawk-button class="mr-3" type="outlined" @click="$emit('close')">
                {{ $t('Cancel') }}
              </hawk-button>
              <hawk-submit-button :form$="form$ || {}">
                {{ $t('Save') }}
              </hawk-submit-button>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
