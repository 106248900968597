<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import IconFilter from '~icons/hawk/filter-icon';
import { useModal } from 'vue-final-modal';
import { useRouter } from 'vue-router';
import HawkTable from '~/common/components/organisms/hawk-table/hawk-table.vue';
import { searchData } from '~/common/utils/common.utils';
import FiltersPopupFormSubmission from '~/forms/components/form-submission-filter.vue';
import { useFormFilterStore } from '~/forms/store/form-filter-store';
import { useFormSubmission } from '~/forms/store/form-submission-store';
import { $dateChecker, $monthChecker, $weekChecker } from '~/forms/utils/forms-due-date.util';

const props = defineProps({
  asset_id: {
    type: String,
    default: null,
  },

});

const $t = inject('$t');

const form_submission_store = useFormSubmission();
const search = ref('');
const router = useRouter();
const filter_store = useFormFilterStore();
const table_instance = ref(null);
const query = { all_access: true };
const columns = computed(() =>[{
  header: $t('Name'),
  accessorKey: 'name',
  id: 'name',
  enableResizing: false,
}, {
  header: $t('Template'),
  accessorKey: 'template',
  id: 'template',
  enableResizing: false,
}, {
  header: $t('Submitted on'),
  accessorKey: 'group_by_dates',
  id: 'submitted_at',
  enableResizing: false,
}]);

const loading = ref(false);
const is_loading = ref(false);

const filtered_data = computed(() => searchData(form_submission_store?.submissions, search.value));
const submitted_data = computed(() => {
  if (Object.keys(filter_store?.filters).length) {
    let data = filtered_data?.value;
    for (const key in filter_store?.filters) {
      if (key === 'template')
        data = data?.filter(form => Object.values(filter_store?.filters?.template).includes(form?.template));
      else if (key === 'category')
        data = data?.filter(form => Object.values(filter_store?.filters?.category).includes(form?.category));
      else if (key === 'date_group')
        data = getDueDateForSubmission(data, filter_store?.filters.date_group);
    }
    return data;
  }
  else { return filtered_data.value; }
});

const has_data = computed(() => !!submitted_data.value.length);
const has_filter_applied = computed(() => filter_store.filters_count > 0 || search.value);
const has_no_results = computed(() => !has_data.value && has_filter_applied.value);

function getDueDateForSubmission(data, selected_due_date) {
  const filterDate = {
    today: (form) => {
      return $dateChecker(form.submitted_at);
    },
    week: (form) => {
      return $weekChecker(form.submitted_at);
    },
    month: (form) => {
      return $monthChecker(form.submitted_at);
    },
    custom: (form) => {
      return (
        new Date(filter_store?.filters?.date_group?.start_due_date) <= new Date(form?.submitted_at)
          && new Date(filter_store?.filters?.date_group?.end_due_date) >= new Date(form?.submitted_at)
      );
    },
  };
  return data?.filter(form => filterDate[selected_due_date.due_date]?.(form));
}
// Filters Popup Handler
const { open: openFiltersPopup, close: closeFiltersPopup } = useModal({
  component: FiltersPopupFormSubmission,
  attrs: {
    onClose() {
      closeFiltersPopup();
    },
  },
});

async function clearFilters() {
  is_loading.value = true;
  filter_store.set_filters({ reset: true });
  await form_submission_store.set_submitted_forms({ query, attribute: 'submissions/list' });
  is_loading.value = false;
}

async function getData(data) {
  loading.value = data;
  await form_submission_store.set_submitted_forms({ query, attribute: 'submissions/list' });
  loading.value = false;
}
onMounted(async () => {
  await getData(!form_submission_store?.forms?.length);
});
watch(() => props.asset_id, () => {
  getData(true);
});
function openFormSubmissionComponent(row) {
  router.push({ query: { form: btoa(JSON.stringify({ form_uid: row.uid })), submission_history: row.submission_history } });
}
</script>

<template>
  <HawkPageHeader :title="$t('Submissions')" class="-mb-2 -mx-4" />
  <div class="mt-2">
    <hawk-sticky-header>
      <div class="flex justify-between">
        <div class="flex">
          <hawk-search-input v-model="search" :placeholder="$t('Search')" />
          <div class="flex items-center ml-4">
            <hawk-button
              class="mr-4"
              type="outlined"
              @click="openFiltersPopup"
            >
              <IconFilter class="text-gray-600 w-[15px] h-2.5" />
              <span class="text-sm text-[#667085]">{{ $t('filters') }}</span>
              <p v-if="filter_store.filters_count">
                ({{ filter_store.filters_count }})
              </p>
            </hawk-button>
            <p
              v-if="filter_store.filters_count"
              class="font-semibold text-sm text-gray-600 cursor-pointer"
              @click="clearFilters"
            >
              {{ $t('Clear Filters') }}
            </p>
          </div>
        </div>
        <new-form-button />
      </div>
    </hawk-sticky-header>
    <div v-if="loading">
      <hawk-loader />
    </div>
    <HawkIllustrations v-else-if="has_no_results" type="no-results" for="forms-submissions" />
    <HawkIllustrations v-else-if="!has_data" type="no-data" for="forms-submissions" />
    <HawkTable
      v-else
      :is_loading="loading"
      :manual_pagination="true"
      :data="submitted_data" :columns="columns" :is_gapless="true" group_by="group_by"
      :show_menu_header="false"
      stick_header_offset="132"
      :sticky_group_label="true"
      @row-clicked="openFormSubmissionComponent"
      @table-instance="table_instance = $event"
    >
      <template #group-label="{ data }">
        <div class="text-sm font-semibold">
          <span class="capitalize mr-1">{{ data.value === 'undefined' ? $t('Undefined') : data.value }}</span> ({{ data.count }} {{ $t('forms') }})
        </div>
      </template>
      <template #name="form">
        <div class="whitespace-nowrap">
          <hawk-text length="60" class="text-gray-900 font-medium" :content="form.data.getValue()" />
        </div>
      </template>
      <template #template="template">
        {{ form_submission_store?.form_templates_map?.[template.data.getValue()]?.name }}
      </template>
    </HawkTable>
  </div>
</template>
