<script setup>
import { find, findIndex } from 'lodash-es';
import HawkModalTemplate from '~/common/components/hawk-modal/hawk-modal-template.vue';
import { useCommonStore } from '~/common/stores/common.store.js';

const props = defineProps({
  members: {
    type: Array,
    required: false,
    default: () => [],
  },
  // Array of objects containing the uids and level of access of teams.
  // Structure of each object is `{ uid: String, access: String ('read', 'write', or 'manage') }`.
  teams: {
    type: Array,
    required: false,
    default: () => [],
  },
  // Array of access levels that can be assigned to users and teams.
  access_levels: {
    type: Array,
    required: false,
    default: () => {
      const $t = inject('$t');
      return [
        { name: 'read', label: $t('Can view'), description: $t('Can view') },
        { name: 'write', label: $t('Can edit'), description: $t('Can view and edit') },
        { name: 'manage', label: $t('Can manage'), description: $t('Can view, edit, and manage') },
      ];
    },
  },
  // Array of access levels that can be set as the global access level.
  global_access_levels: {
    type: Array,
    required: false,
    default: () => {
      const $t = inject('$t');
      return [
        { name: 'no_access', label: $t('No access'), description: $t('No access') },
        { name: 'read', label: $t('Can view'), description: $t('Can view') },
      ];
    },
  },
  // Specifies the 'name' property of the current level in the global_access_levels
  global_access_level: {
    type: String,
    default: undefined,
    required: false,
  },
  // If this uid is provided, an additional line denoting the owner of the object is displayed.
  owner_uid: {
    type: String,
    required: false,
    default: '',
  },
  // Defines whether modifications should be allowed.
  is_editable: {
    type: Boolean,
    required: false,
    default: true,
  },
  // For some objects, there's a concept of global access.
  // This prop defines if the control for global access should be displayed.
  is_global_access_visible: {
    type: Boolean,
    required: false,
    default: false,
  },
  // Defines if this component should be displayed as a modal (you'll still need code to launch it) or not.
  is_modal: {
    type: Boolean,
    default: false,
  },
  // In the modal mode, this function is called when the primary action button is pressed.
  // In non-modal mode, this function is called as soon as there is any change in data.
  get_share_data: {
    type: Function,
    default: null,
  },
  //
  share_action_text: {
    type: String,
    default: () => {
      const $t = inject('$t');
      return $t('Share');
    },
  },
  // Hid 'people with access' text when the block is empty'
  hide_empty: {
    type: Boolean,
    default: true,
  },
  has_teams: {
    type: Boolean,
    default: true,
  },
  title: {
    type: String,
    required: false,
    default: () => {
      const $t = inject('$t');
      return $t('Share with people');
    },
  },
  description: {
    type: String,
    required: false,
    default: () => {
      const $t = inject('$t');
      return $t('The following people have access to this project:');
    },
  },
  has_icon: {
    type: Boolean,
    required: false,
    default: true,
  },
  is_edit_mode: {
    type: Boolean,
    required: false,
    default: false,
  },
  edit_data: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  // Allows to disable all user, team edit options (access levels)
  hide_people_access: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(['close', 'input', 'updateAccess', 'removeAccess', 'updatedMembers', 'accessLevelChanged']);
const $t = inject('$t');
const common_store = useCommonStore();

const form$ = ref(null);
const added_users = ref([...props.members]);
const added_teams = ref([...props.teams]);
const is_initially_shared = ref(false);
const add_mode = ref(false);

const selected_access_level = ref(props.edit_data?.access ? find(props.access_levels, ['name', props.edit_data.access]) : props.access_levels[0]);
const global_access_level = ref(props.global_access_level ?? props.global_access_levels[0].name);

const added_user_uids = computed(() => (added_users.value.map(item => item.uid) || []));
const added_team_uids = computed(() => (added_teams.value.map(item => item.uid) || []));
const share_button_disabled = computed(() => (props.is_editable && !added_users.value?.length && !added_teams.value?.length && !is_initially_shared.value));
const access_level_labels_map = computed(() => props.access_levels.reduce((acc, level) => {
  acc[level.name] = level;
  return acc;
}, {}));
const global_access_level_labels_map = computed(() => props.global_access_levels.reduce((acc, level) => {
  acc[level.name] = level;
  return acc;
}, {}));

function onSelect() {
  const form_data = form$.value.data;
  add_mode.value = !!form_data.assignees.length;
}

function onBackClick() {
  form$.value.reset();
}

function updateAccessLevel(type, access, index) {
  const target = type === 'user' ? added_users : added_teams;
  target.value[index].access = access.name;
  emit('updateAccess', target.value);
}

function removeAccess(type, index) {
  const target = type === 'user' ? added_users : added_teams;
  target.value.splice(index, 1);

  const data = {
    users: type === 'user' ? target.value : added_users.value,
    teams: type === 'team' ? target.value : added_teams.value,
    ...(props.is_global_access_visible && { global_access_level: global_access_level.value }),
  };
  props.get_share_data?.(data);
  emit('removeAccess', data);
}

function onShare() {
  const updated_members = [];
  if (props.is_edit_mode) {
    updated_members.push({ uid: props.edit_data.uid, access: selected_access_level.value.name, member: props.edit_data.type === 'user' });
  }
  else {
    const form_data = form$.value.data;
    form_data.assignees.forEach((item) => {
      const target = item.member ? added_users : added_teams;
      target.value.push({ uid: item.uid, access: selected_access_level.value.name });
    });

    form_data.assignees.forEach((item) => {
      updated_members.push({ uid: item.uid, access: selected_access_level.value.name, member: item.member });
    });
  }

  const data = {
    users: added_users.value,
    teams: added_teams.value,
    ...(props.is_global_access_visible && { global_access_level: global_access_level.value }),
  };
  props.get_share_data?.(data);
  emit('input', data);
  emit('updatedMembers', updated_members);
  if (props.is_modal)
    emit('close');
}

function setSelectedAccessLevel(access) {
  selected_access_level.value = props.access_levels.find(item => item.name === access.name);
  emit('accessLevelChanged', access.name);
}

watch(() => props.is_edit_mode, (val) => {
  add_mode.value = !!val;
}, { deep: true, immediate: true });
</script>

<template>
  <component :is="is_modal ? HawkModalTemplate : 'div'">
    <template v-if="is_modal" #header>
      <div class="flex items-center p-6 border-b border-b-gray-200 justify-between text-lg font-semibold text-gray-800">
        <div class="flex items-center">
          <div
            v-if="has_icon" class="flex items-center border rounded-xl p-3 mr-4 border-gray-200"
            :class="{ 'cursor-pointer': add_mode }"
            @click="add_mode ? onBackClick() : null"
          >
            <IconHawkArrowNarrowLeft v-if="add_mode && !is_edit_mode" />
            <IconHawkUsersShare v-else />
          </div>
          <div class="flex flex-col justify-start">
            {{ title }}
            <span class="font-normal text-sm text-gray-600">
              {{ description }}
            </span>
          </div>
        </div>
        <div class="flex items-center justify-center">
          <div class="text-gray-600 rounded-md hover:bg-gray-50 cursor-pointer flex justify-center items-center p-2 ml-2" @click="$emit('close')">
            <IconHawkXClose class="w-6 h-6" />
          </div>
        </div>
      </div>
    </template>

    <Vueform
      ref="form$" size="sm" :add-classes="{
        ElementLabel: {
          container: 'hidden',
        },
      }" class="w-[600px]"
    >
      <template v-if="is_editable">
        <div class="col-span-12">
          <div v-if="is_edit_mode" class="flex justify-between items-center">
            <div>
              <HawkMembers :members="edit_data.uid" type="label" name_classes="text-sm text-gray-700" />
            </div>
            <HawkMenu
              :items="access_levels"
              additional_dropdown_classes="!w-60 !max-h-48 scrollbar"
              position="fixed"
              class="flex-shrink-0"
              @select="setSelectedAccessLevel"
            >
              <template #item="{ item }">
                <div class="flex flex-col">
                  <span class="text-sm">
                    {{ item.label }}
                  </span>
                  <span class="text-xs font-normal">
                    {{ item.description }}
                  </span>
                </div>
              </template>
              <template #trigger="{ open }">
                <div class="flex cursor-pointer items-center rounded-lg py-1.75 text-sm font-semibold text-gray-600 border px-2.5 border-gray-300">
                  {{ selected_access_level.label }}
                  <IconHawkChevronUp v-if="open" class="text-lg" />
                  <IconHawkChevronDown v-else class="text-lg" />
                </div>
              </template>
            </HawkMenu>
          </div>
          <div v-else class="flex gap-3 items-center justify-evenly">
            <hawk-assignee-input
              class="max-w-full w-full"
              :multi="true"
              :columns="{
                default: { container: 12, label: 0, wrapper: 12 },
                sm: { container: 12, label: 0, wrapper: 12 },
                md: { container: 12, wrapper: 12, label: 0 },
              }"
              :options="{
                existing_users: added_user_uids,
                existing_teams: added_team_uids,
                name: 'assignees',
                has_teams: props.has_teams,
                placeholder: $t('Add members and teams'),
              }"
              @change="onSelect()"
              @deselect="onSelect()"
              @clear="onSelect()"
            />
            <HawkMenu
              v-if="add_mode"
              :items="access_levels"
              additional_dropdown_classes="!w-60 !max-h-48 scrollbar"
              position="fixed"
              class="flex-shrink-0"
              @select="setSelectedAccessLevel"
            >
              <template #item="{ item }">
                <div class="flex flex-col">
                  <span class="text-sm">
                    {{ item.label }}
                  </span>
                  <span class="text-xs font-normal">
                    {{ item.description }}
                  </span>
                </div>
              </template>
              <template #trigger="{ open }">
                <div class="flex cursor-pointer items-center rounded-lg py-1.75 text-sm font-semibold text-gray-600 border px-2.5 border-gray-300">
                  {{ selected_access_level.label }}
                  <IconHawkChevronUp v-if="open" class="text-lg" />
                  <IconHawkChevronDown v-else class="text-lg" />
                </div>
              </template>
            </HawkMenu>
          </div>
        </div>
        <div v-if="add_mode || is_edit_mode" class="col-span-12">
          <slot />
        </div>
        <div v-if="!add_mode && !is_edit_mode && !hide_people_access" class="col-span-12">
          <div
            v-if="hide_empty ? (added_users?.length || added_teams.length || owner_uid) : true" class="font-semibold text-sm col-span-12"
            :class="{ 'mt-5': is_editable }"
          >
            {{ $t('People with access') }}
          </div>
          <div class="max-h-[250px] overflow-auto scrollbar">
            <div v-if="owner_uid" class="flex items-center justify-between">
              <div class="my-2">
                <HawkMembers :members="owner_uid" size="xs" type="label" />
              </div>
              <div class="text-sm mr-3">
                {{ $t('Owner') }}
              </div>
            </div>
            <div v-for="(user, index) of added_users" :key="user.uid" class="col-span-12 flex items-center">
              <div class="my-2">
                <HawkMembers :members="user.uid" size="xs" type="label" />
              </div>
              <HawkMenu
                v-if="is_editable"
                class="ml-auto mr-2"
                position="fixed"
                additional_dropdown_classes="!w-60 !max-h-48 scrollbar -mt-3"
                :items="access_levels"
                @select="access => updateAccessLevel('user', access, index)"
              >
                <template #item="{ item }">
                  <div class="flex flex-col">
                    <span class="text-sm">
                      {{ item.label }}
                    </span>
                    <span class="text-xs font-normal">
                      {{ item.description }}
                    </span>
                  </div>
                </template>
                <template #trigger="{ open }">
                  <div class="flex cursor-pointer items-center rounded py-1.25 text-sm font-semibold text-gray-600">
                    {{ access_level_labels_map[user.access]?.label }}
                    <IconHawkChevronUp v-if="open" class="text-lg" />
                    <IconHawkChevronDown v-else class="text-lg" />
                  </div>
                </template>
                <template #footer>
                  <div
                    class="flex cursor-pointer items-center rounded hover:bg-gray-50 py-2 px-3 font-medium text-sm"
                    @click="removeAccess('user', index)"
                  >
                    {{ $t('Remove Access') }}
                  </div>
                </template>
              </HawkMenu>
              <div v-else class="ml-auto mr-2 flex items-center rounded py-1.25 text-sm">
                {{ access_level_labels_map[user.access]?.label }}
              </div>
            </div>
            <div v-for="(team, index) of added_teams" :key="team.uid" class="col-span-12 flex items-center">
              <div class="flex my-2">
                <HawkMembers v-if="team.uid" :members="team.uid" type="label" size="xs" />
              </div>
              <HawkMenu
                v-if="is_editable"
                class="ml-auto mr-2"
                position="fixed"
                additional_dropdown_classes="!w-60 !max-h-48 scrollbar -mt-3"
                :items="access_levels"
                @select="access => updateAccessLevel('team', access, index)"
              >
                <template #item="{ item }">
                  <div class="flex flex-col">
                    <span class="text-sm">
                      {{ item.label }}
                    </span>
                    <span class="text-xs font-normal">
                      {{ item.description }}
                    </span>
                  </div>
                </template>
                <template #trigger="{ open }">
                  <div class="flex cursor-pointer items-center rounded py-1.25 text-sm font-semibold text-gray-600">
                    {{ access_level_labels_map[team.access]?.label }}
                    <IconHawkChevronUp v-if="open" class="text-lg" />
                    <IconHawkChevronDown v-else class="text-lg" />
                  </div>
                </template>
                <template #footer>
                  <div
                    class="flex cursor-pointer items-center rounded hover:bg-gray-50 py-2 px-3 font-medium text-sm"
                    @click="removeAccess('team', index)"
                  >
                    {{ $t('Remove Access') }}
                  </div>
                </template>
              </HawkMenu>
              <div v-else class="ml-auto mr-2 flex items-center rounded py-1.25 text-sm">
                {{ access_level_labels_map[team.access]?.label }}
              </div>
            </div>
            <hr v-if="is_global_access_visible" class="border-gray-200 my-4">
            <div v-if="is_global_access_visible" class="flex items-center">
              <div class="flex items-center my-1">
                <div class="rounded-full pr-1">
                  <IconHawkGlobe class="h-6 w-6 text-gray-600 text-[31px] mr-0.5" />
                </div>
                <div class="my-2 text-sm font-medium text-gray-700">
                  <span class="text-gray-900">{{ $t('Anyone') }}</span>
                  {{ $t('added in this project') }}
                </div>
              </div>
              <HawkMenu
                v-if="is_editable"
                class="ml-auto mr-2"
                position="fixed"
                :items="global_access_levels"
                @select="access => global_access_level = access.name"
              >
                <template #item="{ item }">
                  <div class="flex flex-col">
                    <span class="text-sm">
                      {{ item.label }}
                    </span>
                    <span class="text-xs font-normal">
                      {{ item.description }}
                    </span>
                  </div>
                </template>
                <template #trigger="{ open }">
                  <div class="flex cursor-pointer items-center rounded py-1.25 text-sm font-semibold  text-gray-600">
                    {{ global_access_level_labels_map[global_access_level]?.label }}
                    <IconHawkChevronUp v-if="open" class="text-lg" />
                    <IconHawkChevronDown v-else class="text-lg" />
                  </div>
                </template>
              </HawkMenu>
              <div v-else class="ml-auto mr-2 flex items-center rounded py-1.25 text-sm">
                {{ global_access_level_labels_map[global_access_level]?.label }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </Vueform>

    <template v-if="is_modal" #footer>
      <Vueform size="sm" class="w-[600px]">
        <div class="col-span-12 flex items-center justify-between w-full">
          <hawk-button
            v-if="is_edit_mode" type="plain" color="error"
            @click="removeAccess(added_team_uids.includes(edit_data.uid) ? 'team' : 'user', findIndex(added_team_uids.includes(edit_data.uid) ? added_team_uids : added_user_uids, uid => uid === edit_data.uid));emit('close')"
          >
            {{ $t('Remove') }}
          </hawk-button>
          <div v-else />
          <hawk-button @click="(add_mode || is_edit_mode) ? onShare() : emit('close')">
            {{ (add_mode || is_edit_mode) ? $t('Save') : $t('Done') }}
          </hawk-button>
        </div>
      </Vueform>
    </template>
  </component>
</template>
