<script setup>
import { omit } from 'lodash-es';
import { useCommonStore } from '~/common/stores/common.store.js';

const props = defineProps({
  item: {
    type: Object,
    default: () => {},
  },
});

const emit = defineEmits(['close']);

const $toast = inject('$toast');
const common_store = useCommonStore();

const form$ = ref(null);
const form = ref({});

async function save() {
  try {
    const { data } = await common_store.update_data({
      type: 'add',
      data: {
        asset: {
          ...omit(common_store.assets_map[props.item.uid], [
            'uid',
            'created_at',
            'updated_at',
            'name',
            'created_by',
            'bookmark',
          ]),
          name: form.value.name,
          code: null,
        },
      },
      service: 'assets',
      append_data: true,
      state_prop: 'assets_map',
      update_state: false,
    });

    if (data.asset) {
      await common_store.update_global_data({ assets: true });
      $toast({
        text: 'Asset duplicated successfully',
        type: 'success',
      });
      emit('close');
    }
    else {
      $toast({
        title: 'Something went wrong',
        text: 'Please try again',
        type: 'error',
      });
    }
  }
  catch (error) {
    logger.log(error);
  }
}

function onFormMounted(el$) {
  el$.update({ name: `${props.item.name} - copy` });
}
</script>

<template>
  <hawk-modal-container :width="400">
    <Vueform
      ref="form$"
      v-model="form"
      size="sm"
      :display-errors="false"
      :columns="{ container: 12, label: 4, wrapper: 12 }"
      :format-load="(data) => data"
      :endpoint="save"
      @mounted="onFormMounted"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            {{ $t('Duplicate Asset') }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content>
          <TextElement
            label="Asset name"
            name="name"
            placeholder="Enter asset name"
            rules="required"
          />
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="flex justify-end gap-4">
              <HawkButton type="outlined" @click="emit('close')">
                {{ $t('Close') }}
              </HawkButton>
              <ButtonElement button-class="w-full bg-blue-600" name="submit" :submits="true">
                {{ $t('Duplicate') }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
