import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import minMax from 'dayjs/plugin/minMax';
import timezone from 'dayjs/plugin/timezone';
import { md5 } from 'js-md5';
import { groupBy, reduce, round, sortBy, uniqBy } from 'lodash-es';
import { computed, reactive, ref } from 'vue';
import useAbortController from '~/common/composables/abort-controller.js';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { csvInjectionProtector } from '~/common/utils/common.utils.js';
import { useTerraStore } from '~/terra/store/terra.store.js';
import { useChartsConstants } from '~/terra/utils/charts-constants';

dayjs.extend(minMax);
dayjs.extend(isSameOrBefore);
dayjs.extend(timezone);

export function useExportTerraReport() {
  const terra_store = useTerraStore();
  const controllers = useAbortController();

  const { auth_store, common_store, $t, $services } = useCommonImports();
  const { sheet_column_specific_keys, sheet_column_specific_titles, sheet_column_titles, sheet_status_map } = useChartsConstants();
  if (auth_store?.logged_in_user_details?.timezone)
    dayjs.tz.setDefault(auth_store?.logged_in_user_details?.timezone);

  const is_report_exporting = ref(false);
  const is_split_enabled = ref(false);

  const state = reactive({
    progress_data: {},
  });
  const container_name = computed(() => terra_store.container?.name);

  const columns_titles = computed(() => {
    const clones_titles = auth_store.check_split('grs_export_summary') && is_split_enabled.value ? [...sheet_column_specific_titles] : [...sheet_column_titles];
    if (auth_store.check_split('terra_cpr_id_column'))
      clones_titles.splice(1, 0, 'ID');
    return clones_titles;
  });

  const sheet_name = computed(() => container_name.value || 'terra-report');
  const project_group_map = computed(() => {
    const project_group_names = {};
    Object.values(terra_store.container?.groups || {}).forEach((group) => {
      Object.values(group.projects || {}).forEach((project) => {
        project_group_names[project.uid] = `${group.name}/${project.name}`;
      });
    });
    return project_group_names;
  });

  function getWorkflowProjectMap(reports = []) {
    const workflow_project_map = {};
    const grouped_workflow = groupBy(reports, 'workflow');
    for (const [workflow, project] of Object.entries(grouped_workflow)) {
      const filtered_projects = project.filter(project => project.project);
      workflow_project_map[workflow] = groupBy(sortBy(filtered_projects, ['project']), 'project');
    }
    return reduce(sortBy(Object.keys(workflow_project_map)), (acc, key) => {
      acc[key] = workflow_project_map[key];
      return acc;
    }, {});
  }

  const fixed_columns = computed(() => {
    if (is_split_enabled.value && auth_store.check_split('grs_export_summary'))
      return sheet_column_specific_keys;
    return [
      { key: 'number' },
      ...(auth_store.check_split('terra_cpr_id_column') ? [{ key: 'id', width: 20 }] : []),
      { key: 'activity', width: 20 },
      { key: 'workflow', width: 20 },
      { key: 'layer', width: 20 },
      ...(auth_store.check_split('grs_export_summary ') && is_split_enabled.value ? [{ key: 'sublayer', width: 20 }] : []),
      { key: 'uom' },
      { key: 'scope' },
      { key: 'completed' },
      { key: 'remaining' },
      { key: 'progress' },
      { key: 'status', width: 12 },
      { key: 'planned_start', width: 15, style: { numFmt: 'DD/MM/YYYY' } },
      { key: 'planned_finish', width: 15, style: { numFmt: 'DD/MM/YYYY' } },
      { key: 'expected_work_rate', width: 15 },
      { key: 'actual_start', width: 15, style: { numFmt: 'DD/MM/YYYY' } },
      { key: 'actual_finish', width: 15, style: { numFmt: 'DD/MM/YYYY' } },
      { key: 'est_finish_date', width: 15, style: { numFmt: 'DD/MM/YYYY' } },
    ];
  });

  function utcToDate(date) {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
  }

  async function fetchProgressData(report_data) {
    try {
      const project_uid = uniqBy(report_data, 'project_uid').map(project => project.project_uid);
      const { data } = await $services.terra.post({
        attribute: 'progress-data',
        body: {
          export: true,
          project: project_uid,
          group_by: 'project',
        },
      });
      return reduce(data, (result, item) => {
        const workflow_name = item.workflow.name;
        const project_name = item.project.name;
        const field_name = item.field.name;
        const date = dayjs(item.date).format('DD-MMM-YY');
        result[workflow_name] = result[workflow_name] || {};
        result[workflow_name][project_name] = result[workflow_name][project_name] || {};
        result[workflow_name][project_name][field_name] = result[workflow_name][project_name][field_name] || {};
        result[workflow_name][project_name][field_name][date] = item.value;
        return result;
      }, {});
    }
    catch (er) {
      logger.error(er);
      return {};
    }
  }

  function setupHeaderInfo(worksheet) {
    worksheet.mergeCells('B2:L2');
    worksheet.getCell('B2').value = $t('Construction progress report');

    const second_rows = worksheet.getRow(2);
    second_rows.height = 42;
    worksheet.getCell('B2').style = {
      font: {
        size: 30,
        bold: true,
        color: { argb: '0b5394' },
      },
      alignment: {
        vertical: 'middle',
        wrapText: true,
      },
    };
    second_rows.eachCell((cell) => {
      cell.border = {
        bottom: { style: 'thin', color: { argb: '0b5394' } },
      };
    });
    const header_cells = {
      B4: {
        merge_with: 'C4',
        value: 'ORGANIZATION',
        style: true,
      },
      D4: {
        merge_with: 'F4',
        value: csvInjectionProtector(auth_store.current_organization?.name) || '-',
        style: false,
      },
      G4: {
        merge_with: 'I4',
        value: 'ASSET',
        style: true,
      },
      J4: {
        merge_with: 'L4',
        value: csvInjectionProtector(common_store?.active_asset?.name) || '-',
        style: false,
      },
      B5: {
        merge_with: 'C5',
        value: 'MAP',
        style: true,
      },
      D5: {
        merge_with: 'F5',
        value: csvInjectionProtector(container_name.value) || '-',
        style: false,
      },
      G5: {
        merge_with: 'I5',
        value: 'DATE',
        style: true,
      },
      J5: {
        merge_with: 'L5',
        value: dayjs().format('MMM D, YYYY'),
        style: false,
      },
    };

    for (const [cell, property] of Object.entries(header_cells)) {
      worksheet.mergeCells(`${cell}:${property.merge_with}`);
      const first_cell = worksheet.getCell(cell);
      const cell_border = {
        bottom: { style: 'thin', color: { argb: 'd9d9d9' } },
      };
      const second_cell = worksheet.getCell(property.merge_with);
      second_cell.border = cell_border;
      first_cell.value = property.value;
      if (property.style) {
        first_cell.style = {
          font: {
            bold: true,
            color: { argb: '666666' },
          },
          border: cell_border,
        };
      }
    }
  }

  function setupRowHeader(worksheet, titles, dates = []) {
    let row_starting = 1;
    if (!is_split_enabled.value || auth_store.check_split('grs_export_summary_header_rows'))
      row_starting = 8;

    const starting_row = worksheet.getRow(row_starting); // Add data from 8th row
    starting_row.height = 28;
    starting_row.values = [...titles, ...(dates.map(date => dayjs(date).startOf('day').toDate()))];
    starting_row.eachCell((cell, colNumber) => {
      cell.style = {
        font: {
          size: 9,
          color: { argb: '000000' },
          bold: true,
        },
        alignment: {
          vertical: 'middle',
          horizontal: 'center',
          wrapText: true,
        },
      };
      if (colNumber <= titles.length) {
        cell.style.fill = createCellFill({ color: 'efefef' });
      }
      else {
        cell.value = utcToDate(cell.value);
        cell.style.fill = createCellFill({ color: 'bdd7ee' });
      }
    });
  }

  function formattedDate(date) {
    let formatted_date = null;
    if (!date)
      formatted_date = '-';
    else
      formatted_date = utcToDate(dayjs(date).startOf('day').toDate());
    return formatted_date;
  }

  function getDatesBetween(min_date, max_date) {
    const dates = [];
    let current_date = min_date || dayjs(min_date);
    const end_date = max_date || dayjs(max_date);
    while (current_date.isSameOrBefore(end_date)) {
      dates.push(current_date.format('DD-MMM-YY'));
      current_date = current_date.add(1, 'day');
    }
    return dates;
  }

  function getMinMaxDate(projects = []) {
    const flatten_dates = projects.flatMap(data => [data.actual_start, data.actual_finish || dayjs().toISOString(), data.planned_start, data.planned_finish]);
    const filtered_dates = reduce(flatten_dates, (result, date) => {
      if (date) {
        const formatted_date = dayjs(date).format('DD-MMM-YY');
        result.push(dayjs(formatted_date));
      }
      return result;
    }, []);
    return {
      min_date: dayjs.min(filtered_dates),
      max_date: dayjs.max(filtered_dates),
    };
  }

  function createCellBorder(options) {
    const { borders, style, color } = options;
    const cell_borders = {};
    borders.forEach((side) => {
      cell_borders[side] = { style, color: { argb: color } };
    });
    return cell_borders;
  }

  function createCellFill(options = {}) {
    return {
      type: options?.type || 'pattern',
      pattern: options?.pattern || 'solid',
      fgColor: { argb: options?.color || 'FFFFFF' },
    };
  }

  function expectedDateColor(cell, field) {
    const current_value = cell.value;
    let cell_bg_color = null;
    if (!current_value || !field.planned_start || !field.planned_finish) {
      cell.style.fill = createCellFill({ color: 'FFFFFF' });
      return;
    }
    const current_date = cell._column._key;
    const planned_start = dayjs(field.planned_start)?.format('DD-MMM-YY');
    const planned_finish = dayjs(field.planned_finish)?.format('DD-MMM-YY');
    const is_before_start = dayjs(current_date).isBefore(planned_start);
    const is_between_start_and_finish = dayjs(current_date).isBetween(planned_start, planned_finish, null, '[]');
    const is_after_finish = dayjs(current_date).isAfter(planned_finish);

    if (is_before_start) {
      cell_bg_color = cell.value > 0 ? 'd9ead3' : 'FFFFFF'; // green
    }
    else if (is_between_start_and_finish) {
      const expected_rate = field.planned_work_rate;
      if (current_value < expected_rate)
        cell_bg_color = 'f4cccc'; // red
      else if (current_value === expected_rate)
        cell_bg_color = 'FFFFFF';
      else if (current_value > expected_rate)
        cell_bg_color = 'd9ead3'; // green
    }
    else if (is_after_finish) {
      cell_bg_color = cell.value > 0 ? 'f4cccc' : 'FFFFFF'; // red
    }

    cell.style.fill = createCellFill({ color: cell_bg_color || 'FFFFFF' });
  }

  function getUniqSheetName(sheet_names, current_sheet) {
    const sheet_name = current_sheet.replace(/[*?:/\\[\]]/g, ' ')?.substring(0, 29);
    if (sheet_names.includes(sheet_name)) {
      const occurrence = sheet_names.filter(element => element?.substring(0, 29) === sheet_name).length;
      return `${sheet_name}-${occurrence + 1}`;
    }
    return sheet_name;
  }

  function cancelExportingTerraReport() {
    controllers.abort('export_terra_report');
    setExporting(false);
  }

  function getValuesForSpecificDate(workflow, project_name, activity, between_dates = []) {
    return between_dates.reduce((result, date) => {
      const date_obj = state.progress_data?.[workflow]?.[project_name]?.[activity] || {};
      result[date] = date_obj?.[date] || '';
      return result;
    }, {});
  }

  function setExporting(value, split = false) {
    is_report_exporting.value = value;
    is_split_enabled.value = split;
  }

  function getFieldValues(field) {
    const { workflow_uid, field_uid, project_uid } = field;
    const layer = csvInjectionProtector(project_group_map.value[field.project_uid]) || '';
    const split_layer = layer?.split('/');
    const id = md5(workflow_uid + project_uid + field_uid);

    return {
      activity: csvInjectionProtector(field.field) || '-',
      id: auth_store.check_split('grs_export_summary') && is_split_enabled.value ? id.substring(0, 10) : id,
      workflow: csvInjectionProtector(field?.workflow) || '',
      layer: auth_store.check_split('grs_export_summary') && is_split_enabled.value ? (split_layer?.[0] || '') : layer,
      sublayer: split_layer[1] || '',
      uom: csvInjectionProtector(field.units),
      scope: field?.planned_work || 0,
      completed: field?.actual_work || 0,
      remaining: field?.remaining_work || '',
      progress: round(field?.percent_complete * 100),
      status: field?.work_rate_status || '-',
      planned_start: formattedDate(field?.planned_start),
      planned_finish: formattedDate(field?.planned_finish),
      expected_work_rate: field?.planned_work_rate,
      actual_start: formattedDate(field?.actual_start),
      actual_finish: formattedDate(field?.actual_finish),
      est_finish_date: formattedDate(field?.est_finish),
    };
  }

  function sheetEachRow(row, field) {
    row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
      cell.name = fixed_columns.value[colNumber - 1]?.key;
      cell.style = {
        font: { color: { argb: '434343' } },
        alignment: { horizontal: ['activity', 'number'].includes(cell.name) ? 'left' : 'center' },
      };
      if (colNumber <= fixed_columns.value.length) {
        cell.style.border = createCellBorder({ borders: ['bottom'], style: 'thin', color: 'b7b7b7' });
      }
      else {
        cell.style.border = createCellBorder({ borders: ['top', 'bottom', 'left', 'right'], style: 'dotted', color: 'b7b7b7' });
        expectedDateColor(cell, field);
      }
      if (cell.name === 'status') {
        const status_name = cell?.value?.toLocaleLowerCase();
        cell.style.fill = createCellFill({ color: sheet_status_map[status_name] });
      }
    });
  }

  function setSheetBasicInfo(worksheet, projects) {
    worksheet.properties.outlineProperties = { summaryBelow: false }; // collapse button on top
    const flat_project = Object.values(projects).flatMap(project => project);
    const { min_date, max_date } = getMinMaxDate(flat_project);
    let dates_between = [];
    let date_columns = [];

    if (!is_split_enabled.value || auth_store.check_split('grs_export_summary_datewise_progress')) {
      dates_between = getDatesBetween(min_date, max_date);
      date_columns = dates_between?.map(date => ({ key: date }));
    }

    worksheet.columns = [...fixed_columns.value, ...date_columns];

    if (!is_split_enabled.value || auth_store.check_split('grs_export_summary_header_rows'))
      setupHeaderInfo(worksheet);

    setupRowHeader(worksheet, columns_titles.value, dates_between);
    return { dates_between };
  }

  function setWorkflowRow(worksheet, workflow, dates_between, index = 1) {
    const empty_cols = Array.from({ length: [...columns_titles.value, ...dates_between].length - 2 }, () => '');

    if (!is_split_enabled.value || auth_store.check_split('grs_export_summary_header_rows')) {
      const workflow_row = worksheet.addRow([index, workflow, ...empty_cols]);
      workflow_row.eachCell({ includeEmpty: true }, (cell) => {
        cell.style = {
          font: { color: { argb: '000000' }, size: 11, bold: true },
          alignment: { horizontal: 'center' },
          fill: createCellFill({ color: 'F7DCB9' }),
        };
      });
    }
    return { empty_cols };
  }

  function setProjectRow(worksheet, row_data) {
    if (!is_split_enabled.value || auth_store.check_split('grs_export_summary_header_rows')) {
      const project_row = worksheet.addRow(row_data);
      project_row.eachCell((cell) => {
        cell.style = {
          font: {
            color: { argb: '000000' },
            size: 11,
            bold: true,
          },
          alignment: { horizontal: 'center' },
          fill: createCellFill({ color: 'd9d9d9' }),
        };
      });
      return project_row;
    }
    return {};
  }

  function addSummarySheet(workbook, group_workflow_project, report_data) {
    const freezing_column = is_split_enabled.value ? auth_store.check_split('grs_export_summary_datewise_progress') : true;
    const worksheet = workbook.addWorksheet('Summary', {
      views: [{
        state: 'frozen',
        xSplit: freezing_column ? fixed_columns.value.length : 0,
        showGridLines: false,
      }],
    });
    const { dates_between } = setSheetBasicInfo(worksheet, report_data);
    Object.entries(group_workflow_project).forEach(([workflow, projects], w_idx) => {
      const { empty_cols } = setWorkflowRow(worksheet, workflow, dates_between, w_idx + 1);
      setFieldsData(worksheet, projects, workflow, dates_between, empty_cols, w_idx + 1);
    });
  }

  function setFieldsData(worksheet, projects, workflow, dates_between, empty_cols, s_no = null) {
    Object.entries(projects).forEach(([project_name, fields], index) => {
      const level_one = s_no ? `${s_no}.${index + 1}` : `1.${index + 1}`;
      const header_row_data = [level_one, csvInjectionProtector(project_name), ...empty_cols];
      const project_row = setProjectRow(worksheet, header_row_data);
      const fields_asc = sortBy(fields, [o => o.field.toLowerCase()]);
      fields_asc.forEach((field, indx) => {
        const dates_fields = getValuesForSpecificDate(workflow, project_name, field.field, dates_between);
        const row_values = {
          number: `${level_one}.${indx + 1}`,
          ...getFieldValues(field),
          ...(dates_fields),
        };
        const row = worksheet.addRow(row_values);

        row.outlineLevel = s_no ? 2 : 1;
        if (is_split_enabled.value && !auth_store.check_split('grs_export_summary_group_rows'))
          row.outlineLevel = 0;

        sheetEachRow(row, field);
        row.commit();
      });

      if (s_no) {
        project_row.outlineLevel = 1;
        if (is_split_enabled.value && !auth_store.check_split('grs_export_summary_group_rows'))
          project_row.outlineLevel = 0;
      }
    });
  }

  async function exportTerraReports(report_data) {
    controllers.add('export_terra_report');
    const ExcelJS = await import('exceljs');
    const { saveAs } = await import('file-saver');
    const filtered_reports = report_data.filter(report => report.field_type !== 'INTG_101');
    const group_workflow_project = getWorkflowProjectMap(filtered_reports);
    state.progress_data = await fetchProgressData(report_data);
    const workbook = new ExcelJS.Workbook();
    try {
      addSummarySheet(workbook, group_workflow_project, report_data);

      if (!is_split_enabled.value) {
        Object.entries(group_workflow_project).forEach(([workflow, projects]) => {
          const worksheet_names = workbook.worksheets.map(sheet => sheet.name);
          const uniq_sheet_name = getUniqSheetName(worksheet_names, workflow);
          const worksheet = workbook.addWorksheet(csvInjectionProtector(uniq_sheet_name), {
            views: [{ state: 'frozen', xSplit: fixed_columns.value.length, showGridLines: false }],
          });
          const { dates_between } = setSheetBasicInfo(worksheet, projects);
          const { empty_cols } = setWorkflowRow(worksheet, workflow, dates_between);
          setFieldsData(worksheet, projects, workflow, dates_between, empty_cols);
        });
      }

      const color_scale_rule = [{
        type: 'colorScale',
        priority: 0,
        cfvo: [
          { type: 'num', value: 0 },
          { type: 'num', value: 100 },
        ],
        color: [{ argb: 'FFFFFF' }, { argb: '57BB84' }],
      }];
      workbook.eachSheet((worksheet) => {
        const columns = worksheet._columns;
        columns.forEach((column) => {
          if (column._key === 'progress') {
            worksheet.addConditionalFormatting({
              ref: `${column.letter}10:${column.letter}${worksheet.rowCount}`,
              rules: color_scale_rule,
            });
          }
        });
      });

      if (is_report_exporting.value) {
        const buffer = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([buffer]), `${sheet_name.value}.xlsx`);
        setTimeout(() => {
          setExporting(false);
        }, 3000);
      }
    }
    catch (err) {
      logger.error(err);
      setExporting(false);
    }
  }

  return {
    cancelExportingTerraReport,
    exportTerraReports,
    setExporting,
    is_report_exporting,
  };
}
