<script setup>
// --------------------------------- Imports -------------------------------- //
import { debounce, find, remove } from 'lodash-es';
import FormBuilderChecklistItemV2 from '~/forms/components/form-builder/form-builder-checklist-item-v2.vue';

// ---------------------------------- Props --------------------------------- //
const props = defineProps(['field', 'section']);

// ---------------------------------- Emits --------------------------------- //

// ---------------------------- Injects/Provides ---------------------------- //
const $toast = inject('$toast');
const $services = inject('$services');
const form_template_detail_store = inject('form_template_detail_store');

// ----------------------- Variables - Pinia - consts ----------------------- //

// --------------------- Variables - Pinia - storeToRefs -------------------- //

// ------------------- Variables - Local - consts and lets ------------------ //
const debounced_save = debounce(item => save(item), 1000);

// ------------------------ Variables - Local - refs ------------------------ //

const addItem = ref(false);
const is_operating_crud = ref(false);
const checklist = ref((props.section?.fields || []).filter(field => field.status === 'active'));

// ---------------------- Variables - Local - reactives --------------------- //

// --------------------------- Computed properties -------------------------- //

// -------------------------------- Functions ------------------------------- //
function addChecklistItems(item) {
  is_operating_crud.value = true;
  debounced_save(item);
}

async function save(item) {
  const payload = {
    type: 'dropdown',
    properties: { type: 'yes-no' },
    mandatory: true,
    config: [{ name: 'Yes' }, { name: 'No' }, { name: 'N/A' }],
  };

  payload.name = item.name;
  payload.section = props.section.uid;

  try {
    const { data } = await $services.forms.post({
      attribute: 'fields',
      body: { field: payload },
    });
    checklist.value = [...checklist.value, data.field];
  }
  catch (e) {
    logger.error(e);
    $toast({ text: e?.data?.message || 'Checklist Updating failed!', type: 'error' });
  }

  is_operating_crud.value = false;
}

function update(items, options) {
  if (options?.type === 'delete')
    deleteItem(options.data);
  if (options?.type === 'rename')
    renameItem(items, options.data);
  if (options?.type === 'move')
    updateItemPosition(items, options.data.moved);
}

async function deleteItem(uid) {
  is_operating_crud.value = true;

  try {
    await $services.forms.delete({
      attribute: `fields/${uid}`,
    });

    remove(checklist.value, item => item.uid === uid);
  }
  catch (e) {
    logger.error(e);
    $toast({ text: e || 'Checklist deletion failed!', type: 'error' });
  }

  is_operating_crud.value = false;
}

async function renameItem(items, data) {
  const { uid, value } = data;
  is_operating_crud.value = true;

  try {
    await $services.forms.patch({
      attribute: `fields/${uid}`,
      body: { field: { name: value.name } },
    });

    const item = find(items, item => item.uid === uid);
    item.name = value.name;
  }
  catch (e) {
    logger.error(e);
    $toast({ text: e?.data?.message || 'Checklist Updating failed!', type: 'error' });
  }
  is_operating_crud.value = false;
}

async function updateItemPosition(items, moved_item) {
  is_operating_crud.value = true;

  try {
    await $services.forms.patch({
      attribute: `fields/${moved_item.element.uid}`,
      body: {
        field: {
          previous_field: moved_item.newIndex ? items[moved_item.newIndex - 1].uid : null,
        },
      },
    });
  }
  catch (e) {
    logger.error(e);
    $toast({ text: e?.data?.message || 'Checklist re-ordering failed!', type: 'error' });
    form_template_detail_store.reset_form_template(props.section.uid);
  }
  is_operating_crud.value = false;
}

// -------------------------------- Watchers -------------------------------- //

// ----------------------------- Lifecycle Hooks ---------------------------- //
</script>

<template>
  <div @click.stop>
    <div id="container" class="border-b border-gray-300 pb-3 last:border-b-0">
      <HawkLoader v-if="is_operating_crud" />
      <ol v-else>
        <FormBuilderChecklistItemV2
          class="my-2"
          :subitem_level="0"
          :items="checklist"
          :button_items="[]"
          :dropdown_items="[
            {
              label: 'Rename',
              emit: 'renameItem',
            },
            {
              label: 'Delete',
              emit: 'deleteItem',
            }]"
          @updateChecklist="update"
        />
        <ChecklistInput
          v-if="addItem" class="mx-5 mt-4" :placeholder="$t('Add item')" :allow_multiple="false"
          @close="addItem = false"
          @add="addChecklistItems"
        />
        <hawk-button v-else type="text" @click="addItem = true">
          + {{ $t('Add items') }}
        </hawk-button>
      </ol>
    </div>
  </div>
</template>
