import { computed, inject } from 'vue';

export function useChartsConstants() {
  const $t = inject('$t');

  const container_schedule_map = {
    'RZVw28YBh9': '0XX8Ov_ka',
    'C3JA1HFQh4': 'w4pzx8WIU',
    '8DtjXYIJzS': 'f9P1fx2j8',
    '63J53GayIz': 'Z65opTLin',
    'ptrNkXvosG': 'RNKCCehu7',
    'YdCAJi2zeX': 'UNMfnod9o',
    'ZnaWAwHOiw': 'pZig1r6Re',
  };

  const color_by_menu = computed(() => ([
    {
      label: $t('Percentage'),
      value: 'Percentage',
    },
    {
      label: $t('Status'),
      value: 'Status',
    },
    {
      label: $t('Work rate'),
      value: 'Work rate',
    },
  ]));

  const range_dates = computed(() => ([
    {
      label: $t('Last 120 days'),
      value: 120,
    },
    {
      label: $t('Last 90 days'),
      value: 90,
    },
    {
      label: $t('Last 60 days'),
      value: 60,
    },
    {
      label: $t('Last 45 days'),
      value: 45,
    },
    {
      label: $t('Last 30 days'),
      value: 30,
    },
    {
      label: $t('Last 14 days'),
      value: 14,
    },
  ]));

  const status_color_map = {

    'Not Started': 'gray',
    'On Track': 'yellow',
    'On pace': 'yellow',
    'In Progress': 'yellow',
    'Ahead': 'blue',
    'Completed': 'green',
    'Delayed': 'red',
    'Behind': 'red',

  };

  const color_by_status_menu = computed(() => ([
    {
      name: $t('Not Started'),
      color: `bg-${status_color_map['Not Started']}-500`,
    },
    {
      name: $t('Behind'),
      color: `bg-${status_color_map.Behind}-500`,
    },
    {
      name: $t('On pace'),
      color: `bg-${status_color_map['On pace']}-500`,
    },
    {
      name: $t('Ahead'),
      color: `bg-${status_color_map.Ahead}-500`,
    },
    {
      name: $t('Completed'),
      color: `bg-${status_color_map.Completed}-500`,
    },
  ]));

  const color_by_percentage_menu = computed(() => ([
    {
      name: '0 to 20%',
      color: 'bg-red-500',
    },
    {
      name: '20 to 40%',
      color: 'bg-warning-500',
    },
    {
      name: '40 to 60%',
      color: 'bg-yellow-500',
    },
    {
      name: '60 to 80%',
      color: 'bg-lime-500',
    },
    {
      name: '80 to 100%',
      color: 'bg-success-500',
    },
  ]));

  const color_by_work_rate_menu = computed(() => ([
    {
      name: $t('Not Started'),
      color: `bg-${status_color_map['Not Started']}-500`,
    },
    {
      name: $t('Behind'),
      color: `bg-${status_color_map.Behind}-500`,
    },
    {
      name: $t('Ahead'),
      color: `bg-${status_color_map.Ahead}-500`,
    },
    {
      name: $t('Completed'),
      color: `bg-${status_color_map.Completed}-500`,
    },
  ]));

  const sheet_column_titles = ['Number', 'Activity', 'Workflow', 'Layer', 'UOM', 'Scope', 'Completed', 'Remaining', '% Progress', 'Status', 'Planned Start', 'Planned Finish', 'Expected Work rate', 'Actual Start', 'Actual Finish', 'Est. Finish date'];
  const sheet_column_specific_titles = ['Number', 'Activity', 'Workflow', 'Layer', 'Sublayer', 'UOM', 'Scope', 'Completed', 'Actual Start', 'Actual Finish', 'Remaining', '% Progress', 'Status', 'Planned Start', 'Planned Finish', 'Expected Work rate', 'Est. Finish date'];
  const sheet_column_specific_keys = [
    { key: 'number' },
    { key: 'id', width: 20 },
    { key: 'activity', width: 20 },
    { key: 'workflow', width: 20 },
    { key: 'layer', width: 20 },
    { key: 'sublayer', width: 20 },
    { key: 'uom' },
    { key: 'scope' },
    { key: 'completed' },
    { key: 'actual_start', width: 15, style: { numFmt: 'DD/MM/YYYY' } },
    { key: 'actual_finish', width: 15, style: { numFmt: 'DD/MM/YYYY' } },
    { key: 'remaining' },
    { key: 'progress' },
    { key: 'status', width: 12 },
    { key: 'planned_start', width: 15, style: { numFmt: 'DD/MM/YYYY' } },
    { key: 'planned_finish', width: 15, style: { numFmt: 'DD/MM/YYYY' } },
    { key: 'expected_work_rate', width: 15 },
    { key: 'est_finish_date', width: 15, style: { numFmt: 'DD/MM/YYYY' } },
  ];
  const sheet_status_map = {
    'behind': 'f4cccc',
    'completed': 'd9ead3',
    'ahead': 'c9daf8',
    'on track': 'fce5cd',
    'on pace': 'fce5cd',
    'not started': 'd9d9d9',
  };

  return {
    container_schedule_map,
    color_by_menu,
    range_dates,
    status_color_map,
    color_by_status_menu,
    color_by_percentage_menu,
    color_by_work_rate_menu,
    sheet_column_titles,
    sheet_column_specific_titles,
    sheet_column_specific_keys,
    sheet_status_map,
  };
}
