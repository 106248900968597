<script setup>
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store.js';

// Components
import FormBuilderDraggableFields from '~/forms/components/form-builder/form-builder-draggable-fields.vue';
import FormBuilderFieldConfiguration from '~/forms/components/form-builder/form-builder-field-configuration.vue';
import FormBuilderHeader from '~/forms/components/form-builder/form-builder-header.vue';
import FormBuilderSectionConfiguration from '~/forms/components/form-builder/form-builder-section-configuration.vue';
import FormBuilderSections from '~/forms/components/form-builder/form-builder-sections.vue';
import FormTemplateDetailDropdown from '~/forms/components/form-template-detail/form-template-detail-dropdown.vue';
import FormTemplateDetailHeader from '~/forms/components/form-template-detail/form-template-detail-header.vue';

const props = defineProps({
  is_popup: {
    type: Boolean,
    default: false,
  },
  previous_step_number: {
    type: Number,
    default: -1,
  },
  step_number: {
    type: Number,
    default: -1,
  },
});

const form_template_detail_store = useFormTemplateDetailStore();
const { $t, auth_store, route, router, $services } = useCommonImports();

const draggable_component = defineAsyncComponent(() => import('vuedraggable'));
const draggable = shallowRef(draggable_component);

provide('form_template_detail_store', form_template_detail_store);
const has_edit_access = computed(() => form_template_detail_store.form_template_detail.status === 'published' || !form_template_detail_store?.form_template_detail?.can_modify_template);
const form_template_detail_tabs = computed(() => [
  { uid: '1', label: $t('Forms'), to: { name: 'forms-overview' } },
  { uid: '2', label: $t('Templates'), to: { name: 'forms-templates' } },
  {
    uid: '3',
    label: form_template_detail_store.form_template_detail.name,
    to: {
      name: auth_store.check_split('forms_v2') ? 'fam' : 'form-template-detail',
      params: { template_uid: route.params.template_uid, asset_id: route.params.asset_id },
    },
  },
  { uid: '4', label: $t('Builder'), to: { name: 'forms-template-builder' } },
]);

const selected_field = ref(null);
const selected_section = ref(null);
const sections = ref(null);
const right_pane = ref(null);
const templates = ref([]);
const is_fetching_terra_containers = ref(false);
const is_template_loading = ref(true);
const terra_containers = ref([]);

const is_sticky = ref(false);

provide('terra-containers', terra_containers);
provide('is-fetching-terra-containers', is_fetching_terra_containers);

async function getData() {
  if (route.params.template_uid) {
    is_template_loading.value = true;
    await form_template_detail_store.set_form_template({ id: route.params.template_uid });
    is_template_loading.value = false;
  }
}

function addStickyHeader() {
  const sibling = document.querySelector('.form-builder-header-sibling');

  const observer = new IntersectionObserver(
    ([e]) => { is_sticky.value = e.intersectionRatio < 1; },
    { threshold: 0.1, rootMargin: '-40px' },
  );
  observer.observe(sibling);
}

function removeDeletedOptions(option) {
  if (Array.isArray(option?.field?.config)) {
    const config = option.field.config.filter(config => !config?.deleted);
    option.field.config = config;
    return option;
  }
  return option;
}

async function fetchTerraContainers() {
  is_fetching_terra_containers.value = true;
  const { data } = await $services.terra_view_service.getAll({
    query: {
      asset: form_template_detail_store.form_template_detail?.target_element?.asset,
      detail: true,
    },
  });
  terra_containers.value = data.map(container => ({
    ...container,
    children: container.groups.map(group => ({
      ...group,
      children: group.projects,
    })),
  }));

  is_fetching_terra_containers.value = false;
}

onMounted (async () => {
  await getData();

  const { data } = await $services.forms.getAll({
    attribute: 'fields',
    query: {
      isTemplate: true,
    },
  });
  templates.value = data?.fields;
  if (!props.is_popup)
    addStickyHeader();
});

watch(() => route.params.asset_id, (val, old_val) => {
  if (val && old_val && val !== old_val)
    router.push({ name: 'forms-templates', params: { asset_id: route.params.asset_id }, query: { active: 'published' } });
});

watch(() => route.params.template_uid, () => {
  getData();
});

watch(
  selected_field,
  (field_obj) => {
    const is_asset_scoped_template = !!form_template_detail_store.form_template_detail?.target_element?.asset;
    if (auth_store.check_split('multi_text_field_config') && is_asset_scoped_template && field_obj && field_obj.field.type === 'multi_text')
      fetchTerraContainers();
  },
);
</script>

<template>
  <div class="justify-center py-4 sm:px-6 lg:px-4">
    <hawk-loader v-if="is_template_loading" />
    <div v-else class="flex min-h-full flex-col">
      <div v-if="$route.name !== 'forms-template-workflow-builder'" class="flex justify-between items-start">
        <FormTemplateDetailHeader>
          <div>
            <hawk-breadcrumbs :items="form_template_detail_tabs" active_item="4" />
          </div>
        </FormTemplateDetailHeader>
        <FormTemplateDetailDropdown :is_form_builder_page="true" class="relative z-[30]" />
      </div>
      <div class="form-builder">
        <!-- Header -->
        <div class="form-builder-header-sibling" />
        <div v-if="$route.name !== 'forms-template-workflow-builder'" class="border-t  border-gray-200 mt-4" />
        <FormBuilderHeader v-if="!is_popup" :is_sticky="is_sticky" class="sticky top-12 bg-white z-[18] -mx-4 px-4 py-4" :class="is_sticky ? 'shadow-md pb-2 pt-6' : 'py-4'" />
        <!-- Builder -->
        <div class="grid gap-4" :style="has_edit_access ? 'pointer-events:none;' : 'grid-template-columns:1fr 23rem;'">
          <!-- Left -->
          <FormBuilderSections
            v-if="form_template_detail_store.form_template_detail.sections.length > 0"
            ref="sections"
            :selected_field="selected_field"
            :selected_section="selected_section"
            :draggable="draggable"
            @select_field="selected_field = removeDeletedOptions($event);selected_section = null;"
            @select_section="selected_section = $event;selected_field = null;"
          />
          <hawk-loader v-else />
          <!-- Right -->
          <div v-if="!has_edit_access" @click.stop @keydown.esc="() => { selected_field = null;selected_section = null }">
            <div ref="right_pane" class="sticky" :style="`top:${props.is_popup ? '0' : '8.3'}rem;`">
              <FormBuilderFieldConfiguration v-if="selected_field" :step_number="step_number" :previous_step_number="previous_step_number" :options="selected_field" @close="selected_field = null" />
              <FormBuilderSectionConfiguration v-else-if="selected_section" :section="selected_section" @close="selected_section = null" />
              <FormBuilderDraggableFields v-else :templates="templates" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
