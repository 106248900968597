<script setup>
const props = defineProps({
  title: {
    type: String,
  },
  value: {
    type: [String, Number],
    required: true,
  },
});

const text_class = computed(() => {
  if (typeof props.value === 'number')
    return props.value >= 1 ? 'text-success-700' : 'text-error-700';

  return 'text-gray-700';
});
</script>

<template>
  <div class="px-3 pb-3 pt-0">
    <div
      class="text-lg font-semibold text-error-700"
      :class="text_class"
    >
      {{ props.value ?? 0 }}
    </div>
  </div>
</template>
