<script setup>
import { searchData } from '~/common/utils/common.utils';
import { useFormBuilderAllFields } from '~/forms/composables/form-builder-all-fields.composable.js';

const props = defineProps({
  templates: {
    type: Array,
    default: () => [],
  },
});

const draggable = defineAsyncComponent(() => import('vuedraggable'));

const search = ref(null);

const { fields, field_map } = useFormBuilderAllFields();

const filtered_fields = computed(() => searchData(fields, search.value));
const filtered_templates = computed(() => searchData(props.templates, search.value));
</script>

<template>
  <div class="border rounded-lg border-gray-300">
    <div>
      <hawk-search-input v-model="search" :placeholder="$t('Search')" full_width class="px-4 pt-4" />
      <div class="overflow-y-auto max-h-[calc(100vh-13rem)] scrollbar px-4 pb-4">
        <div v-if="filtered_fields.length > 0" class="text-bold py-4 font-semibold text-sm">
          {{ $t('Fields') }}
        </div>
        <div>
          <draggable
            :group="{ name: 'fields', pull: 'clone', put: false }"
            :list="filtered_fields"
            :sort="false"
            tag="div"
            item-key="name"
            class="grid grid-cols-2 gap-4 no-ghost"
          >
            <template #item="{ element }">
              <div class="p-1.5 border border-gray-300 rounded-lg flex items-center cursor-grab truncate bg-white text-sm hover:bg-gray-50">
                <div class="px-2">
                  <component :is="element.icon" class="text-gray-700" :class="element.icon_class" />
                </div>
                {{ $t(element.name) }}
              </div>
            </template>
          </draggable>
        </div>
        <div v-if="filtered_templates.length > 0" class="text-bold py-4 font-semibold  text-sm">
          {{ $t('Templates') }}
        </div>
        <div>
          <draggable
            :group="{ name: 'fields', pull: 'clone', put: false }"
            :list="filtered_templates"
            :sort="false"
            tag="div"
            item-key="uid"
            ghost-class="ghost"
            class="grid grid-cols-2 gap-4 no-ghost`"
          >
            <template #item="{ element }">
              <div class="p-1.5 border border-gray-300 rounded-lg flex items-center cursor-grab truncate  bg-white text-sm  hover:bg-gray-50">
                <div class="px-2">
                  <component :is="field_map[element.properties.type].icon" class="text-gray-700" :class="field_map[element.properties.type].icon_class" />
                </div>
                <hawk-text :content="element.name" :length="12" />
              </div>
            </template>
          </draggable>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
    :not(.no-ghost){
      >.sortable-ghost {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding:1rem;
      }
    }
</style>
