import { acceptHMRUpdate, defineStore } from 'pinia';
import { useRoute } from 'vue-router';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import SelectOptionTemplate from '~/dashboard/components/vueform-schema-templates/select-option-template.vue';
import { useDashboardStore } from '~/dashboard/store/dashboard.store';

export const useDashboardCustomStore = defineStore('dashboard_custom', {
  state: () => ({
    custom_configuration: {},
    type_options: [
      ['rich_text', 'Rich text'],
      ['container_views', 'Views'],
      // ['map_view', 'Map view'],
      ['vertical_graph', 'Vertical Bar Chart'],
      ['horizontal_bar', 'Horizontal Bar Chart'],
      ['donut', 'Pie Chart'],
      ['line', 'Line chart'],
      ['area', 'Area chart'],
      ['custom_table', 'Custom table'],
    ].map((item) => {
      return {
        value: item[0],
        label: item[1],
        description: item[2],
      };
    }),
    data_set: false,
  }),
  getters: {
    custom_widget_type: state => state.custom_configuration?.type || null,
    custom_schema: (state) => {
      const { $t } = useCommonImports();
      if (!state.data_set)
        return {};

      return {
        type: {
          type: 'select',
          items: state.type_options,
          search: true,
          native: false,
          label: $t('Type'),
          canClear: false,
          canDeselect: false,
          inputType: 'search',
          autocomplete: 'off',
          rules: [
            'required',
          ],
          default: state.custom_configuration.type || state.type_options[0].value,
          slots: {
            option: SelectOptionTemplate,
          },
        },
        module: {
          type: 'hidden',
          default: 'custom',
        },
      };
    },
  },
  actions: {
    update_custom_configuration(data) {
      this.custom_configuration = { ...data };
    },
    getMinW() {
      return this.custom_configuration.type === 'container_views' ? 8 : 4;
    },
    parse_custom_form_to_server_format(widget_data) {
      if (!Object.keys(widget_data || {}).length)
        return null;

      const payload = {
        properties: {
          source: {
            type: 'custom',
            service: 'custom',
          },
          // config: this.graph_data?.properties?.config || {},
        },
        chart: widget_data.type,
        group: widget_data.type === 'donut' ? false : 'custom',
        minW: this.getMinW(),
      };

      if (widget_data.type === 'map_view')
        payload.properties.boundaries = widget_data.map_boundaries;

      if (['vertical_graph', 'horizontal_bar', 'donut', 'line', 'area', 'custom_table'].includes(widget_data.type)) {
        payload.properties.csv_data = widget_data.csv_data.data || [];
        payload.data = widget_data.csv_data.formatted_data || {};
        payload.properties.config = widget_data.chart_config || {};
      }

      if (widget_data.type === 'rich_text')
        payload.rich_text = widget_data.rich_text;

      if (widget_data.type === 'container_views')
        payload.properties.asset_id = widget_data.asset_id;

      return payload;
    },
    unparse_custom_form_to_server_format(widget_data) {
      if (!Object.keys(widget_data || {}).length)
        return null;

      const router = useRoute();

      const data = {};
      data.name = widget_data.properties?.name || 'Untitled';
      data.type = widget_data.chart;
      data.rich_text = widget_data.rich_text || null;
      data.map_boundaries = widget_data.properties?.boundaries || null;
      data.csv_data = {};
      data.csv_data = {
        data: widget_data?.properties?.csv_data || [],
        formatted_data: widget_data?.data || {},
      };
      data.chart_config = widget_data.properties?.config || null;
      data.asset_id = widget_data.properties?.asset_id || router.params?.asset_id || null;
      data.module = 'custom';

      return data;
    },
    set_data() {
      const dashboard_store = useDashboardStore();
      const widget_configuration = dashboard_store.widget_configuration;

      if (
        !Object.keys(widget_configuration || {}).length
        || widget_configuration?.module !== 'custom'
      ) {
        this.data_set = true;
        this.custom_configuration = {
          ...this.custom_configuration,
          asset_id: dashboard_store.widget_asset,
        };
        return;
      }

      const data = widget_configuration;
      this.custom_configuration = { ...data };

      this.data_set = true;
    },
  },
});

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useDashboardCustomStore, import.meta.hot));
