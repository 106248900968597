<!-- eslint-disable vue/prop-name-casing -->
<script setup>
// screens available for onboarding: ['tasks','inventory-items','plans','files','forms']
// screens available for no data: ['assets-list', 'tasks', 'conversations', 'plans', 'forms', 'project-management', 'system-model', 'inventory-items', 'inventory-warehouses', 'inventory-transactions', 'inventory-history', 'terra', 'therm-map', 'therm-simulation', 'dashboard-widgets', 'checklists', 'subtasks', 'dependencies']
const props = defineProps({
  is_create_indicator: {
    type: Boolean,
    default: true,
  },
  type: {
    type: String,
    default: 'no-data',
    validator: value => ['no-data', 'no-results', 'on-boarding', 'no-permission', 'get-started'].includes(value),
  },
  for: {
    type: String,
    default: 'generic',
  },
  variant: {
    type: String,
    default: 'default',
    validator: value => ['default', 'mini', 'mini_vertical'].includes(value),
  },
  has_permission: {
    type: Boolean,
    default: true,
  },
  is_popup: {
    type: Boolean,
    default: false,
  },
  arrow_text: {
    type: String,
    default: '',
  },
  has_fixed_height: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['onCtaClick']);

// Please import icons here as async components to reduce bundle size
const IconIllustrationGeneric = defineAsyncComponent(() => import('~icons/illustration/generic'));
const IconIllustrationNoComments = defineAsyncComponent(() => import('~icons/illustration/no-comments'));
const IconIllustrationAssetsList = defineAsyncComponent(() => import('~icons/illustration/assets-list'));
const IconIllustrationUsers = defineAsyncComponent(() => import('~icons/illustration/users'));
const IconIllustrationInvitations = defineAsyncComponent(() => import('~icons/illustration/invitations'));
const IconIllustrationRolesMini = defineAsyncComponent(() => import('~icons/illustration/roles-mini'));
const IconIllustrationCategories = defineAsyncComponent(() => import('~icons/illustration/categories'));
const IconIllustrationTags = defineAsyncComponent(() => import('~icons/illustration/tags'));
const IconIllustrationAssetSettings = defineAsyncComponent(() => import('~icons/illustration/asset-settings'));
const IconIllustrationTasks = defineAsyncComponent(() => import('~icons/illustration/tasks'));
const IconIllustrationConversations = defineAsyncComponent(() => import('~icons/illustration/conversations'));
const IconIllustrationPlans = defineAsyncComponent(() => import('~icons/illustration/plans'));
const IconIllustrationForms = defineAsyncComponent(() => import('~icons/illustration/forms'));
const IconIllustrationFormsTemplateBuilder = defineAsyncComponent(() => import('~icons/illustration/forms-template-builder'));
const IconIllustrationProjectManagement = defineAsyncComponent(() => import('~icons/illustration/project-management'));
const IconIllustrationSystemModel = defineAsyncComponent(() => import('~icons/illustration/system-model'));
const IconIllustrationInventoryItems = defineAsyncComponent(() => import('~icons/illustration/inventory-items'));
const IconIllustrationInventoryWarehouses = defineAsyncComponent(() => import('~icons/illustration/inventory-warehouses'));
const IconIllustrationInventoryTransactions = defineAsyncComponent(() => import('~icons/illustration/inventory-transactions'));
const IconIllustrationInventoryHistory = defineAsyncComponent(() => import('~icons/illustration/inventory-history'));
const IconIllustrationInventoryBom = defineAsyncComponent(() => import('~icons/illustration/inventory-bom'));
const IconIllustrationTerra = defineAsyncComponent(() => import('~icons/illustration/terra'));
const IconIllustrationThermMap = defineAsyncComponent(() => import('~icons/illustration/therm-map'));
const IconIllustrationThermSimulation = defineAsyncComponent(() => import('~icons/illustration/therm-simulation'));
const IconIllustrationDashboardWidgets = defineAsyncComponent(() => import('~icons/illustration/dashboard-widgets'));
const IconIllustrationFiles = defineAsyncComponent(() => import('~icons/illustration/files'));
const IconIllustrationSections = defineAsyncComponent(() => import('~icons/illustration/sections'));
const IconIllustrationProjectManagementSync = defineAsyncComponent(() => import('~icons/illustration/project-management-sync'));
const IconIllustrationTasksOnboarding = defineAsyncComponent(() => import('~icons/illustration/tasks-onboarding'));
const IconIllustrationTasksOnboardingOne = defineAsyncComponent(() => import('~icons/illustration/tasks-onboarding-one'));
const IconIllustrationTasksOnboardingTwo = defineAsyncComponent(() => import('~icons/illustration/tasks-onboarding-two'));
const IconIllustrationTasksOnboardingThree = defineAsyncComponent(() => import('~icons/illustration/tasks-onboarding-three'));
const IconIllustrationTasksOnboardingFour = defineAsyncComponent(() => import('~icons/illustration/tasks-onboarding-four'));
const IconIllustrationCncOnboarding = defineAsyncComponent(() => import('~icons/illustration/cnc-onboarding'));
const IconIllustrationCncOnboardingOne = defineAsyncComponent(() => import('~icons/illustration/cnc-onboarding-one'));
const IconIllustrationCncOnboardingTwo = defineAsyncComponent(() => import('~icons/illustration/cnc-onboarding-two'));
const IconIllustrationCncOnboardingThree = defineAsyncComponent(() => import('~icons/illustration/cnc-onboarding-three'));
const IconIllustrationCncOnboardingFour = defineAsyncComponent(() => import('~icons/illustration/cnc-onboarding-four'));
const IconIllustrationInventoryItemsOnboarding = defineAsyncComponent(() => import('~icons/illustration/inventory-items-onboarding'));
const IconIllustrationInventoryItemsOnboardingOne = defineAsyncComponent(() => import('~icons/illustration/inventory-items-onboarding-one'));
const IconIllustrationInventoryItemsOnboardingTwo = defineAsyncComponent(() => import('~icons/illustration/inventory-items-onboarding-two'));
const IconIllustrationInventoryItemsOnboardingThree = defineAsyncComponent(() => import('~icons/illustration/inventory-items-onboarding-three'));
const IconIllustrationInventoryItemsOnboardingFour = defineAsyncComponent(() => import('~icons/illustration/inventory-items-onboarding-four'));
const IconIllustrationPlansOnboarding = defineAsyncComponent(() => import('~icons/illustration/plans-onboarding'));
const IconIllustrationPlansOnboardingOne = defineAsyncComponent(() => import('~icons/illustration/plans-onboarding-one'));
const IconIllustrationPlansOnboardingTwo = defineAsyncComponent(() => import('~icons/illustration/plans-onboarding-two'));
const IconIllustrationPlansOnboardingThree = defineAsyncComponent(() => import('~icons/illustration/plans-onboarding-three'));
const IconIllustrationPlansOnboardingFour = defineAsyncComponent(() => import('~icons/illustration/plans-onboarding-four'));
const IconIllustrationFilesOnboarding = defineAsyncComponent(() => import('~icons/illustration/files-onboarding'));
const IconIllustrationFilesOnboardingOne = defineAsyncComponent(() => import('~icons/illustration/files-onboarding-one'));
const IconIllustrationFilesOnboardingTwo = defineAsyncComponent(() => import('~icons/illustration/files-onboarding-two'));
const IconIllustrationFilesOnboardingThree = defineAsyncComponent(() => import('~icons/illustration/files-onboarding-three'));
const IconIllustrationFilesOnboardingFour = defineAsyncComponent(() => import('~icons/illustration/files-onboarding-four'));
const IconIllustrationProjectManagementOnboarding = defineAsyncComponent(() => import('~icons/illustration/project-management-onboarding'));
const IconIllustrationProjectManagementOnboardingOne = defineAsyncComponent(() => import('~icons/illustration/project-management-onboarding-one'));
const IconIllustrationProjectManagementOnboardingTwo = defineAsyncComponent(() => import('~icons/illustration/project-management-onboarding-two'));
const IconIllustrationProjectManagementOnboardingThree = defineAsyncComponent(() => import('~icons/illustration/project-management-onboarding-three'));
const IconIllustrationProjectManagementOnboardingFour = defineAsyncComponent(() => import('~icons/illustration/project-management-onboarding-four'));
const IconIllustrationTasksMiniVariant = defineAsyncComponent(() => import('~icons/illustration/tasks-mini-variant'));
const IconIllustrationFormsMiniVariant = defineAsyncComponent(() => import('~icons/illustration/forms-mini-variant'));
const IconIllustrationFilesMiniVariant = defineAsyncComponent(() => import('~icons/illustration/files-mini-variant'));
const IconIllustrationCommentsMiniVariant = defineAsyncComponent(() => import('~icons/illustration/comments-mini-variant'));
const IconIllustrationActivityAndRemindersMiniVariant = defineAsyncComponent(() => import('~icons/illustration/activity-and-reminders-mini-variant'));
const IconIllustrationChecklistsMiniVariant = defineAsyncComponent(() => import('~icons/illustration/checklists-mini-variant'));
const IconIllustrationFolderMiniVerticalVariant = defineAsyncComponent(() => import('~icons/illustration/folder-mini-vertical-variant'));
const IconIllustrationNoAccessOne = defineAsyncComponent(() => import('~icons/illustration/no-access-one'));
const IconIllustrationNoResultFound = defineAsyncComponent(() => import('~icons/illustration/no-result-found'));
const IconIllustrationOnboardingArrow = defineAsyncComponent(() => import('~icons/illustration/onboarding-arrow'));
const IconIllustrationSubtaskMiniVariant = defineAsyncComponent(() => import('~icons/illustration/subtasks-mini-variant'));
const IconIllustrationDependenciesMiniVariant = defineAsyncComponent(() => import('~icons/illustration/dependencies-mini-variant'));
const IconIllustrationTerraChartsOnboardingRightImage = defineAsyncComponent(() => import('~icons/illustration/terra-charts-onboarding-right'));
const IconIllustrationTerraChartsOnboardingOne = defineAsyncComponent(() => import('~icons/illustration/terra-charts-onboarding-one'));
const IconIllustrationTerraChartsOnboardingTwo = defineAsyncComponent(() => import('~icons/illustration/terra-charts-onboarding-two'));
const IconIllustrationTerraChartsOnboardingThree = defineAsyncComponent(() => import('~icons/illustration/terra-charts-onboarding-three'));
const IconIllustrationTerraChartsOnboardingFour = defineAsyncComponent(() => import('~icons/illustration/terra-charts-onboarding-four'));
const IconIllustrationTerraChartsOnboardingFive = defineAsyncComponent(() => import('~icons/illustration/terra-charts-onboarding-five'));
const IconIllustrationTerraChartsOnboardingSix = defineAsyncComponent(() => import('~icons/illustration/terra-charts-onboarding-six'));
const IconIllustrationTerraChartsOnboardingSeven = defineAsyncComponent(() => import('~icons/illustration/terra-charts-onboarding-seven'));
const IconIllustrationTerraChartsOnboardingEight = defineAsyncComponent(() => import('~icons/illustration/terra-charts-onboarding-eight'));
const IconIllustrationTerraScanDuplicate = defineAsyncComponent(() => import('~icons/illustration/terra-scan-duplicate'));
const IconIllustrationTerraScan = defineAsyncComponent(() => import('~icons/illustration/terra-scan'));

const IconIllustrationComponentMasterOnboarding = defineAsyncComponent(() => import('~icons/illustration/component-master-onboarding'));
const IconIllustrationComponentMasterOnboardingOne = defineAsyncComponent(() => import('~icons/illustration/component-master-onboarding-one'));
const IconIllustrationComponentMasterOnboardingTwo = defineAsyncComponent(() => import('~icons/illustration/component-master-onboarding-two'));
const IconIllustrationComponentMasterOnboardingThree = defineAsyncComponent(() => import('~icons/illustration/component-master-onboarding-three'));
const IconIllustrationComponentMasterOnboardingFour = defineAsyncComponent(() => import('~icons/illustration/component-master-onboarding-four'));

const no_data = computed(() => {
  return {
    'generic': {
      title: 'No Data',
      description: '',
      illustration: IconIllustrationGeneric,
    },
    'comments': {
      title: 'No comments yet',
      description: `no-${props.for}`,
      illustration: IconIllustrationNoComments,
    },
    'assets-list': {
      title: 'No Assets',
      description: `no-data-${props.for}`,
      illustration: IconIllustrationAssetsList,
    },
    'users': {
      title: 'No Members',
      description: `no-data-${props.for}`,
      illustration: IconIllustrationUsers,
    },
    'invitations': {
      title: 'No Invitation',
      description: `no-data-${props.for}`,
      illustration: IconIllustrationInvitations,
    },
    'teams': {
      title: 'No Teams',
      description: `no-data-${props.for}`,
      illustration: IconIllustrationUsers,
      // illustration: IconIllustrationTeams,
    },
    'roles': {
      title: 'No Roles',
      description: `no-data-${props.for}`,
      illustration: IconIllustrationUsers,
      // illustration: IconIllustrationRoles,
    },
    'categories': {
      title: 'No Categories',
      description: `no-data-${props.for}`,
      illustration: IconIllustrationCategories,
    },
    'tags': {
      title: 'No tags',
      description: `no-data-${props.for}`,
      illustration: IconIllustrationTags,
    },
    'asset-settings': {
      title: 'Asset Settings',
      description: `no-data-${props.for}`,
      illustration: IconIllustrationAssetSettings,
    },
    'tasks': {
      title: 'No tasks found',
      description: `no-data-${props.for}`,
      illustration: IconIllustrationTasks,
    },
    'conversations': {
      title: 'No Conversations',
      description: `no-data-${props.for}`,
      illustration: IconIllustrationConversations,
    },
    'plans': {
      title: 'No Plans',
      description: `no-data-${props.for}`,
      illustration: IconIllustrationPlans,
    },
    'forms': {
      title: 'No Forms',
      description: `no-data-${props.for}`,
      illustration: IconIllustrationForms,
    },
    'forms-templates-published': {
      title: 'No Templates',
      description: `no-data-${props.for}`,
      illustration: IconIllustrationForms,
    },
    'forms-templates-drafts': {
      title: 'No Forms',
      description: `no-data-${props.for}`,
      illustration: IconIllustrationForms,
    },
    'forms-templates-public': {
      title: 'No Templates',
      description: `no-data-${props.for}`,
      illustration: IconIllustrationForms,
    },
    'forms-submissions': {
      title: 'No Submissions',
      description: `no-data-${props.for}`,
      illustration: IconIllustrationForms,
    },
    'forms-template-details': {
      title: 'No Forms',
      description: `no-data-${props.for}`,
      illustration: IconIllustrationForms,
    },
    'forms-responses': {
      title: 'No Responses',
      description: `no-data-${props.for}`,
      illustration: IconIllustrationForms,
    },
    'forms-template-builder': {
      title: 'Drag and Drop',
      description: `no-data-${props.for}`,
      illustration: IconIllustrationFormsTemplateBuilder,
    },
    'fam': {
      title: 'No forms found',
      description: `no-data-${props.for}`,
      illustration: IconIllustrationForms,
    },
    'project-management': {
      title: 'No Schedules',
      description: `no-data-${props.for}`,
      illustration: IconIllustrationProjectManagement,
    },
    'system-model': {
      title: 'No Models',
      description: `no-data-${props.for}`,
      illustration: IconIllustrationSystemModel,
    },
    'inventory-items': {
      title: 'No items added yet',
      description: `no-data-${props.for}`,
      illustration: IconIllustrationInventoryItems,
    },
    'inventory-assets-items': {
      arrow_text: 'Add a new item here',
      title: 'No items added yet',
      description: `no-data-${props.for}`,
      illustration: IconIllustrationInventoryItems,
    },
    'inventory-warehouses': {
      title: 'No warehouses added yet',
      description: `no-data-${props.for}`,
      illustration: IconIllustrationInventoryWarehouses,
    },
    'inventory-transactions': {
      title: 'No transactions',
      description: `no-data-${props.for}`,
      illustration: IconIllustrationInventoryTransactions,
    },
    'inventory-history': {
      title: 'No transactions',
      description: `no-data-${props.for}`,
      illustration: IconIllustrationInventoryHistory,
    },
    'inventory-bom': {
      title: 'No bill of material added yet.',
      description: `no-data-${props.for}`,
      illustration: IconIllustrationInventoryBom,
    },
    'terra': {
      title: 'No maps',
      description: `no-data-${props.for}`,
      illustration: IconIllustrationTerra,
    },
    'maps': {
      title: 'No maps',
      description: `no-data-${props.for}`,
      illustration: IconIllustrationTerra,
    },
    'therm-map': {
      title: 'No maps found',
      description: `no-data-${props.for}`,
      illustration: IconIllustrationThermMap,
    },
    'therm-simulation': {
      title: 'No simulation found',
      description: `no-data-${props.for}`,
      illustration: IconIllustrationThermSimulation,
    },
    'dashboard-widgets': {
      title: 'No Widgets',
      description: `no-data-${props.for}`,
      illustration: IconIllustrationDashboardWidgets,
    },
    'files': {
      title: 'No Files',
      description: `no-data-${props.for}`,
      illustration: IconIllustrationFiles,
    },
    'sections': {
      title: 'No section yet',
      description: `no-data-${props.for}`,
      illustration: IconIllustrationSections,
    },
    'pm_sync_history': {
      title: 'No sync history',
      description: 'It looks like there is no history to display here',
      illustration: IconIllustrationProjectManagementSync,
    },
    'attachments': {
      title: 'No Attachments',
      description: `no-data-${props.for}`,
      illustration: IconIllustrationFiles,
    },
    'fam-gallery': {
      title: 'No data found',
      description: `no-data-${props.for}`,
      illustration: IconIllustrationFiles,
    },
    'terra_scan_duplicate': {
      title: 'No duplicates',
      description: 'Looks like there are no duplicates here.',
      illustration: IconIllustrationTerraScanDuplicate,
    },
  };
});

const on_boarding_content = computed(() => {
  return {
    'tasks': {
      arrow_text: 'Create a new task here',
      header: {
        illustration: IconIllustrationTasksOnboarding,
        // title: `onboarding-${props.for}-title`,
        description: `onboarding-${props.for}-description`,
      },
      content_header: `onboarding-${props.for}-content-header`,
      content: [
        {
          illustration: IconIllustrationTasksOnboardingOne,
          title: `onboarding-${props.for}-content-title-one`,
          description: `onboarding-${props.for}-content-description-one`,
        },
        {
          illustration: IconIllustrationTasksOnboardingTwo,
          title: `onboarding-${props.for}-content-title-two`,
          description: `onboarding-${props.for}-content-description-two`,
        },
        {
          illustration: IconIllustrationTasksOnboardingThree,
          title: `onboarding-${props.for}-content-title-three`,
          description: `onboarding-${props.for}-content-description-three`,
        },
        {
          illustration: IconIllustrationTasksOnboardingFour,
          title: `onboarding-${props.for}-content-title-four`,
          description: `onboarding-${props.for}-content-description-four`,
        },
      ],
      no_permission_text: `no-permission-text-${props.for}`,
    },
    'cnc': {
      arrow_text: 'Create a new block here',
      header: {
        illustration: IconIllustrationCncOnboarding,
        // title: `onboarding-${props.for}-title`,
        description: `onboarding-${props.for}-description`,
      },
      content_header: `onboarding-${props.for}-content-header`,
      content: [
        {
          illustration: IconIllustrationCncOnboardingOne,
          title: `onboarding-${props.for}-content-title-one`,
          description: `onboarding-${props.for}-content-description-one`,
        },
        {
          illustration: IconIllustrationCncOnboardingTwo,
          title: `onboarding-${props.for}-content-title-two`,
          description: `onboarding-${props.for}-content-description-two`,
        },
        {
          illustration: IconIllustrationCncOnboardingThree,
          title: `onboarding-${props.for}-content-title-three`,
          description: `onboarding-${props.for}-content-description-three`,
        },
        {
          illustration: IconIllustrationCncOnboardingFour,
          title: `onboarding-${props.for}-content-title-four`,
          description: `onboarding-${props.for}-content-description-four`,
        },
      ],
      no_permission_text: `no-permission-text-${props.for}`,
    },
    'inventory-items': {
      arrow_text: 'Create a new item',
      header: {
        illustration: IconIllustrationInventoryItemsOnboarding,
        // title: `onboarding-${props.for}-title`,
        description: `onboarding-${props.for}-description`,
      },
      content_header: `onboarding-${props.for}-content-header`,
      content: [
        {
          illustration: IconIllustrationInventoryItemsOnboardingOne,
          title: `onboarding-${props.for}-content-title-one`,
          description: `onboarding-${props.for}-content-description-one`,
        },
        {
          illustration: IconIllustrationInventoryItemsOnboardingTwo,
          title: `onboarding-${props.for}-content-title-two`,
          description: `onboarding-${props.for}-content-description-two`,
        },
        {
          illustration: IconIllustrationInventoryItemsOnboardingThree,
          title: `onboarding-${props.for}-content-title-three`,
          description: `onboarding-${props.for}-content-description-three`,
        },
        {
          illustration: IconIllustrationInventoryItemsOnboardingFour,
          title: `onboarding-${props.for}-content-title-four`,
          description: `onboarding-${props.for}-content-description-four`,
        },
      ],
      no_permission_text: `no-permission-text-${props.for}`,
    },
    'plans': {
      arrow_text: 'Create a new plan here',
      header: {
        illustration: IconIllustrationPlansOnboarding,
        // title: `onboarding-${props.for}-title`,
        description: `onboarding-${props.for}-description`,
      },
      content_header: `onboarding-${props.for}-content-header`,
      content: [
        {
          illustration: IconIllustrationPlansOnboardingOne,
          title: `onboarding-${props.for}-content-title-one`,
          description: `onboarding-${props.for}-content-description-one`,
        },
        {
          illustration: IconIllustrationPlansOnboardingTwo,
          title: `onboarding-${props.for}-content-title-two`,
          description: `onboarding-${props.for}-content-description-two`,
        },
        {
          illustration: IconIllustrationPlansOnboardingThree,
          title: `onboarding-${props.for}-content-title-three`,
          description: `onboarding-${props.for}-content-description-three`,
        },
        {
          illustration: IconIllustrationPlansOnboardingFour,
          title: `onboarding-${props.for}-content-title-four`,
          description: `onboarding-${props.for}-content-description-four`,
        },
      ],
      no_permission_text: `no-permission-text-${props.for}`,
    },
    'files': {
      arrow_text: 'Create New File here',
      header: {
        illustration: IconIllustrationFilesOnboarding,
        // title: `onboarding-${props.for}-title`,
        description: `onboarding-${props.for}-description`,
      },
      content_header: `onboarding-${props.for}-content-header`,
      content: [
        {
          illustration: IconIllustrationFilesOnboardingOne,
          title: `onboarding-${props.for}-content-title-one`,
          description: `onboarding-${props.for}-content-description-one`,
        },
        {
          illustration: IconIllustrationFilesOnboardingTwo,
          title: `onboarding-${props.for}-content-title-two`,
          description: `onboarding-${props.for}-content-description-two`,
        },
        {
          illustration: IconIllustrationFilesOnboardingThree,
          title: `onboarding-${props.for}-content-title-three`,
          description: `onboarding-${props.for}-content-description-three`,
        },
        {
          illustration: IconIllustrationFilesOnboardingFour,
          title: `onboarding-${props.for}-content-title-four`,
          description: `onboarding-${props.for}-content-description-four`,
        },
      ],
      no_permission_text: `no-permission-text-${props.for}`,
    },
    'forms': {
      arrow_text: 'Create a new form',
      header: {
        illustration: IconIllustrationFilesOnboarding,
        // title: `onboarding-${props.for}-title`,
        description: `onboarding-${props.for}-description`,
      },
      content_header: `onboarding-${props.for}-content-header`,
      content: [
        {
          illustration: IconIllustrationFilesOnboardingOne,
          title: `onboarding-${props.for}-content-title-one`,
          description: `onboarding-${props.for}-content-description-one`,
        },
        {
          illustration: IconIllustrationFilesOnboardingTwo,
          title: `onboarding-${props.for}-content-title-two`,
          description: `onboarding-${props.for}-content-description-two`,
        },
        {
          illustration: IconIllustrationFilesOnboardingThree,
          title: `onboarding-${props.for}-content-title-three`,
          description: `onboarding-${props.for}-content-description-three`,
        },
        {
          illustration: IconIllustrationFilesOnboardingFour,
          title: `onboarding-${props.for}-content-title-four`,
          description: `onboarding-${props.for}-content-description-four`,
        },
      ],
      no_permission_text: `no-permission-text-${props.for}`,
    },
    'project-management': {
      arrow_text: 'Create New Schedule here',
      header: {
        illustration: IconIllustrationProjectManagementOnboarding,
        // title: `onboarding-${props.for}-title`,
        description: `onboarding-${props.for}-description`,
      },
      content_header: `onboarding-${props.for}-content-header`,
      content: [
        {
          illustration: IconIllustrationProjectManagementOnboardingOne,
          title: `onboarding-${props.for}-content-title-one`,
          description: `onboarding-${props.for}-content-description-one`,
        },
        {
          illustration: IconIllustrationProjectManagementOnboardingTwo,
          title: `onboarding-${props.for}-content-title-two`,
          description: `onboarding-${props.for}-content-description-two`,
        },
        {
          illustration: IconIllustrationProjectManagementOnboardingThree,
          title: `onboarding-${props.for}-content-title-three`,
          description: `onboarding-${props.for}-content-description-three`,
        },
        {
          illustration: IconIllustrationProjectManagementOnboardingFour,
          title: `onboarding-${props.for}-content-title-four`,
          description: `onboarding-${props.for}-content-description-four`,
        },
      ],
      no_permission_text: `no-permission-text-${props.for}`,
    },
    'terra': {
      arrow_text: 'Create New Map here',
      header: {
        illustration: IconIllustrationProjectManagementOnboarding,
        // title: `onboarding-${props.for}-title`,
        description: `onboarding-${props.for}-description`,
      },
      content_header: `onboarding-${props.for}-content-header`,
      content: [
        {
          illustration: IconIllustrationProjectManagementOnboardingOne,
          title: 'onboarding-project-management-content-title-one',
          description: 'onboarding-project-management-content-description-one',
        },
        {
          illustration: IconIllustrationProjectManagementOnboardingTwo,
          title: 'onboarding-project-management-content-title-two',
          description: 'onboarding-project-management-content-description-two',
        },
        {
          illustration: IconIllustrationProjectManagementOnboardingThree,
          title: 'onboarding-project-management-content-title-three',
          description: 'onboarding-project-management-content-description-three',
        },
        {
          illustration: IconIllustrationProjectManagementOnboardingFour,
          title: 'onboarding-project-management-content-title-four',
          description: 'onboarding-project-management-content-description-four',
        },
      ],
    },
    'terra-charts': {
      header: {
        illustration: IconIllustrationTerraChartsOnboarding,
        title: `onboarding-${props.for}-title`,
        description: `onboarding-${props.for}-description`,
        cta_button_text: 'Contact Sales to Enable Dashboard',
        right_image: IconIllustrationTerraChartsOnboardingRightImage,
      },
      content_header: `onboarding-${props.for}-content-header`,
      content: [
        {
          illustration: IconIllustrationTerraChartsOnboardingOne,
          title: 'onboarding-terra-charts-content-title-one',
          description: 'onboarding-terra-charts-content-description-one',
        },
        {
          illustration: IconIllustrationTerraChartsOnboardingTwo,
          title: 'onboarding-terra-charts-content-title-two',
          description: 'onboarding-terra-charts-content-description-two',
        },
        {
          illustration: IconIllustrationTerraChartsOnboardingThree,
          title: 'onboarding-terra-charts-content-title-three',
          description: 'onboarding-terra-charts-content-description-three',
        },
        {
          illustration: IconIllustrationTerraChartsOnboardingFour,
          title: 'onboarding-terra-charts-content-title-four',
          description: 'onboarding-terra-charts-content-description-four',
        },
        {
          illustration: IconIllustrationTerraChartsOnboardingFive,
          title: 'onboarding-terra-charts-content-title-five',
          description: 'onboarding-terra-charts-content-description-five',
        },
        {
          illustration: IconIllustrationTerraChartsOnboardingSix,
          title: 'onboarding-terra-charts-content-title-six',
          description: 'onboarding-terra-charts-content-description-six',
        },
        {
          illustration: IconIllustrationTerraChartsOnboardingSeven,
          title: 'onboarding-terra-charts-content-title-seven',
          description: 'onboarding-terra-charts-content-description-seven',
        },
        {
          illustration: IconIllustrationTerraChartsOnboardingEight,
          title: 'onboarding-terra-charts-content-title-eight',
          description: 'onboarding-terra-charts-content-description-eight',
        },
      ],
    },
    'workflows': {
      no_permission_text: `no-permission-text-${props.for}`,
    },
    'component-master': {
      arrow_text: 'Create a new component here',
      header: {
        illustration: IconIllustrationComponentMasterOnboarding,
        title: `onboarding-${props.for}-title`,
        description: `onboarding-${props.for}-description`,
      },
      content_header: `onboarding-${props.for}-content-header`,
      content: [
        {
          illustration: IconIllustrationComponentMasterOnboardingOne,
          title: `onboarding-${props.for}-title-one`,
          description: `onboarding-${props.for}-description-one`,
        },
        {
          illustration: IconIllustrationComponentMasterOnboardingTwo,
          title: `onboarding-${props.for}-title-two`,
          description: `onboarding-${props.for}-description-two`,
        },
        {
          illustration: IconIllustrationComponentMasterOnboardingThree,
          title: `onboarding-${props.for}-title-three`,
          description: `onboarding-${props.for}-description-three`,
        },
        {
          illustration: IconIllustrationComponentMasterOnboardingFour,
          title: `onboarding-${props.for}-title-four`,
          description: `onboarding-${props.for}-description-four`,
        },
      ],
    },
    'terra-scan': {
      header: {
        illustration: IconIllustrationTerraChartsOnboarding,
        title: `onboarding-${props.for}-title`,
        description: `onboarding-${props.for}-description`,
        cta_button_text: 'Contact Sales to Enable Dashboard',
      },
    },
  };
});

const mini_variant = computed(() => {
  return {
    generic: {
      title: 'No Data',
      description: '',
      illustration: IconIllustrationGeneric,
    },
    tasks: {
      title: 'No #Tasks found',
      description: `no-data-${props.for}`,
      illustration: IconIllustrationTasksMiniVariant,
    },
    forms: {
      title: 'No #Forms found',
      description: `no-data-${props.for}`,
      illustration: IconIllustrationFormsMiniVariant,
    },
    files: {
      title: 'No #Files found',
      description: `no-data-${props.for}`,
      illustration: IconIllustrationFilesMiniVariant,
    },
    comments: {
      description: `no-data-${props.for}`,
      illustration: IconIllustrationCommentsMiniVariant,
    },
    activity_and_reminders: {
      description: 'no-data-comments',
      illustration: IconIllustrationActivityAndRemindersMiniVariant,
    },
    checklists: {
      description: 'Streamline your task execution using checklists. Breakdown and report progress for the task in detail',
      illustration: IconIllustrationChecklistsMiniVariant,
    },
    subtasks: {
      description: 'Create hierarchies of subtasks to plan tasks with multiple levels of depth and keep everything organized',
      illustration: IconIllustrationSubtaskMiniVariant,
    },
    dependencies: {
      description: 'Coordinate tasks effortlessly with well-defined dependencies such as blocking, waiting and link relevant tasks',
      illustration: IconIllustrationDependenciesMiniVariant,
    },
    entries: {
      title: 'No entry available yet',
      description: `no-data-${props.for}`,
      illustration: IconIllustrationChecklistsMiniVariant,
    },
    attachments: {
      title: 'No Attachments',
      description: `no-data-${props.for}`,
      illustration: IconIllustrationFilesMiniVariant,
    },
    terra_attachments: {
      title: 'No attachments available',
      description: '',
      illustration: IconIllustrationFilesMiniVariant,
    },
  };
});

const mini_vertical_variant = computed(() => {
  return {
    'folders': {
      title: 'No folders',
      description: `no-data-${props.for}`,
      illustration: IconIllustrationFolderMiniVerticalVariant,
    },
    'workflow-no-permission': {
      title: 'No Access',
      description: `no-data-${props.for}`,
      illustration: IconIllustrationNoAccessOne,
    },
    'no-workflows': {
      title: 'No Workflows Yet',
      description: `no-data-${props.for}`,
      illustration: IconIllustrationNoWorkflows,
    },
    'enable-workflows': {
      title: 'Enable Workflows',
      description: `no-data-${props.for}`,
      illustration: IconIllustrationEnableWorkflows,
    },
    'map-no-members': {
      title: 'No members',
      description: `no-data-${props.for}`,
      illustration: IconIllustrationMapNoMembers,
    },
    'roles': {
      title: 'No Roles',
      description: `no-data-${props.for}`,
      illustration: IconIllustrationRolesMini,
    },
  };
});

const get_started = computed(() => {
  return {
    'terra-scan': {
      title: 'Your #dashboard for scan will appear here',
      description: `no-data-${props.for}`,
      illustration: IconIllustrationTerraScan,
      cta_button_text: 'Contact Sales to Enable Dashboard',
    },
    'inventory-report': {
      title: 'No report yet',
      description: 'Start creating and sharing delivery, installation, transaction reports, etc., with team members for smooth inventory tracking.',
      illustration: IconIllustrationTerraScan,
      cta_button_text: 'Create a report',
    },
  };
});
</script>

<template>
  <div v-if="variant === 'mini'">
    <div class="flex gap-3 max-w-[590px]">
      <div>
        <component :is="mini_variant?.[props?.for]?.illustration" class="w-10 mb-3" />
      </div>
      <div>
        <div v-if="mini_variant?.[props?.for]?.title" class="text-base font-medium mb-1 text-gray-900">
          {{ $t(mini_variant[props.for].title) }}
        </div>
        <div v-if="mini_variant?.[props?.for]?.description" class="text-sm text-gray-500">
          {{ $t(mini_variant[props.for].description) }}
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="variant === 'mini_vertical'">
    <div class="flex flex-col items-center justify-center gap-3 max-w-[590px] my-20">
      <div>
        <component :is="mini_vertical_variant?.[props?.for]?.illustration" class="w-10 mb-3" />
      </div>
      <div class="text-center">
        <div class="text-base font-medium mb-1 text-gray-900">
          {{ $t(mini_vertical_variant?.[props?.for]?.title) }}
        </div>
        <div class="text-sm text-gray-500">
          {{ $t(mini_vertical_variant?.[props?.for]?.description) }}
        </div>
        <slot name="content" />
      </div>
    </div>
  </div>
  <div v-else-if="variant === 'default'">
    <!-- no-data -->
    <div v-if="type === 'no-data'" class="grid place-content-center relative" :class="{ 'h-[calc(100vh-300px)]': has_fixed_height }">
      <div v-if="is_create_indicator && no_data?.[props?.for]?.arrow_text?.length" class="absolute right-24 flex items-end">
        <div class="text-primary-700">
          {{ arrow_text?.length ? $t(arrow_text) : $t(no_data?.[props?.for]?.arrow_text) }}
        </div>
        <IconIllustrationOnboardingArrow class="w-20 h-20" />
      </div>
      <div class="max-w-[520px] flex items-center justify-center flex-col">
        <component :is="no_data[props.for].illustration" class="w-44 mb-3" />
        <div v-if="no_data?.[props?.for]?.title" class="text-2xl font-semibold mb-2 text-gray-900">
          {{ $t(no_data?.[props?.for]?.title) }}
        </div>
        <div v-if="no_data?.[props?.for]?.description" class="text-base text-gray-500 text-center">
          {{ $t(no_data?.[props?.for]?.description) }}
        </div>
        <slot />
      </div>
    </div>

    <!-- no-results -->
    <div v-else-if="type === 'no-results'" class="h-[calc(100vh-300px)] grid place-content-center">
      <div class="max-w-[520px] flex items-center justify-center flex-col">
        <IconIllustrationNoResultFound class="w-44 mb-3" />
        <div class="text-2xl font-semibold mb-2 text-gray-900">
          {{ $t('No Results') }}
        </div>
        <div class="text-base text-gray-500 text-center">
          {{ $t('Sorry, there are no results for this search, please try another phrase.') }}
        </div>
      </div>
    </div>

    <!-- no-permission -->
    <div v-else-if="type === 'no-permission'" class="h-[calc(100vh-65px)] grid place-content-center">
      <div class="max-w-[520px] flex items-center justify-center flex-col">
        <IconIllustrationNoAccess class="w-44 mb-3" />
        <div class="text-2xl font-semibold mb-2 text-gray-900">
          {{ $t('No Access') }}
        </div>
        <div class="text-base text-gray-500 text-center">
          {{ $t('Sorry, you don\'t have access to this page') }}
        </div>
      </div>
    </div>

    <!-- on-boarding -->
    <div v-else-if="type === 'on-boarding'" class="px-5 lg:px-32 py-2 relative">
      <div v-if="is_create_indicator && on_boarding_content?.[props?.for]?.arrow_text?.length" class="absolute right-24 flex items-end">
        <div class="text-primary-700">
          {{ arrow_text?.length ? $t(arrow_text) : $t(on_boarding_content?.[props?.for]?.arrow_text) }}
        </div>
        <IconIllustrationOnboardingArrow class="w-20 h-20" />
      </div>
      <div class="flex justify-between">
        <div class="max-w-[560px] mt-10">
          <component :is="on_boarding_content?.[props?.for]?.header?.illustration" class="w-14 h-14 mb-3" />
          <div class="text-lg font-semibold text-gray-900 mb-1">
            <p v-if="on_boarding_content?.[props?.for]?.header?.title">
              {{ $t(on_boarding_content?.[props?.for]?.header?.title) }}
            </p>
            <p v-else>
              {{ $t(`Welcome to ${props.for}`) }}!
            </p>
          </div>
          <div v-if="on_boarding_content?.[props?.for]?.header?.description" class="text-sm font-normal mb-2 text-gray-600">
            {{ $t(on_boarding_content?.[props?.for]?.header?.description) }}
          </div>
          <HawkButton v-if="on_boarding_content?.[props?.for]?.header?.cta_button_text" class="mt-6" @click="emit('onCtaClick')">
            {{ $t(on_boarding_content?.[props?.for]?.header?.cta_button_text) }}
          </HawkButton>
        </div>
        <component :is="on_boarding_content?.[props?.for]?.header?.right_image" v-if="on_boarding_content?.[props?.for]?.header?.right_image" class="w-[500px] mr-12" />
      </div>

      <hr class="mt-6 mb-10 ">
      <div :class="{ 'mb-10': !has_permission }">
        <div class="text-lg font-semibold text-gray-900 mb-2">
          {{ $t(on_boarding_content?.[props?.for]?.content_header) }}
        </div>
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-5">
          <div v-for="content in on_boarding_content?.[props?.for]?.content" :key="content.title" class="bg-gray-50 rounded-lg border border-gray-200 px-4 pt-5 pb-3">
            <component :is="content?.illustration" class="w-10 h-10 mb-3" />
            <div class="text-gray-900 mb-1 font-semibold">
              {{ $t(content?.title) }}
            </div>
            <div v-if="content?.description" class="text-sm text-gray-600">
              {{ $t(content?.description) }}
            </div>
          </div>
        </div>
      </div>
      <HawkAlertBanner v-if="!has_permission" color="warning">
        <template #icon>
          <IconHawkAlertTriangle class="flex-shrink-0" />
        </template>
        <template #content>
          {{ $t((on_boarding_content?.[props?.for]?.no_permission_text)) }}
        </template>
      </HawkAlertBanner>
    </div>

    <div v-else-if="type === 'get-started'" class="h-[calc(100vh-300px)] grid place-content-center">
      <div class="max-w-[520px] flex items-center justify-center flex-col">
        <component :is="get_started?.[props?.for]?.illustration" class="w-44 mb-3" />
        <div class="text-2xl font-semibold mb-2 text-gray-900">
          {{ $t(get_started?.[props?.for]?.title) }}
        </div>
        <div class="text-base text-gray-500 text-center mb-5">
          {{ $t(get_started?.[props?.for]?.description) }}
        </div>
        <HawkButton @click="$emit('onCtaClick')">
          {{ $t(get_started?.[props?.for]?.cta_button_text) }}
        </HawkButton>
      </div>
    </div>
  </div>
</template>
